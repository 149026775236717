import { Injectable } from '@angular/core';
import { DashboardApiService, DashboardListQueryParams } from '@selfai-platform/bi-api';
import { PageParams } from '@selfai-platform/shared';
import { Observable, map } from 'rxjs';
import { DashboardList } from '../models/dashboard-list.model';

@Injectable({ providedIn: 'root' })
export class DashboardApiToDomainService {
  constructor(private readonly dashboardApiService: DashboardApiService) {}

  loadDashboardList(workbookId: string, pageParams?: PageParams): Observable<DashboardList> {
    const apiParams: DashboardListQueryParams = {
      limit: pageParams.pageSize,
      offset: (pageParams.pageNumber - 1) * pageParams.pageSize,
      filter: pageParams.sortField ? `${pageParams.sortField},${pageParams.sortOrder}` : undefined,
      search: pageParams.query?.trim(),
    };

    return this.dashboardApiService.getDashboardListByWorkbookId(workbookId, apiParams).pipe(
      map((res) => ({
        // TODO: remove any and fix normalizer
        dashboards: (res._embedded?.dashboards as any) || [],
        page: res.page,
      })),
    );
  }
}
