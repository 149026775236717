import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { getDimensionClass, getFieldClass, getMeasureClass } from './field-icon.functions';
import { ETypeIcon } from './field-icon.interfaces';

@Component({
  selector: 'selfai-bi-shared-field-icon',
  template: `
    <em [class]="iconClass$ | async"></em>
  `,
  styles: [
    `
      :host {
        display: inline-block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldIconComponent implements OnInit {
  @Input() type?: string;
  @Input() view?: string;
  @Input() logicalType?: any;

  iconClass$ = new BehaviorSubject<string>('');

  ngOnInit(): void {
    const type = this.type;
    const logicalType = this.logicalType ? this.logicalType.toString() : '';

    if (this.view === ETypeIcon.DIMENSION && !!type) {
      this.iconClass$.next(getDimensionClass(type, logicalType));
    }

    if (this.view === ETypeIcon.MEASURE && !!type) {
      this.iconClass$.next(getMeasureClass(type, logicalType));
    }

    if (this.view === ETypeIcon.FIELD && !!type) {
      this.iconClass$.next(getFieldClass(type));
    }
  }
}
