import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  ConfigService,
  SelfaiAuthService,
  PermissionService,
  TokenRefreshExpiredInterceptor,
  AppAuthGuard,
  KeycloakHashTokenService,
} from '@selfai-platform/shared';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ErrorDialogComponentModule } from './error/error-dialog/error-dialog-component.module';
import { HeaderComponentModule } from './header/header-component.module';
import { MainMenuComponentModule } from './main-menu/main-menu.module';
import { SHELL_CONFIG, ShellModuleConfig, onAppInitModule, shellConfigFactory } from './shell-config';
import {
  SELFAI_AVALIABLE_LANGUAGES,
  SelfaiTranslateService,
  UiLanguages,
  provideShellTranslateModule,
} from './translate';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    ErrorDialogComponentModule,
    KeycloakAngularModule,
    HeaderComponentModule,
    DynamicDialogModule,
    MainMenuComponentModule,
    provideShellTranslateModule(),
    RouterModule.forChild([
      {
        path: 'home',
        pathMatch: 'full',
        loadChildren: () => import('./pages/home').then((m) => m.HomePageModule),
        canActivate: [AppAuthGuard],
      },
      {
        path: 'account-settings',
        pathMatch: 'full',
        loadChildren: () => import('./pages/account-settings').then((m) => m.AccountSettingsModule),
        canActivate: [AppAuthGuard],
      },
      {
        path: 'access-denied',
        pathMatch: 'full',
        loadChildren: () => import('./pages/access-denied').then((m) => m.AccessDeniedPageModule),
      },
      {
        path: 'notfound',
        pathMatch: 'full',
        loadChildren: () => import('./pages/not-found').then((m) => m.PageNotFoundComponentModule),
        canActivate: [AppAuthGuard],
      },
      {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full',
      },
      {
        path: 'start',
        redirectTo: '/home',
        pathMatch: 'full',
      },
    ]),
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot({}),
  ],
  exports: [HeaderComponentModule, MainMenuComponentModule],
  providers: [
    {
      provide: KeycloakService,
      useExisting: KeycloakHashTokenService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInitModule,
      multi: true,
      deps: [ConfigService, PermissionService, Injector, SelfaiAuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenRefreshExpiredInterceptor,
      multi: true,
    },
  ],
})
export class ShellModule {
  static forRoot(config?: ShellModuleConfig): ModuleWithProviders<ShellModule> {
    return {
      ngModule: ShellModule,
      providers: [
        {
          provide: SELFAI_AVALIABLE_LANGUAGES,
          useValue: config && config.languages ? config.languages : [UiLanguages.EN],
        },
        {
          provide: SHELL_CONFIG,
          useFactory: () => {
            return shellConfigFactory(config?.homePageWidgets, config?.modules);
          },
        },
      ],
    };
  }

  constructor(selfaiTranslateService: SelfaiTranslateService) {
    selfaiTranslateService.initTranslation();
  }
}
