import { Injectable } from '@angular/core';
import {
  DataLabelPosition,
  UIChartColorByDimension,
  UIOption,
  UiChartFormatNumericAlias,
  UiSettings,
} from '@selfai-platform/bi-domain';
import { omit } from 'lodash';
import { Observable } from 'rxjs';
import { graphChartInitialSettings } from '../const';
import { UiSettingsFacade } from '../stores/facades/ui-settings.facade';
import { getInitialSettings } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class UiSettingsService {
  constructor(private uiSettingsFacade: UiSettingsFacade) {}

  loadSettings(widgetId: string, contextId?: string): void {
    // TODO: load settings from backend
    this.uiSettingsFacade.setUiSettings(widgetId, graphChartInitialSettings as UiSettings, contextId);
  }

  getSettings(widgetId: string, contextId?: string): Observable<UiSettings | undefined> {
    return this.uiSettingsFacade.selectUiSettings(widgetId, contextId);
  }

  setSettings(widgetId: string, settings: UiSettings, contextId?: string): void {
    this.uiSettingsFacade.setUiSettings(widgetId, this.enreachWithInitialSettings(settings), contextId);
  }

  resetSettings(widgetId: string, contextId?: string): void {
    this.uiSettingsFacade.resetUiSettings(widgetId, contextId);
  }

  saveSettings(widgetId: string, contextId?: string): void {}

  setSettingsFromLegacy(widgetId: string, legacyUiOptions: UIOption, contextId?: string): void {
    this.setSettings(widgetId, this.mapLegacySettings(legacyUiOptions), contextId);
  }

  mapToLegacySettings(settings: UiSettings): UIOption {
    return {
      type: settings.type,
      color: {
        schema: settings.color?.schema,
        type: settings.color?.type,
      } as UIChartColorByDimension,
      dataLabel: {
        showValue: settings.dataLabel?.showValue,
        pos: settings.dataLabel?.position as DataLabelPosition,
        displayTypes: settings.dataLabel?.displayTypes || [],
        textBackgroundColor: settings.dataLabel?.textStyle?.backgroundColor,
        textColor: settings.dataLabel?.textStyle?.color,
        textOutlineColor: settings.dataLabel?.textStyle?.borderColor,
      },
      legend: {
        auto: settings.showLegend,
      },
      valueFormat: {
        abbr: settings.format?.abbr,
        type: settings.format?.type,
        customSymbol: settings.format?.customSymbol,
        decimal: settings.format?.decimal,
        sign: settings.format?.sign,
        useThousandsSep: settings.format?.useThousandsSep,
      },
      toolTip: {
        displayTypes: settings.tooltip?.displayTypes || [],
      },
      fontSize: settings.common?.fontSize,
      limit: settings.common?.limit,
    };
  }

  private mapLegacySettings(legacyUiOptions: UIOption): UiSettings {
    // TODO: need use more extended typings, this color only for graph chart
    const color = legacyUiOptions.color as UIChartColorByDimension | undefined;

    return {
      common: {
        fontSize: legacyUiOptions.fontSize,
        limit: legacyUiOptions.limit,
      },
      type: legacyUiOptions.type,
      color: {
        schema: color?.schema,
        type: color?.type,
      },
      dataLabel: {
        showValue: legacyUiOptions.dataLabel?.showValue,
        position: legacyUiOptions.dataLabel?.pos,
        displayTypes: legacyUiOptions.dataLabel?.displayTypes || [],
        textStyle: {
          backgroundColor: legacyUiOptions.dataLabel?.textBackgroundColor,
          color: legacyUiOptions.dataLabel?.textColor,
          borderColor: legacyUiOptions.dataLabel?.textOutlineColor,
        },
      },
      showLegend: legacyUiOptions.legend?.auto,
      format: {
        // TODO: temprary exclude 'each' and 'all'. it doesn't use in graph chart
        ...omit(legacyUiOptions.valueFormat, ['each', 'all']),
        abbr: legacyUiOptions.valueFormat?.abbr as UiChartFormatNumericAlias,
        type: legacyUiOptions.valueFormat?.type as string,
      },
      tooltip: {
        displayTypes: legacyUiOptions.toolTip?.displayTypes || [],
      },
    };
  }

  private enreachWithInitialSettings(settings: UiSettings): UiSettings {
    const initialSettings = getInitialSettings(settings.type);

    return {
      ...settings,
      common: {
        ...(settings.common || {}),
        fontSize: settings.common?.fontSize || initialSettings.common?.fontSize,
        limit: typeof settings.common?.limit !== 'undefined' ? settings.common.limit : initialSettings.common?.limit,
      },
      color: {
        ...(settings.color || {}),
        schema: settings.color?.schema || initialSettings.color?.schema,
        type: settings.color?.type || initialSettings.color?.type,
      },
      dataLabel: {
        ...(settings.dataLabel || {}),
        showValue:
          typeof settings.dataLabel?.showValue !== 'undefined'
            ? settings.dataLabel.showValue
            : initialSettings.dataLabel?.showValue,
        position: settings.dataLabel?.position || initialSettings.dataLabel?.position,
        displayTypes: settings.dataLabel?.displayTypes || initialSettings.dataLabel?.displayTypes || [],
      },
      showLegend: typeof settings.showLegend !== 'undefined' ? settings.showLegend : initialSettings.showLegend,
      format: {
        ...(settings.format || {}),
        type: settings.format?.type || initialSettings.format?.type,
        abbr: settings.format?.abbr || initialSettings.format?.abbr,
        customSymbol: settings.format?.customSymbol || initialSettings.format?.customSymbol,
        decimal: settings.format?.decimal || initialSettings.format?.decimal,
        sign: settings.format?.sign || initialSettings.format?.sign,
        useThousandsSep:
          typeof settings.format?.useThousandsSep !== 'undefined'
            ? settings.format.useThousandsSep
            : initialSettings.format?.useThousandsSep,
      },
      tooltip: {
        ...(settings.tooltip || {}),
        displayTypes: settings.tooltip?.displayTypes || initialSettings.tooltip?.displayTypes || [],
      },
    };
  }
}
