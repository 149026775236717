import { HistoryEntity, LinksEntity } from '../../core';
import { Datasource, FieldNameAlias, FieldValueAlias } from '../../datasource';
import { FilterWidget, PageWidget, TextWidget } from '../../widget';
import { Workbook } from '../../workbook';

import { BoardConfiguration, createBoardConfiguration } from './board-configuration';
import { BoardDataSource, createBoardSource } from './board-datasource';

export interface Dashboard extends HistoryEntity, LinksEntity {
  id: string | null;
  name: string;
  description: string;
  configuration: BoardConfiguration;
  temporaryId?: string;
  tag?: string;
  imageUrl?: string;
  hiding: boolean;
  seq?: number;
  workBook?: Workbook;
  dataSources?: Datasource[];
  widgets?: (PageWidget | FilterWidget | TextWidget)[];

  aliases: (FieldNameAlias | FieldValueAlias)[];
  dataSource: BoardDataSource;

  selectDatasource?: Datasource;
  updateId: string | null;
  main: boolean;
  workbookId?: string;
}

export function createDashboard(dashboardOptions: Partial<Dashboard> = {}): Dashboard {
  return {
    id: null,
    name: '',
    description: '',
    hiding: false,
    aliases: [],
    dataSources: [],
    dataSource: createBoardSource(),
    updateId: null,
    main: false,
    configuration: createBoardConfiguration(),
    ...dashboardOptions,
  };
}
