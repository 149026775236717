import { Injectable } from '@angular/core';
import { PipelinePermission, PipelinePermissionScope } from './pipeline-permission.enum';
import { PermissionRequest } from './permission.model';
import { BiPermission } from './bi-permission.enum';
import { SelfaiPermission } from './selfai-permission';
import { BehaviorSubject, Observable, ReplaySubject, map } from 'rxjs';
import { IdentityProviderRoles } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private currentUserPermissions$ = new BehaviorSubject<SelfaiPermission[]>([]);
  private currentPipelinePermissions$ = new BehaviorSubject<PipelinePermission[]>([]);
  private currentBiPermissions$ = new BehaviorSubject<BiPermission[]>([]);
  private pipelinePermissionsLoaded$ = new ReplaySubject<boolean>(1);
  private biPermissionsLoaded$ = new ReplaySubject<boolean>(1);

  private tokenRolesLoaded = false;
  private pipelineRbacRolesLoaded = false;

  private pipelineRbac: Record<string, string[]> | undefined;
  private currentClientRoles: IdentityProviderRoles | undefined;

  currentUserPermissionsObs$ = this.currentBiPermissions$.asObservable();
  currentUserPipelinePermissionsObs$ = this.currentPipelinePermissions$.asObservable();
  currentUserBiPermissionsObs$ = this.currentBiPermissions$.asObservable();
  pipelinePermissionsLoadedObs$ = this.pipelinePermissionsLoaded$.asObservable();
  biPermessionsLoadedObs$ = this.biPermissionsLoaded$.asObservable();

  setRoles(identityProviderRoles: IdentityProviderRoles): void {
    this.currentClientRoles = identityProviderRoles;
    this.addPermissionsForKe([...identityProviderRoles.keRoles]);
    // Need if rbac loaded before token
    this.setRolesFromKeRbac();
    this.addPermissionsForKd([...identityProviderRoles.kdRoles]);
    this.tokenRolesLoaded = true;
    this.checkPipelinePermissionsLoaded();
  }

  setRolesFromKeRbac(): void {
    const keRolesFromRbac = this.getKeRolesFromRbac(this.currentClientRoles?.keRoles || []);
    this.addPermissionsForKe(keRolesFromRbac);
    this.pipelineRbacRolesLoaded = true;
    this.checkPipelinePermissionsLoaded();
  }
  setPipelineRbacModel(rbac: Record<string, string[]>) {
    this.pipelineRbac = rbac;
    this.setRolesFromKeRbac();
  }

  getPermissions(): SelfaiPermission[] {
    return this.currentUserPermissions$.value;
  }

  getPipelinePermissions(): PipelinePermission[] {
    return this.currentPipelinePermissions$.value;
  }

  getBiPermissions(): BiPermission[] {
    return this.currentBiPermissions$.value;
  }

  checkPermission(permissionRequest: PermissionRequest): Observable<boolean> {
    const permissionAction = `${permissionRequest.entity}:${permissionRequest.action}`;

    return this.currentUserPermissions$.pipe(
      map((permissions) => {
        return permissions.some(
          (permission) =>
            permission === permissionAction ||
            permission === `${permissionAction}:${PipelinePermissionScope.Any}` ||
            (permissionRequest.isOwner && permission === `${permissionAction}:${PipelinePermissionScope.Own}`) ||
            (permissionRequest.isPublic && permission.includes(permissionAction)),
        );
      }),
    );
  }

  private getKeRolesFromRbac(clientRoles: string[]): string[] {
    if (!this.pipelineRbac) {
      return [];
    }
    return clientRoles.map((role) => this.pipelineRbac[role] || []).flat(1);
  }

  private checkPipelinePermissionsLoaded() {
    if (this.tokenRolesLoaded && this.pipelineRbacRolesLoaded) {
      this.pipelinePermissionsLoaded$.next(true);
    }
    if (this.tokenRolesLoaded) {
      this.biPermissionsLoaded$.next(true);
    }
  }

  private addPermissionsForKe(roleNames: string[]): void {
    const currentPipelinePermissions = this.currentPipelinePermissions$.value;
    const currentUserPermissions = this.currentUserPermissions$.value;

    for (const roleName of roleNames) {
      if (Object.values(PipelinePermission).includes(roleName as PipelinePermission)) {
        if (!currentPipelinePermissions.includes(roleName as PipelinePermission)) {
          currentPipelinePermissions.push(roleName as PipelinePermission);
        }
        if (!currentUserPermissions.includes(roleName as PipelinePermission)) {
          currentUserPermissions.push(roleName as PipelinePermission);
        }
      }
    }

    this.currentPipelinePermissions$.next(currentPipelinePermissions);
    this.currentUserPermissions$.next(currentUserPermissions);
  }

  private addPermissionsForKd(roleNames: string[]): void {
    const currentBiPermissions = this.currentBiPermissions$.value;
    const currentUserPermissions = this.currentUserPermissions$.value;

    for (const roleName of roleNames) {
      if (Object.values(BiPermission).includes(roleName as BiPermission)) {
        if (!currentBiPermissions.includes(roleName as BiPermission)) {
          currentBiPermissions.push(roleName as BiPermission);
        }
        if (!currentUserPermissions.includes(roleName as PipelinePermission)) {
          currentUserPermissions.push(roleName as PipelinePermission);
        }
      }
    }

    this.currentBiPermissions$.next(currentBiPermissions);
    this.currentUserPermissions$.next(currentUserPermissions);
  }
}
