import { UserProfile } from '@selfai-platform/bi-domain';
import { AbstractHistoryEntity } from '../common/abstract-history-entity';
import { DomainType } from '../entity/domain-type.enum';

export class Comment extends AbstractHistoryEntity {
  public id: number;
  public contents: string;
  public domainType: DomainType;
  public domainId: string;
  public createdBy: UserProfile;
  public modifiedBy: UserProfile;

  public isEdit = false;
  public modifyContents: string;
  public isNew = false;
}

export class Comments {
  public comments: Comment[];
}
