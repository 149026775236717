import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KdBackendApiService } from '../../common';
import {
  CreateDashboardApiModel,
  DashboardDetailViewApiModel,
  DashboardListApiModel,
  DashboardListQueryParams,
  UpdateDashboardApiModel,
} from '../models';

export const enum DashboardApiProjection {
  FOR_DETAIL_VIEW = 'forDetailView',
}

export const enum DashboardWidgetApiProjection {
  FOR_TREE_VIEW = 'forTreeView',
}

@Injectable()
export class DashboardApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  /**
   *
   * @param boardId - dashboard id
   * @param links - array links such us /api/datasources/{datasourceId}
   * @returns Observable<void>
   */
  connectDashboardAndDataSource(boardId: string, links: string[]): Observable<void> {
    return this.kdBackendApiService.putTextUrlList<void>(`dashboards/${boardId}/datasources`, links.join('\n'));
  }

  createDashboard(createDashboardApiModel: CreateDashboardApiModel): Observable<DashboardDetailViewApiModel> {
    return this.kdBackendApiService.post<DashboardDetailViewApiModel>('dashboards', createDashboardApiModel);
  }

  getDashboardDetailView(dashboardId: string): Observable<DashboardDetailViewApiModel> {
    return this.getDashboardWithProjection<DashboardDetailViewApiModel>(
      dashboardId,
      DashboardApiProjection.FOR_DETAIL_VIEW,
    );
  }

  deleteDashboard(id: string): Observable<void> {
    return this.kdBackendApiService.delete('dashboards/' + id);
  }

  updateDashboard(dashboardId: string, options: UpdateDashboardApiModel): Observable<DashboardDetailViewApiModel> {
    return this.kdBackendApiService.patch('dashboards/' + dashboardId, options);
  }

  updateDashboardFields(dashboardId: string, options: unknown): Observable<unknown> {
    return this.kdBackendApiService.patch('dashboards/' + dashboardId + '/fieldsoption', options);
  }

  copyDashboard(dashboardId: string) {
    return this.kdBackendApiService.post('dashboards/' + dashboardId + '/copy', null);
  }

  getDashboardWidgetTreeView(dashboardId: string): Observable<unknown> {
    return this.getDashboardWidgetWithProjection<unknown>(dashboardId, DashboardWidgetApiProjection.FOR_TREE_VIEW);
  }

  getDashboardByWidgetId(widgetId: string): Observable<DashboardDetailViewApiModel> {
    return this.kdBackendApiService.get<DashboardDetailViewApiModel>(
      `widgets/${widgetId}/dashBoard?projection=forDetailView`,
    );
  }

  getDashboardListByWorkbookId(
    workbookId: string,
    queryParams: DashboardListQueryParams = {},
  ): Observable<DashboardListApiModel> {
    return this.kdBackendApiService.get<DashboardListApiModel>(
      this.kdBackendApiService.buildPath<DashboardListQueryParams>(`workbooks/${workbookId}/dashboards`, {
        projection: 'forDetailView',
        ...queryParams,
      }),
    );
  }

  getList(pageParams: DashboardListQueryParams): Observable<DashboardListApiModel> {
    return this.kdBackendApiService.get<DashboardListApiModel>(
      this.kdBackendApiService.buildPath('dashboards', pageParams),
    );
  }

  private getDashboardWidgetWithProjection<T>(
    dashboardId: string,
    projection: DashboardWidgetApiProjection,
  ): Observable<T> {
    return this.kdBackendApiService.get<T>(`dashboards/${dashboardId}/widgets?projection=${projection}`);
  }

  private getDashboardWithProjection<T>(dashboardId: string, projection: DashboardApiProjection): Observable<T> {
    return this.kdBackendApiService.get<T>(`dashboards/${dashboardId}?projection=${projection}`);
  }
}
