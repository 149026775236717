import { Injectable } from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { PageParams, UrlPageParamsService } from '../utils';

@Injectable()
export class TableDataComponentService<T = unknown> {
  pageParams$: Observable<PageParams>;
  data$: Observable<T[]>;
  readonly loading$ = new BehaviorSubject<boolean>(false);

  private readonly totalItems$ = new BehaviorSubject<number | undefined>(undefined);
  private readonly reloadTrigger$ = new BehaviorSubject<void>(undefined);

  constructor(private readonly urlPageParamsService: UrlPageParamsService) {}

  init(loadDataCallback: (pageParams: PageParams) => Observable<{ items: T[]; totalItems: number }>): void {
    this.pageParams$ = this.urlPageParamsService.getPageParams().pipe(
      filter(Boolean),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );

    this.data$ = combineLatest({ pageParams: this.pageParams$, reloadTrigger: this.reloadTrigger$ }).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(({ pageParams }) => {
        return loadDataCallback(pageParams).pipe(
          catchError((e) => {
            this.loading$.next(false);

            return throwError(() => e);
          }),
        );
      }),
      map(({ items, totalItems }) => {
        this.setTotalItems(totalItems);
        return items;
      }),
      tap((res) => {
        this.loading$.next(false)
      }),
    );
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    const pageParams: Pick<PageParams, 'pageNumber' | 'pageSize' | 'sortField' | 'sortOrder' | 'filters'> = {
      pageNumber: this.calcPageNumber(event.first, event.rows),
      pageSize: event.rows,
      sortField: event.sortField as string,
      sortOrder: event.sortOrder > 0 ? 'asc' : 'desc',
    };
    this.urlPageParamsService.setPageParams(pageParams);
  }

  getTotalItems(): Observable<number> {
    return this.totalItems$.asObservable().pipe(filter(Boolean));
  }

  setTotalItems(totalItems: number): void {
    this.totalItems$.next(totalItems);
  }

  triggerReloadData(): void {
    this.reloadTrigger$.next(undefined);
  }

  updateFilter(filterFieldName: string, filterValue: number | string | boolean): void {
    this.pageParams$.pipe(take(1)).subscribe((pageParams) => {
      const filters = pageParams.filters.filter((filter) => filter.fieldName !== filterFieldName);
      filters.push({ fieldName: filterFieldName, value: filterValue });
      this.urlPageParamsService.setPageParams({ ...pageParams, filters });
    });
  }

  getFilterValue(filterFieldName: string): Observable<number | string | boolean | undefined> {
    return this.pageParams$.pipe(
      map((pageParams) => {
        const filter = pageParams.filters.find((filter) => filter.fieldName === filterFieldName);
        return filter?.value;
      }),
    );
  }

  private calcPageNumber(first: number, rows: number): number {
    return Math.round(first / rows) + 1;
  }
}
