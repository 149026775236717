import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ng2-cookies';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AlertService } from '@selfai-platform/shared';

import { AuthService } from '../../auth/auth.service';
import { CommonConstant } from '../constant/common.constant';
import { CookieConstant } from '../constant/cookie.constant';

@Injectable()
export class AbstractService {
  protected http: HttpClient;

  protected API_URL: string = CommonConstant.API_CONSTANT.API_URL;

  protected INTEGRATOR_URL: string = CommonConstant.API_CONSTANT.API_INTEGRATOR_URL;

  protected cookieService: CookieService;

  protected router: Router;

  protected translateService: TranslateService;

  private authSerivce: AuthService;

  protected alertPrimeService: AlertService;

  constructor(protected injector: Injector) {
    this.http = injector.get(HttpClient);
    this.cookieService = injector.get(CookieService);
    this.translateService = injector.get(TranslateService);
    this.router = injector.get(Router);
    this.authSerivce = injector.get(AuthService);
    this.alertPrimeService = injector.get(AlertService);
  }

  protected get<T = any>(url: string): Promise<T> {
    const scope: any = this;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .get<T>(url, { headers })
      .toPromise()
      .catch((error) => scope.errorHandler(scope, error, httpMethod.GET));
    
  }

  protected getFormData(url: string): Promise<any> {
    const scope: any = this;

      return this.http
        .get(url)
        .toPromise()
        .catch((error) => scope.errorHandler(scope, error, httpMethod.GET));
    
  }

  protected getHTML(url: string): Promise<any> {
    const scope: any = this;

    const headers = new HttpHeaders({
      'Content-Type': 'text/html',
    });

    return this.http
      .get(url, { headers })
      .toPromise()
      .catch((error) => scope.errorHandler(scope, error, httpMethod.GET));
  }

  protected getWithoutToken(url: string): Promise<any> {
    const scope: any = this;

    return this.http
      .get(url)
      .toPromise()
      .catch((error) => scope.errorHandler(scope, error));
  }

  protected post(url: string, data: any): Promise<any> {
    const scope: any = this;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    try {
      return this.http
        .post(url, JSON.stringify(data), { headers })
        .toPromise()
        .catch((error) => scope.errorHandler(scope, error, httpMethod.POST, data));
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  protected postWithForm(url: string, data: any): Promise<any> {
    const scope: any = this;

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    try {
      return this.http
        .post(url, data, { headers })
        .toPromise()
        .catch((error) => scope.errorHandler(scope, error, httpMethod.POST, data));
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  protected postBinary2(url: string, data: any): void {
    const scope: any = this;

    const headers = new HttpHeaders({
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
    });
    this.http.post(url, JSON.stringify(data), { headers }).subscribe((response) => {});
  }

  protected postBinary(url: string, data: any): Promise<any> {
    const scope: any = this;

    const headers = new HttpHeaders({
      Accept: 'application/octet-stream',
      'Content-Type': 'application/json',
    });

    return this.http
      .post(url, data, { headers })
      .toPromise()
      .then((response) => scope.resultDownHandler(scope, response))
      .catch((error) => scope.errorHandler(scope, error, httpMethod.POST, data));
  }

  protected postObservable(url: string, data: any): Observable<Object> {
    const scope: any = this;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(url, data, { headers }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => of(scope.errorHandler(scope, error, httpMethod.POST, data))),
    );
  }

  protected patch(url: string, data: any, contentType: string = 'application/json'): Promise<any> {
    const scope: any = this;

    const headers = new HttpHeaders({
      'Content-Type': contentType,
    });

    const body = contentType === 'text/uri-list' ? data : JSON.stringify(data);

    return this.http
      .patch(url, body, { headers })
      .toPromise()
      .catch((error) => scope.errorHandler(scope, error, httpMethod.POST, data));
  }

  protected postWithoutToken(url: string, data: any): Promise<any> {
    const scope: any = this;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http
      .post(url, JSON.stringify(data), { headers })
      .toPromise()
      .catch((error) => scope.errorHandler(scope, error));
  }

  protected put(url: string, data: any, contentType?: string): Promise<any> {
    const scope: any = this;

    let type = 'application/json';
    if (contentType) {
      type = contentType;
    }

    const headers = new HttpHeaders({
      'Content-Type': type,
    });

    const params = type === 'application/json' ? JSON.stringify(data) : data;

    return this.http
      .put(url, params, { headers })
      .toPromise()
      .catch((error) => scope.errorHandler(scope, error, httpMethod.PUT, data));
  }

  protected putWithoutToken(url: string, data: any): Promise<any> {
    const scope: any = this;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http
      .put(url, JSON.stringify(data), { headers })
      .toPromise()
      .catch((error) => scope.errorHandler(scope, error));
  }

  protected delete(url: string): Promise<any> {
    const scope: any = this;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .delete(url, { headers })
      .toPromise()
      .catch((error) => scope.errorHandler(scope, error, httpMethod.DELETE));
  }

  protected deleteWithoutToken(url: string): Promise<any> {
    const scope: any = this;

    return this.http
      .delete(url)
      .toPromise()
      .catch((error) => scope.errorHandler(scope, error));
  }

  protected resultDownHandler(scope: any, response: Response): Promise<any> {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response['_body']);
    }

    throw new Error(response.statusText);
  }

  protected errorHandler(scope: any, error: any, method: httpMethod, data: any): Promise<any> {
    let errorMessage: any;
    if (error.status && error.status !== 404) {
      try {
        errorMessage = error && error['error'] ? error['error'] : 'Server error';
      } catch (e) {
        errorMessage = 'Server error';
      }
    } else {
      error.name === 'TimeoutError' ? (errorMessage = 'Timeout') : (errorMessage = 'Server error');
    }

    const service = this;

    if (error.status === 401) {
      return this.authSerivce
        .updateToken()
        .then(() => {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
          });

          if (method === httpMethod.GET) {
            return this.http
              .get(error.url, { headers })
              .toPromise()
              .then((response) => scope.reRequestResultHandle(scope, response))
              .catch((error) => scope.reRequestErrorHandle(scope, error));
          } else if (method === httpMethod.POST) {
            return this.http
              .post(error.url, data, { headers })
              .toPromise()
              .then((response) => scope.reRequestResultHandle(scope, response))
              .catch((error) => scope.reRequestErrorHandle(scope, error));
          } else if (method === httpMethod.PUT) {
            return this.http
              .put(error.url, data, { headers })
              .toPromise()
              .then((response) => scope.reRequestResultHandle(scope, response))
              .catch((error) => scope.reRequestErrorHandle(scope, error));
          } else if (method === httpMethod.DELETE) {
            return this.http
              .delete(error.url, { headers })
              .toPromise()
              .then((response) => scope.reRequestResultHandle(scope, response))
              .catch((error) => scope.reRequestErrorHandle(scope, error));
          }
        })
        .catch((error) => {
          this._logout();
        });
    } else {
      return Promise.reject(errorMessage);
    }
  }

  protected reRequestErrorHandle(scope: any, error: any) {
    if (error.status === 401) {
      this._logout();
      return false;
    }

    const errorMessage = error ? error.json() : 'Server error';
    return Promise.reject(errorMessage);
  }

  protected reRequestResultHandle(scope: any, response: Response) {
    return response;
  }

  protected tokenRefreshFail(scope: any, error: any): void {
    this.alertPrimeService.error(this.translateService.instant('msg.sso.ui.session.expired'));
    throw error;
  }

  private _logout() {
    this.cookieService.delete(CookieConstant.KEY.LOGIN_TOKEN, '/');
    this.cookieService.delete(CookieConstant.KEY.LOGIN_TOKEN_TYPE, '/');
    this.cookieService.delete(CookieConstant.KEY.LOGIN_USER_ID, '/');
    this.cookieService.delete(CookieConstant.KEY.REFRESH_LOGIN_TOKEN, '/');
    this.cookieService.delete(CookieConstant.KEY.CURRENT_WORKSPACE, '/');
    this.authSerivce.logout();
  }
}

export enum httpMethod {
  GET,
  POST,
  PUT,
  DELETE,
}
