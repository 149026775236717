import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  ViewChild,
} from '@angular/core';
import {
  BaseOption,
  ChartType,
  DashboardField,
  DatasourceField,
  FieldRole,
  LabelLayoutType,
  LabelSecondaryIndicatorMarkType,
  LabelSecondaryIndicatorPeriod,
  LabelSecondaryIndicatorType,
  LogicalType,
  Pivot,
  PivotField,
  SearchQueryRequest,
  ShelveFieldType,
  ShelveType,
  TimeCompareRequest,
  TimestampField,
  UIChartFormatItem,
  UILabelAnnotation,
  UILabelChart,
  UILabelChartSeries,
  UILabelIcon,
  UILabelSecondaryIndicator,
  Widget,
  createTimestampField,
} from '@selfai-platform/bi-domain';

import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import * as _ from 'lodash';
import { FormatOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { EChartService } from '../../services/echart.service';
import { BaseChart } from '../base-chart';

export interface KPI {
  show?: boolean;
  title?: string;
  value?: string;
  isPositive: boolean;
  align?: string;
  showLabel?: boolean;
  showIcon?: boolean;
  iconType?: string;
  image?: string;
  text?: string;
  titleSize: number;
  valueSize: number;
  imageWidth: number;
  imageHeight: number;
  imagePadding: number;

  targetValue?: string;
  targetOriginalValue?: string;

  emphasized?: boolean;

  emphasizedMessage?: string;

  isTargetPositive: boolean;

  isPeriod: boolean;
}

const initKPI: KPI = {
  isPositive: true,
  titleSize: 14,
  valueSize: 32,
  imageWidth: 63,
  imageHeight: 53,
  imagePadding: 78,
  isTargetPositive: true,

  isPeriod: false,
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'label-chart',
  templateUrl: './label-chart.component.html',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class LabelChartComponent extends BaseChart implements AfterViewInit, AfterViewChecked {
  @ViewChild('labelArea', { static: true })
  private area!: ElementRef;

  private kpiElement: NodeListOf<HTMLElement>;

  query: SearchQueryRequest;
  override uiOption: UILabelChart & { isChangeStyle?: boolean };

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('query')
  set setQuery(query: SearchQueryRequest) {
    this.query = query;
  }

  @Input()
  widget: Widget;

  list: KPI[] = [];

  constructor(
    private cdr: ChangeDetectorRef,

    private readonly translate: TranslateService,
    elementRef: ElementRef,
    eChartService: EChartService,
    destroy$: DestroyService,
    injector: Injector,
  ) {
    super(elementRef, destroy$, eChartService, injector);
  }

  override ngAfterViewInit(): void {
    this.chart = this.area as any;
  }

  ngAfterViewChecked(): void {
    if (this.kpiElement) {
      const size: number = this.kpiElement.length;
      const align = String((<UILabelChart>this.uiOption).layout);
      const fontSize = String((<UILabelChart>this.uiOption).fontSize);
      const kpi: KPI = { ...initKPI };
      const imageWidth: number = kpi.imageWidth;
      const imageHeight: number = kpi.imageHeight;
      const imagePadding: number = kpi.imagePadding;
      const list: KPI[] = this.list;
      let count = 0;
      this.kpiElement.forEach((item) => {
        const $item = item;

        const titleSizePer = 500;
        const resultSizePer = 1500;
        const descriptionSizePer = 450;
        const targetSizePer = 350;
        const preferredSize = 2500;
        const maxTitleSize = 150;
        const maxValueSize = 450;
        const maxDescriptionSize = 100;
        let maxTargetSize = 100;
        const scale = size == 1 ? 2.5 : size == 2 ? 1.5 : 1;
        const itemWidth = $item.clientWidth || 0;
        const itemHeight = $item.clientHeight || 0;

        const currentSize = itemWidth / size;
        const scalePercentage = Math.sqrt(currentSize) / Math.sqrt(preferredSize);
        let titleSize = (titleSizePer * scalePercentage) / scale;
        titleSize = itemWidth <= 70 ? 0 : titleSize;
        titleSize = titleSize > maxTitleSize ? maxTitleSize : titleSize;
        let valueSize = (resultSizePer * scalePercentage) / scale;
        valueSize = itemWidth <= 70 ? 0 : valueSize;
        valueSize = valueSize > maxValueSize ? maxValueSize : valueSize;
        let descriptionSize = (descriptionSizePer * scalePercentage) / scale;
        descriptionSize = itemWidth <= 70 ? 0 : descriptionSize;
        descriptionSize = descriptionSize > maxDescriptionSize ? maxDescriptionSize : descriptionSize;
        const targetSize = (targetSizePer * scalePercentage) / scale;
        maxTargetSize = itemWidth <= 70 ? 0 : targetSize;
        maxTargetSize = targetSize > maxTargetSize ? maxTargetSize : targetSize;

        if (fontSize == 'SMALL') {
          titleSize = titleSize * 0.9;
          valueSize = valueSize * 0.9;
          descriptionSize = descriptionSize * 0.9;
          maxTargetSize = maxTargetSize * 0.9;
        } else if (fontSize == 'NORMAL') {
          titleSize = titleSize * 1.1;
          valueSize = valueSize * 1.1;
          descriptionSize = descriptionSize * 1.1;
          maxTargetSize = maxTargetSize * 1.1;
        } else {
          titleSize = titleSize * 1.3;
          valueSize = valueSize * 1.3;
          descriptionSize = descriptionSize * 1.3;
          maxTargetSize = maxTargetSize * 1.3;
        }

        let imageWidthPx = (imageWidth * (scalePercentage * 5)) / scale;
        imageWidthPx = itemWidth <= 70 ? 0 : imageWidthPx;
        imageWidthPx = imageWidthPx > imageWidth * 1.2 ? imageWidth * 1.2 : imageWidthPx;
        let imageHeightPx = (imageHeight * (scalePercentage * 5)) / scale;
        imageHeightPx = itemWidth <= 70 ? 0 : imageHeightPx;
        imageHeightPx = imageHeightPx > imageHeight * 1.2 ? imageHeight * 1.2 : imageHeightPx;
        const imagePaddingPx = (imagePadding * (scalePercentage * 5)) / scale;

        const ddpImgKpiItem = $item.querySelector<HTMLElement>('.ddp-img-kpi');
        if (ddpImgKpiItem) {
          ddpImgKpiItem.style.width = imageWidthPx + 'px';
          ddpImgKpiItem.style.height = imageHeightPx + 'px';
        }

        const ddpTxtTitleItem = $item.querySelector<HTMLElement>('.ddp-txt-title');
        if (ddpTxtTitleItem) {
          ddpTxtTitleItem.style.fontSize = titleSize + '%';
        }

        const ddpDataResultItem = $item.querySelector<HTMLElement>('.ddp-data-result');
        if (ddpDataResultItem) {
          ddpDataResultItem.style.fontSize = valueSize + '%';
        }

        const ddpDataValueItem = $item.querySelector<HTMLElement>('.ddp-data-value');
        if (ddpDataValueItem) {
          ddpDataValueItem.style.fontSize = maxTargetSize + '%';
        }

        if (align == 'VERTICAL') {
          if (list[count].showIcon) {
            const ddpAreaDataKpiItem = $item.querySelector<HTMLElement>('.ddp-area-data-kpi');
            if (ddpAreaDataKpiItem) {
              ddpAreaDataKpiItem.style.paddingLeft = imagePaddingPx + 'px';
            }
          }

          if (list[count].emphasized) {
            const ddpDataResultItem3 = $item.querySelector<HTMLElement>('.ddp-data-result3');
            if (ddpDataResultItem3) {
              ddpDataResultItem3.style.fontSize = titleSize + '%';
            }
          }
        } else {
          if (!list[count].emphasized) {
            const ddpDataResultItem2 = $item.querySelector<HTMLElement>('.ddp-data-result2');
            if (ddpDataResultItem2) {
              ddpDataResultItem2.style.fontSize = titleSize + '%';
            }
          }
        }

        const ddpDataCalenItem = $item.querySelector<HTMLElement>('.ddp-data-calen');
        if (ddpDataCalenItem) {
          ddpDataCalenItem.style.fontSize = descriptionSize + '%';
          if (itemHeight < 100) {
            ddpDataCalenItem.style.display = 'none';
          } else {
            ddpDataCalenItem.style.display = 'block';
          }
        }

        count++;
      });
    }
  }

  override isValid(pivot: Pivot): boolean {
    return (
      (this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) > 0 ||
        this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) > 0) &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0
    );
  }

  override draw(): void {
    if (!this.isValid(this.pivot)) {
      this.noData.emit();
      return;
    }

    this.chartOption = this.convertSeries();

    this.cdr.detectChanges();

    setTimeout(() => {
      this.kpiElement = this.elementRef.nativeElement?.querySelectorAll('.ddp-view-data-kpi');
      this.drawFinished.emit();
    }, 500);
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.LABEL,
      series: [],
    };
  }

  protected override convertSeries(): BaseOption | undefined {
    const option: UILabelChart = this.uiOption;
    if (_.isUndefined(option.series)) {
      option.series = [];
      option.layout = LabelLayoutType.HORIZONTAL;
    }

    const seriesLength: number = this.fieldInfo.aggs.length;

    if (_.isUndefined(option.icons) && option.series.length > 0) {
      option.icons = [];
      option.annotations = [];
      option.secondaryIndicators = [];
      if (option.series[0]['showLabel']) {
        option.showLabel = true;
      }
    }

    this.list = [];
    const series: UILabelChartSeries[] = [];
    const icons: UILabelIcon[] = [];
    const annotations: UILabelAnnotation[] = [];
    const secondaryIndicators: UILabelSecondaryIndicator[] = [];
    for (let num = 0; num < seriesLength; num++) {
      const field: PivotField = this.pivot.aggregations[num];
      let alias: string = field['alias'] || field['fieldAlias'] || field['name'];

      const displayName: any = this.fieldInfo.aggs[num];
      if (field.aggregationType && field.aggregationType != '' && alias.indexOf(field.aggregationType + '(') == -1) {
        alias = this.pivot.aggregations[num].aggregationType + '(' + alias + ')';
      }

      if (option.icons && option.secondaryIndicators && option.annotations) {
        if (option.series.length <= num) {
          if (num > 0) {
            option.series[num] = {
              name: alias,
              displayName: displayName,
            };
            option.icons[num] = {
              seriesName: alias,
              displayName: displayName,
              show: option.icons[0].show,
              iconType: option.icons[0].iconType,
            };
            option.annotations[num] = {
              seriesName: alias,
              displayName: displayName,
              show: option.annotations[0].show,
              description: option.annotations[0].description,
            };
            option.secondaryIndicators[num] = {
              seriesName: alias,
              displayName: displayName,
              show: option.secondaryIndicators[0].show,
              indicatorType: option.secondaryIndicators[0].indicatorType,
              rangeUnit: option.secondaryIndicators[0].rangeUnit,
              targetValue: option.secondaryIndicators[0].targetValue,
              mark: option.secondaryIndicators[0].mark,
              emphasized: option.secondaryIndicators[0].emphasized,
            };
          } else {
            option.series[num] = {};
            option.icons[num] = {};
            option.annotations[num] = {};
            option.secondaryIndicators[num] = {};
          }
        }
        if (
          _.isUndefined(option.series[num].name) ||
          _.isUndefined(option.icons[num].seriesName) ||
          _.isUndefined(option.annotations?.[num].seriesName) ||
          _.isUndefined(option.secondaryIndicators?.[num].seriesName)
        ) {
          option.series[num].name = alias;
          option.series[num].displayName = displayName;
          option.icons[num].seriesName = alias;
          option.icons[num].displayName = displayName;
          option.annotations[num].seriesName = alias;
          option.annotations[num].displayName = displayName;
          option.secondaryIndicators[num].seriesName = alias;
          option.secondaryIndicators[num].displayName = displayName;
        }

        if (_.isUndefined(option.icons[num])) {
          option.icons[num] = {
            show: option.series[num]['showIcon'],
            iconType: option.series[num]['iconType'],
          };
          option.annotations[num] = {};
          option.secondaryIndicators[num] = {};
          option.series[num] = {};
        }

        let isPush = false;
        for (let num2 = 0; num2 < this.pivot.aggregations.length; num2++) {
          if (option.series.length >= num2 + 1 && _.eq(alias, option.series[num2].name)) {
            isPush = true;
            series.push(option.series[num2]);
          }
          if (option.icons.length >= num2 + 1 && _.eq(alias, option.icons[num2].seriesName)) {
            icons.push(option.icons[num2]);
          }
          if (option.annotations.length >= num2 + 1 && _.eq(alias, option.annotations[num2].seriesName)) {
            annotations.push(option.annotations[num2]);
          }
          if (
            option.secondaryIndicators.length >= num2 + 1 &&
            _.eq(alias, option.secondaryIndicators[num2].seriesName)
          ) {
            secondaryIndicators.push(option.secondaryIndicators[num2]);
          }
        }

        if (!isPush) {
          option.series[num].name = alias;
          option.series[num].displayName = displayName;
          option.icons[num].seriesName = alias;
          option.icons[num].displayName = displayName;
          option.annotations[num].seriesName = alias;
          option.annotations[num].displayName = displayName;
          option.secondaryIndicators[num].seriesName = alias;
          option.secondaryIndicators[num].displayName = displayName;

          for (let num2 = 0; num2 < this.pivot.aggregations.length; num2++) {
            if (option.series.length >= num2 + 1 && _.eq(alias, option.series[num2].name)) {
              series.push(option.series[num2]);
            }
            if (option.icons.length >= num2 + 1 && _.eq(alias, option.icons[num2].seriesName)) {
              icons.push(option.icons[num2]);
            }
            if (option.annotations.length >= num2 + 1 && _.eq(alias, option.annotations[num2].seriesName)) {
              annotations.push(option.annotations[num2]);
            }
            if (
              option.secondaryIndicators.length >= num2 + 1 &&
              _.eq(alias, option.secondaryIndicators[num2].seriesName)
            ) {
              secondaryIndicators.push(option.secondaryIndicators[num2]);
            }
          }
        }
      }
    }

    option.series = series;
    option.icons = icons;
    option.annotations = annotations;
    option.secondaryIndicators = secondaryIndicators;

    if (seriesLength != option.series.length) {
      option.series = option.series.slice(0, seriesLength);
      option.icons = option.icons.slice(0, seriesLength);
      option.annotations = option.annotations.slice(0, seriesLength);
      option.secondaryIndicators = option.secondaryIndicators.slice(0, seriesLength);
    }

    for (let num = 0; num < seriesLength; num++) {
      const format: UIChartFormatItem = (
        this.uiOption.valueFormat && !this.uiOption.valueFormat.isAll && this.uiOption.valueFormat.each?.length
          ? this.uiOption.valueFormat.each[num]
          : this.uiOption.valueFormat
      ) as UIChartFormatItem;

      const kpi: KPI = { ...initKPI };

      kpi.title = this.fieldInfo.aggs[num];
      let kpiValue: number = _.sum(this.data.columns.find((item) => item.name === kpi.title).value);
      kpi.isPositive = kpiValue >= 0;
      kpi.value = FormatOptionConverter.getFormatValue(kpiValue, format);

      kpi.align = String(option.layout);

      kpi.showLabel = option.showLabel;

      kpi.showIcon = option.icons[num].show;

      if (kpi.showIcon) {
        kpi.iconType = option.icons[num].iconType ? option.icons[num].iconType : 'USER';
      }

      kpi.text = option.annotations[num].show ? option.annotations[num].description : undefined;
      kpi.isPeriod = false;
      kpi.show = true;

      if (
        option.secondaryIndicators[num].show &&
        !_.eq(option.secondaryIndicators[num].indicatorType, LabelSecondaryIndicatorType.PERIOD) &&
        option.secondaryIndicators[num].targetValue &&
        option.secondaryIndicators[num].targetValue != 0
      ) {
        let value: number = kpiValue - (option.secondaryIndicators[num].targetValue || 0);

        kpi.isTargetPositive = value >= 0;

        value = Math.abs(value);

        if (_.eq(option.secondaryIndicators[num].mark, LabelSecondaryIndicatorMarkType.PERCENTAGE)) {
          value = (kpiValue / (option.secondaryIndicators[num].targetValue || 0) - 1) * 100;
        }

        value = Math.floor(Number(value) * Math.pow(10, 1)) / Math.pow(10, 1);

        kpi.targetValue = value.toLocaleString();
        kpi.targetOriginalValue = (
          Math.floor(Number(option.secondaryIndicators[num].targetValue) * Math.pow(10, 1)) / Math.pow(10, 1)
        ).toLocaleString();

        if (_.eq(option.secondaryIndicators[num].mark, LabelSecondaryIndicatorMarkType.PERCENTAGE)) {
          kpi.targetValue += '%';
        }

        kpi.emphasized = option.secondaryIndicators[num].emphasized;
        kpi.emphasizedMessage = this.translate.instant('msg.page.common.kpi.indocator.standard');
      } else if (
        option.secondaryIndicators[num].show &&
        _.eq(option.secondaryIndicators[num].indicatorType, LabelSecondaryIndicatorType.PERIOD)
      ) {
        if (_.isUndefined(this.query) || _.isUndefined(this.widget)) {
          return;
        }

        kpiValue = 0;

        kpi.isPeriod = true;
        kpi.isPositive = kpiValue >= 0;
        kpi.value = FormatOptionConverter.getFormatValue(kpiValue, format);

        kpi.emphasized = option.secondaryIndicators[num].emphasized;
        if (_.eq(option.secondaryIndicators[num].rangeUnit, LabelSecondaryIndicatorPeriod.YEAR)) {
          kpi.emphasizedMessage = this.translate.instant('msg.page.common.kpi.indocator.period.year');
        } else if (_.eq(option.secondaryIndicators[num].rangeUnit, LabelSecondaryIndicatorPeriod.MONTH)) {
          kpi.emphasizedMessage = this.translate.instant('msg.page.common.kpi.indocator.period.month');
        } else if (_.eq(option.secondaryIndicators[num].rangeUnit, LabelSecondaryIndicatorPeriod.DAY)) {
          kpi.emphasizedMessage = this.translate.instant('msg.page.common.kpi.indocator.period.day');
        } else if (_.eq(option.secondaryIndicators[num].rangeUnit, LabelSecondaryIndicatorPeriod.HOUR)) {
          kpi.emphasizedMessage = this.translate.instant('msg.page.common.kpi.indocator.period.hour');
        }

        //kpi.show = false;
      }

      this.list.push(kpi);
    }

    return this.chartOption;
  }
}
