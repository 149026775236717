import { Injectable } from '@angular/core';
import { ShelveFieldType } from '@selfai-platform/bi-domain';
import { Observable, map } from 'rxjs';
import { getCountAggregationsByFieldType, getCountColumnsByFieldType, getCountRowsByFieldType } from '../../utils';
import { DataSettingsService } from '../data-settings.service';

@Injectable({ providedIn: 'root' })
export class GraphChartDataSettingsService {
  constructor(private readonly dataSettingsService: DataSettingsService) {}
  isSettingReadyForDisplay(widgetId: string, contextId?: string): Observable<boolean> {
    return this.dataSettingsService.getSettings(widgetId, contextId).pipe(
      map((settings) => {
        const pivot = settings?.pivot;

        if (!pivot) return false;

        // TODO: need to check this condition and make it more clear
        return (
          getCountColumnsByFieldType(pivot, ShelveFieldType.DIMENSION) === 1 &&
          getCountColumnsByFieldType(pivot, ShelveFieldType.TIMESTAMP) === 0 &&
          getCountRowsByFieldType(pivot, ShelveFieldType.DIMENSION) === 1 &&
          getCountRowsByFieldType(pivot, ShelveFieldType.TIMESTAMP) === 0 &&
          getCountAggregationsByFieldType(pivot, ShelveFieldType.MEASURE) +
            getCountAggregationsByFieldType(pivot, ShelveFieldType.CALCULATED) ===
            1 &&
          getCountColumnsByFieldType(pivot, ShelveFieldType.MEASURE) === 0 &&
          getCountColumnsByFieldType(pivot, ShelveFieldType.CALCULATED) === 0 &&
          getCountRowsByFieldType(pivot, ShelveFieldType.MEASURE) === 0 &&
          getCountRowsByFieldType(pivot, ShelveFieldType.CALCULATED) === 0 &&
          getCountAggregationsByFieldType(pivot, ShelveFieldType.DIMENSION) === 0 &&
          getCountAggregationsByFieldType(pivot, ShelveFieldType.TIMESTAMP) === 0
        );
      }),
    );
  }
}
