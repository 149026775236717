import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import * as _ from 'lodash';

import {
  Dashboard,
  TIME_RANGE_FILTER_EARLIEST_DATETIME,
  TIME_RANGE_FILTER_LATEST_DATETIME,
  TimeRangeFilter,
} from '@selfai-platform/bi-domain';

import { DatasourceService } from '../../../datasource/service/datasource.service';
import { AbstractFilterPopupComponent } from '../abstract-filter-popup.component';
import { TimeRange, TimeRangeData } from '../component/time-range.component';
import moment from 'moment';

@Component({
  selector: 'selfai-bi-time-range-filter',
  styleUrls: ['./time-range-filter.component.scss'],
  templateUrl: './time-range-filter.component.html',
})
export class TimeRangeFilterComponent
  extends AbstractFilterPopupComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  private _isRunningCandidate = false;

  public lastIntervals = '';

  public targetFilter: TimeRangeFilter;

  public rangeBoundary: RangeBoundary;
  public timeRangeList: TimeRange[];
  public isEarliestTime = false;
  public isLatestTime = false;

  @Input()
  public filter: TimeRangeFilter;

  @Input()
  public dashboard: Dashboard;

  @Input()
  public mode = 'CHANGE';

  @Output()
  public changeFilter: EventEmitter<TimeRangeFilter> = new EventEmitter();

  constructor(
    private datasourceService: DatasourceService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const filterChanges: SimpleChange = changes.filter;
    if (filterChanges) {
      const prevFilter: TimeRangeFilter = filterChanges.previousValue;
      const currFilter: TimeRangeFilter = filterChanges.currentValue;
      if (
        this.isLoaded &&
        currFilter &&
        (!prevFilter ||
          prevFilter.field !== currFilter.field ||
          0 < _.difference(prevFilter.intervals, currFilter.intervals).length)
      ) {
        this.setData(filterChanges.currentValue, !filterChanges.firstChange);
      }
    }
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
    this.setData(this.filter, true);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setData(filter: TimeRangeFilter, isBroadcast: boolean = false) {
    if (!this._isRunningCandidate) {
      this._isRunningCandidate = true;
      this.loadingShow();
      const cloneFilter: TimeRangeFilter = _.cloneDeep(filter);
      this.datasourceService
        .getCandidateForFilter(cloneFilter, this.dashboard)
        .then((result) => {
          this.targetFilter = this._setRangeFilter(result, cloneFilter);
          this.safelyDetectChanges();

          isBroadcast && this._broadcastChange();

          this._isRunningCandidate = false;
          this.loadingHide();
        })
        .catch((err) => {
          this._isRunningCandidate = false;
          this.commonExceptionHandler(err);
        });
    }
  }

  public getData(): TimeRangeFilter {
    this.targetFilter.intervals = this.timeRangeList.map((item) => {
      const startDate = moment(item.startDate).format('yyyy-MM-DD HH:mm:ss');
      const endDate = moment(item.endDate).format('yyyy-MM-DD HH:mm:ss');
      return startDate + '/' + endDate;
    });
    return this.targetFilter;
  }

  public getTimeRangeData(item: TimeRange): TimeRangeData {
    return new TimeRangeData(
      this.rangeBoundary.minTime,
      this.rangeBoundary.maxTime,
      item,
      false,
      this.targetFilter.timeUnit,
    );
  }

  public setEarliestTime($event, filter: TimeRangeFilter) {
    const checked = $event.target ? $event.target.checked : $event.currentTarget.checked;

    this.isEarliestTime = checked;

    const interval: string = filter.intervals[0];
    const intervalUI: TimeRange = this.timeRangeList[0];

    if (checked) {
      if (interval.indexOf('/') > -1) {
        const item: TimeRange = new TimeRange(TIME_RANGE_FILTER_EARLIEST_DATETIME, intervalUI.endDate);
        this.timeRangeList[0] = item;
        filter.intervals[0] = item.toInterval();
      }
    } else {
      if (interval.indexOf('/') > -1) {
        const item: TimeRange = new TimeRange(this.rangeBoundary.minTime, intervalUI.endDate);
        this.timeRangeList[0] = item;
        filter.intervals[0] = item.toInterval();
      }
    }

    this._broadcastChange();
  }

  public setLatestTime($event, filter: TimeRangeFilter) {
    const checked = $event.target ? $event.target.checked : $event.currentTarget.checked;

    this.isLatestTime = checked;

    const interval: string = filter.intervals[filter.intervals.length - 1];
    const intervalUI: TimeRange = this.timeRangeList[this.timeRangeList.length - 1];

    if (checked) {
      if (interval.indexOf('/') > -1) {
        const item: TimeRange = new TimeRange(intervalUI.startDate, TIME_RANGE_FILTER_LATEST_DATETIME);
        this.timeRangeList[this.timeRangeList.length - 1] = item;
        filter.intervals[filter.intervals.length - 1] = item.toInterval();
      }
    } else {
      if (interval.indexOf('/') > -1) {
        const item: TimeRange = new TimeRange(intervalUI.startDate, this.rangeBoundary.maxTime);
        this.timeRangeList[this.timeRangeList.length - 1] = item;
        filter.intervals[filter.intervals.length - 1] = item.toInterval();
      }
    }

    this._broadcastChange();
  }

  public addIntervalRange(filter: TimeRangeFilter) {
    if (this.isLatestTime) return;

    const item: TimeRange = new TimeRange(this.rangeBoundary.minTime, this.rangeBoundary.maxTime);
    this.timeRangeList.push(item);
    filter.intervals.push(item.toInterval());

    this._broadcastChange();
  }

  public deleteIntervalRange() {
    this.timeRangeList.length > 1 && this.timeRangeList.pop();
    this.isLatestTime = false;

    this._broadcastChange();
  }

  public onDateChange(date: TimeRange, idx: number) {
    this.timeRangeList[idx] = date;
    this.targetFilter.intervals = this.timeRangeList.map((item) => item.startDate + '/' + item.endDate);

    if (this.mode && this.mode !== 'WIDGET') {
      this._broadcastChange();
    }
  }

  public broadcastChange() {
    this._broadcastChange();
  }

  private _broadcastChange() {
    const filterData: TimeRangeFilter = this.getData();

    if (this.lastIntervals != filterData.intervals.join('')) {
      this.lastIntervals = filterData.intervals.join('');
      this.changeFilter.emit(filterData);
    }
  }

  private _setRangeFilter(result: RangeBoundary, targetFilter: TimeRangeFilter): TimeRangeFilter {
    this.isEarliestTime = false;
    this.isLatestTime = false;

    this.rangeBoundary = result;
    if (targetFilter.intervals && 0 < targetFilter.intervals.length) {
      const items: any[] = [];
      targetFilter.intervals.forEach((item) => {
        const arrInterval: any[] = item.split('/');
        if (TIME_RANGE_FILTER_EARLIEST_DATETIME === arrInterval[0]) {
          this.isEarliestTime = true;
        }
        if (TIME_RANGE_FILTER_LATEST_DATETIME === arrInterval[1]) {
          this.isLatestTime = true;
        }
        items.push(new TimeRange(arrInterval[0], arrInterval[1]));
      });
      this.timeRangeList = items;
    } else {
      const item: TimeRange = new TimeRange(result.minTime, result.maxTime);
      this.timeRangeList = [item];
      targetFilter.intervals = [item.toInterval()];
    }

    return targetFilter;
  }
}

class RangeBoundary {
  minTime: Date;
  maxTime: Date;
}
