/* eslint-disable @typescript-eslint/no-namespace */
import {
  AxisLabel,
  AxisLabelType,
  AxisLine,
  AxisTick,
  AxisType,
  BarMarkType,
  BaseOption,
  ChartAxisLabelType,
  ChartColorType,
  ChartGradientType,
  ChartType,
  ColorRange,
  ColorRangeType,
  CustomSymbol,
  DataLabelPosition,
  DataStyle,
  DataUnit,
  DataZoomType,
  FontSize,
  FontWeight,
  GraphicStyle,
  GraphicType,
  GridViewType,
  ItemStyleSet,
  LabelLayoutType,
  LabelStyle as LabelStyleModel,
  LabelStyleSet,
  LineCornerType,
  LineMarkType,
  LineMode,
  LineStyleSet,
  LineStyleType,
  LineType,
  MapLineStyle,
  MapType,
  Orient,
  PieSeriesViewType,
  PointShape,
  Position,
  PositionLabel,
  Radar,
  SplitLine,
  SymbolType,
  TriggerType,
  UIBarChart,
  UIChartAxis,
  UIChartAxisLabel,
  UIChartAxisLabelCategory,
  UIChartAxisLabelValue,
  UIChartColorByDimension,
  UIChartColorBySeries,
  UIChartColorBySingle,
  UIChartColorByValue,
  UIChartDataLabel,
  UIChartFormat,
  UIChartFormatItem,
  UIChartLegend,
  UIChartZoom,
  UICombineChart,
  UIFormatCurrencyType,
  UIFormatType,
  UIGridChart,
  UILabelChart,
  UILineChart,
  UIMapOption,
  UIOption,
  UIOrient,
  UIPieChart,
  UIPosition,
  UIRadarChart,
  UIScatterChart,
  UiChartDataLabelDisplayType,
  UiChartFormatSymbolPosition,
  VisualMapType,
} from '@selfai-platform/bi-domain';
import {
  ComposeOption,
  InsideDataZoomComponentOption,
  LegendComponentOption,
  PiecewiseVisualMapComponentOption,
  RadarSeriesOption,
  SliderDataZoomComponentOption,
  ToolboxComponentOption,
  VisualMapComponentOption,
} from 'echarts';
import { DataZoomComponentOption } from 'echarts/components';
import * as _ from 'lodash';
import { PROP_MAP_CONFIG } from '../const';

export namespace OptionGenerator {
  export function defaultLimit(type: ChartType): number {
    return ChartType.SANKEY == type ? 50 : 1000;
  }

  export function initUiOption(uiOption: UIOption): UIOption {
    const type: ChartType | undefined = uiOption.type;
    switch (type) {
      case ChartType.BAR:
        uiOption = OptionGenerator.BarChart.defaultBarChartUIOption();
        break;
      case ChartType.LINE:
        uiOption = OptionGenerator.LineChart.defaultLineChartUIOption();
        break;
      case ChartType.CONTROL:
        uiOption = OptionGenerator.LineChart.defaultControlLineChartUIOption();
        break;
      case ChartType.SCATTER:
        uiOption = OptionGenerator.ScatterChart.defaultScatterChartUIOption();
        break;
      case ChartType.HEATMAP:
        uiOption = OptionGenerator.HeatMapChart.defaultHeatMapChartUIOption();
        break;
      case ChartType.GRID:
        uiOption = OptionGenerator.GridChart.defaultGridChartUIOption();
        break;
      case ChartType.BOXPLOT:
        uiOption = OptionGenerator.BoxPlotChart.defaultBoxPlotChartUIOption();
        break;
      case ChartType.PIE:
        uiOption = OptionGenerator.PieChart.defaultPieChartUIOption();
        break;
      case ChartType.DPIE:
        uiOption = OptionGenerator.DPieChart.defaultDPieChartOption();
        break;
      case ChartType.DDONAT:
        uiOption = OptionGenerator.DDonatChart.defaultDDonatChartOptions();
        break;
      case ChartType.LABEL:
        uiOption = OptionGenerator.LabelChart.defaultLabelChartUIOption();
        break;
      case ChartType.WORDCLOUD:
        uiOption = OptionGenerator.WordCloudChart.defaultWordCloudChartUIOption();
        break;
      case ChartType.WATERFALL:
        uiOption = OptionGenerator.WateFallChart.defaultWateFallChartUIOption();
        break;
      case ChartType.RADAR:
        uiOption = OptionGenerator.RadarChart.defaultRadarChartUIOption();
        break;
      case ChartType.TREEMAP:
        uiOption = OptionGenerator.TreeMapChart.defaultTreeMapChartUIOption();
        break;
      case ChartType.COMBINE:
        uiOption = OptionGenerator.CombineChart.defaultCombineChartUIOption();
        break;
      case ChartType.GRAPH:
        uiOption = OptionGenerator.NetworkChart.defaultNetworkChartUIOption();
        break;
      case ChartType.GRAPHV2:
        uiOption = { ...OptionGenerator.NetworkChart.defaultNetworkChartUIOption(), type: ChartType.GRAPHV2 };
        break;
      case ChartType.GAUGE:
        uiOption = OptionGenerator.BarChart.gaugeBarChartUIOption();
        break;
      case ChartType.SANKEY:
        uiOption = OptionGenerator.SankeyChart.defaultSankeyChartUIOption();
        break;
      case ChartType.MAP:
        uiOption = OptionGenerator.MapViewChart.defaultMapViewChartUIOption();
        break;
      default:
        break;
    }

    if (
      type !== ChartType.WORDCLOUD &&
      type !== ChartType.SANKEY &&
      type !== ChartType.GRAPH &&
      type !== ChartType.GRAPHV2 &&
      type !== ChartType.GAUGE &&
      type !== ChartType.TREEMAP
    ) {
      uiOption.limitCheck = true;
      uiOption.limit = OptionGenerator.defaultLimit(type);
    }

    return uiOption;
  }

  export namespace Title {
    export function auto(): any {
      return {};
    }
  }

  export namespace Grid {
    const grid = (top: number, bottom: number, left: number, right: number): any => {
      return {
        top,
        bottom,
        left,
        right,
        containLabel: true,
      };
    };

    export function auto(): any {
      return {};
    }

    export function verticalMode(
      top: number,
      bottom: number,
      left: number,
      right: number,
      withLegend: boolean,
      withDataZoom: boolean,
      withSubAxis: boolean,
    ): any {
      return grid(withLegend ? 40 : top, withDataZoom ? bottom + 50 : bottom, left + 10, withSubAxis ? 0 : right);
    }

    export function horizontalMode(
      top: number,
      bottom: number,
      left: number,
      right: number,
      withLegend: boolean,
      withDataZoom: boolean,
    ): any {
      return grid(withLegend ? 40 : top, bottom + 10, withDataZoom ? left + 50 : left + 10, right);
    }

    export function bothMode(
      top: number,
      bottom: number,
      left: number,
      right: number,
      withLegend: boolean,
      withDataZoom: boolean,
    ): any {
      return grid(withLegend ? 40 : top, withDataZoom ? bottom + 50 : bottom, withDataZoom ? left + 50 : left, right);
    }
  }

  export namespace Axis {
    const axisTitleStyle = (): any => {
      return {
        fontSize: 13,
        fontWeight: FontWeight.BOLD,
        fontFamily: 'SpoqaHanSans',
      };
    };

    const axisLabelStyle = (): any => {
      return {
        fontSize: 13,
        fontFamily: 'SpoqaHanSans',
      };
    };

    const axisLabel = (show: boolean): AxisLabel => {
      return {
        show,
        inside: !show,
        fontSize: 13,
        fontFamily: 'SpoqaHanSans',
      };
    };

    const axisTick = (show: boolean): AxisTick => {
      return {
        show,
      };
    };

    const axisLine = (show: boolean): AxisLine => {
      return {
        show,
      };
    };

    const splitLine = (): SplitLine => {
      return {};
    };

    const radar = (): Radar => {
      return { indicator: [] };
    };

    export function categoryAxis(
      nameLocation: Position,
      nameGap: number | null,
      inverse: boolean,
      showLabel: boolean,
      showTick: boolean,
      showLine: boolean,
    ): any {
      return {
        nameLocation,
        nameGap,
        inverse,
        type: AxisType.CATEGORY,
        nameTextStyle: axisTitleStyle(),
        axisLabel: axisLabel(showLabel),
        axisTick: axisTick(showTick),
        axisLine: axisLine(showLine),
        show: true,
        data: [],
      };
    }

    export function valueAxis(
      nameLocation: Position,
      nameGap: number | null,
      scale: boolean,
      inverse: boolean,
      showLabel: boolean,
      showTick: boolean,
      showLine: boolean,
    ): any {
      return {
        nameLocation,
        nameGap,
        scale,
        inverse,
        type: AxisType.VALUE,
        nameTextStyle: axisTitleStyle(),
        axisLabel: axisLabel(showLabel),
        axisTick: axisTick(showTick),
        axisLine: axisLine(showLine),
        splitLine: splitLine(),
        show: true,
      };
    }

    export function radarAxis(): Radar {
      return radar();
    }
  }

  export namespace Legend {
    const legendTextStyle = (): any => {
      return {
        fontSize: 13,
        fontFamily: 'SpoqaHanSans',
      };
    };

    export function auto(): any {
      return {};
    }

    export function custom(
      show: boolean,
      seriesSync: boolean,
      left: Position,
      symbol: SymbolType,
      width: string | number,
      itemGap: number,
      right = 110,
    ): LegendComponentOption {
      return { show, left, right, width, itemGap, textStyle: legendTextStyle() };
    }
  }

  export namespace DataZoom {
    const verticalSliderDataZoom = (): SliderDataZoomComponentOption => {
      return {
        orient: 'vertical',
        type: DataZoomType.SLIDER,
        left: 0,
      };
    };

    const horizontalSliderDataZoom = (): SliderDataZoomComponentOption => {
      return {
        orient: 'horizontal',
        type: DataZoomType.SLIDER,
        bottom: 0,
      };
    };

    const insideZoom = (orient: Orient): InsideDataZoomComponentOption => {
      return {
        orient: orient.toLowerCase(),
        type: DataZoomType.INSIDE,
      } as InsideDataZoomComponentOption;
    };

    export function verticalDataZoom(): DataZoomComponentOption {
      return verticalSliderDataZoom();
    }

    export function horizontalDataZoom(): DataZoomComponentOption {
      return horizontalSliderDataZoom();
    }

    export function verticalInsideDataZoom(): DataZoomComponentOption {
      return insideZoom(Orient.VERTICAL);
    }

    export function horizontalInsideDataZoom(): InsideDataZoomComponentOption {
      return insideZoom(Orient.HORIZONTAL);
    }
  }

  export namespace Toolbox {
    const toolbox = (show: boolean): any => {
      return {
        show,
        feature: {
          dataZoom: {
            show,
          },
        },
      };
    };

    export function auto(): any {
      return {};
    }

    export function hiddenToolbox(): any {
      return toolbox(false);
    }
  }

  export namespace Tooltip {
    const tooltip = (trigger: TriggerType): any => {
      return {
        trigger,
      };
    };

    export function auto(): any {
      return {};
    }

    export function axisTooltip(): any {
      return tooltip(TriggerType.AXIS);
    }

    export function itemTooltip(): any {
      return tooltip(TriggerType.ITEM);
    }
  }

  export namespace Brush {
    const brush = (transformable?: boolean, inBrush?: any, outOfBrush?: any): any => {
      return {
        transformable,
        inBrush,
        outOfBrush,
      };
    };

    export function auto(): any {
      return {};
    }

    export function selectBrush(): any {
      return brush(false, SymbolStyle.defaultSymbolStyle(), SymbolStyle.dimmedSymbolStyle());
    }
  }

  export namespace VisualMap {
    const visualMap = (type: VisualMapType): VisualMapComponentOption => {
      return {
        type,
        calculable: true,
        show: true,
        orient: 'horizontal',
        hoverLink: true,
        top: Position.TOP,
        left: 'center',
        itemWidth: 15,
        itemHeight: 300,
      };
    };

    export function continuousVisualMap(): VisualMapComponentOption {
      return visualMap(VisualMapType.CONTINUOUS);
    }

    export function piecewiseVisualMap(): PiecewiseVisualMapComponentOption {
      return visualMap(VisualMapType.PIECEWISE) as PiecewiseVisualMapComponentOption;
    }
  }

  export namespace Series {
    const series = (): any[] => {
      return [];
    };
  }

  export namespace Graphic {
    const graphic = (id: string, type: GraphicType, left: Position, top: Position): any => {
      return {
        id,
        type,
        left,
        top,
      };
    };

    const graphicItem = (type: GraphicType, left: Position, top: Position | string, style: GraphicStyle): any => {
      return {
        type,
        left,
        top,
        style,
      };
    };

    const graphicStyle = (fill: string, text: string, font: string): GraphicStyle => {
      return {
        fill,
        text,
        font,
      };
    };

    export function customGraphic(id: string, type: GraphicType, left: Position, top: Position): any {
      return graphic(id, type, left, top);
    }

    export function customGraphicItem(
      type: GraphicType,
      left: Position,
      top: Position | string,
      style: GraphicStyle,
    ): any {
      return graphicItem(type, left, top, style);
    }

    export function customGraphicStyle(fill: string, text: string, font: string): GraphicStyle {
      return graphicStyle(fill, text, font);
    }
  }

  export namespace ItemStyle {
    const itemStyle = (
      color?: string,
      borderColor?: string,
      borderWidth?: number,
      borderType?: LineType,
      shadowBlur?: number,
      shadowColor?: string,
      shadowOffsetX?: number,
      shadowOffsetY?: number,
      opacity?: number,
    ): DataStyle => {
      return {
        color,
        borderColor,
        borderWidth,
        borderType,
        shadowBlur,
        shadowColor,
        shadowOffsetX,
        shadowOffsetY,
        opacity,
      };
    };

    export function auto(): DataStyle {
      return {};
    }

    export function opacity1(): DataStyle {
      return { opacity: 1 };
    }

    export function customItemNormalStyle(
      color?: string,
      borderColor?: string,
      borderWidth?: number,
      borderType?: LineType,
      shadowBlur?: number,
      shadowColor?: string,
      shadowOffsetX?: number,
      shadowOffsetY?: number,
      opacity?: number,
    ): DataStyle {
      return itemStyle(
        color,
        borderColor,
        borderWidth,
        borderType,
        shadowBlur,
        shadowColor,
        shadowOffsetX,
        shadowOffsetY,
        opacity,
      );
    }

    export function customItemEmphasisStyle(
      color?: string,
      borderColor?: string,
      borderWidth?: number,
      borderType?: LineType,
      shadowBlur?: number,
      shadowColor?: string,
      shadowOffsetX?: number,
      shadowOffsetY?: number,
      opacity?: number,
    ): DataStyle {
      return itemStyle(
        color,
        borderColor,
        borderWidth,
        borderType,
        shadowBlur,
        shadowColor,
        shadowOffsetX,
        shadowOffsetY,
        opacity,
      );
    }

    export function customItemStyle(
      color?: string,
      borderColor?: string,
      borderWidth?: number,
      borderType?: LineType,
      shadowBlur?: number,
      shadowColor?: string,
      shadowOffsetX?: number,
      shadowOffsetY?: number,
      opacity?: number,
    ): ItemStyleSet {
      return {
        normal: itemStyle(
          color,
          borderColor,
          borderWidth,
          borderType,
          shadowBlur,
          shadowColor,
          shadowOffsetX,
          shadowOffsetY,
          opacity,
        ),
      };
    }
  }

  export namespace LineStyle {
    const lineStyle = (
      color?: string,
      width?: number,
      type?: LineType,
      shadowBlur?: number,
      shadowColor?: string,
      shadowOffsetX?: number,
      shadowOffsetY?: number,
      opacity?: number,
    ): DataStyle => {
      return {
        color,
        width,
        type,
        shadowBlur,
        shadowColor,
        shadowOffsetX,
        shadowOffsetY,
        opacity,
      };
    };

    export function auto(): LineStyleSet {
      return {
        normal: {},
      };
    }

    export function customLineNormalStyle(
      color?: string,
      width?: number,
      type?: LineType,
      shadowBlur?: number,
      shadowColor?: string,
      shadowOffsetX?: number,
      shadowOffsetY?: number,
      opacity?: number,
    ): DataStyle {
      return lineStyle(color, width, type, shadowBlur, shadowColor, shadowOffsetX, shadowOffsetY, opacity);
    }

    export function customItemStyle(
      color?: string,
      width?: number,
      type?: LineType,
      shadowBlur?: number,
      shadowColor?: string,
      shadowOffsetX?: number,
      shadowOffsetY?: number,
      opacity?: number,
    ): ItemStyleSet {
      return {
        normal: lineStyle(color, width, type, shadowBlur, shadowColor, shadowOffsetX, shadowOffsetY, opacity),
      };
    }
  }

  export namespace AreaStyle {
    const areaStyle = (opacity: number): DataStyle => {
      return {
        opacity,
      };
    };

    const radarAreaStyle = (type?: string): any => {
      return {
        type: type,
      };
    };

    export function auto(): ItemStyleSet {
      return {
        normal: {},
      };
    }

    export function customAreaNormalStyle(opacity?: number): DataStyle {
      return areaStyle(opacity);
    }

    export function customAreaStyle(opacity?: number): DataStyle {
      return areaStyle(opacity);
    }

    export function radarItemAreaStyle(type?: string): DataStyle {
      return radarAreaStyle(type);
    }
  }

  export namespace LabelStyle {
    const normalLabelStyle = (show: boolean, position?: PositionLabel): LabelStyleModel => {
      return {
        show,
        position,
      };
    };

    const emphasisLabelStyle = (color?: string): LabelStyleModel => {
      return {
        color,
      };
    };

    export function auto(): LabelStyleModel {
      return {};
    }

    export function defaultLabelStyle(show: boolean, position: PositionLabel): LabelStyleModel {
      return normalLabelStyle(show, position);
    }

    export function stackBarLabelStyle(orient: Orient, show?: boolean, chartType?: ChartType): LabelStyleSet {
      return {
        normal: _.eq(orient, Orient.VERTICAL)
          ? normalLabelStyle(show, PositionLabel.INSIDETOP)
          : _.eq(chartType, ChartType.GAUGE)
          ? normalLabelStyle(show, PositionLabel.INSIDE)
          : normalLabelStyle(show, PositionLabel.INSIDERIGHT),
        emphasis: emphasisLabelStyle('#fff'),
      };
    }

    export function multipleBarLabelStyle(orient: Orient, show?: boolean): LabelStyleModel {
      return _.eq(orient, Orient.VERTICAL)
        ? normalLabelStyle(show, PositionLabel.TOP)
        : normalLabelStyle(show, PositionLabel.RIGHT);
    }
  }

  export namespace SymbolStyle {
    const symbol = (opacity?: number, symbol?: SymbolType): any => {
      return {
        opacity,
        symbol,
      };
    };

    export function defaultSymbolStyle(): any {
      return symbol(1);
    }

    export function dimmedSymbolStyle(): any {
      return symbol(0.2);
    }
  }

  export namespace TextStyle {
    const textStyle = (color?: string, fontFamily?: string, fontWeight?: FontWeight, fontSize?: number): any => {
      return {
        color,
        fontFamily,
        fontWeight,
        fontSize,
      };
    };

    export function auto(): any {
      return {};
    }

    export function customTextStyle(
      color?: string,
      fontFamily?: string,
      fontWeight?: FontWeight,
      fontSize?: number,
    ): any {
      return textStyle(color, fontFamily, fontWeight, fontSize);
    }
  }

  export namespace UI {
    export namespace Color {
      export function dimensionUIChartColor(schema: string): UIChartColorByDimension {
        return {
          schema,
          targetField: '',
          type: ChartColorType.DIMENSION,
        };
      }

      export function measureUIChartColor(schema: string): UIChartColorBySeries {
        return {
          schema,
          type: ChartColorType.SERIES,
        };
      }

      export function valueUIChartColor(mode: ChartGradientType, schema: string): UIChartColorByValue {
        return {
          mode,
          schema,
          type: ChartColorType.MEASURE,
        };
      }

      export function singleUIChartColor(code: string): UIChartColorBySingle {
        return {
          type: ChartColorType.SINGLE,
          code,
        };
      }
    }

    export namespace Range {
      export function colorRange(
        type: ColorRangeType,
        color: string,
        fixMin?: number,
        fixMax?: number,
        gt?: number,
        lte?: number,
        symbol = 'circle',
      ): ColorRange {
        return {
          type,
          fixMin,
          fixMax,
          gt,
          lte,
          color,
          symbol,
        };
      }
    }

    export namespace Format {
      export function custom(
        isAll: boolean,
        each: UIChartFormatItem[],
        type: string,
        sign: string,
        decimal: number,
        useThousandsSep: boolean,
      ): UIChartFormat {
        return {
          isAll: isAll,
          each: each,
          type: type,
          sign: sign,
          decimal: decimal,
          useThousandsSep: useThousandsSep,
        };
      }

      export function customSymbol(pos: UiChartFormatSymbolPosition): CustomSymbol {
        return {
          pos,
          value: '',
          abbreviations: false,
        };
      }
    }

    export namespace Axis {
      export function xAxis(
        mode: AxisLabelType,
        showName: boolean,
        showLabel: boolean,
        label?: UIChartAxisLabel,
      ): UIChartAxis {
        return {
          mode,
          showName,
          showLabel,
          label,
        };
      }

      export function yAxis(
        mode: AxisLabelType,
        showName: boolean,
        showLabel: boolean,
        label?: UIChartAxisLabel,
      ): UIChartAxis {
        return {
          mode,
          showName,
          showLabel,
          label,
        };
      }
    }

    export namespace AxisLabel {
      export function axisLabelForValue(type: ChartAxisLabelType): UIChartAxisLabelValue {
        return {
          type,
          useDefault: true,
        };
      }

      export function axisLabelForCategory(type: ChartAxisLabelType): UIChartAxisLabelCategory {
        return {
          type,
        };
      }
    }

    export namespace DataLabel {
      export function label(
        showValue: boolean,
        displayTypes?: UiChartDataLabelDisplayType[],
        pos?: DataLabelPosition,
      ): UIChartDataLabel {
        return {
          showValue,
          displayTypes,
          pos,
        };
      }
    }

    export namespace Legend {
      const legendTextStyle = (): any => {
        return {
          fontSize: 13,
          fontFamily: 'SpoqaHanSans',
        };
      };

      export function pagingLegend(show: boolean, count: number): UIChartLegend {
        return {
          count,
          auto: show,
          pos: UIPosition.TOP,
          textStyle: legendTextStyle(),
        };
      }
    }

    export namespace DataZoom {
      export function sliderDataZoom(show: boolean, orient: UIOrient): UIChartZoom {
        return {
          orient,
          auto: show,
        };
      }
    }
  }

  export namespace BarChart {
    export function defaultBarChartOption(): ComposeOption<ToolboxComponentOption> {
      return {
        type: ChartType.BAR,
        grid: [Grid.verticalMode(10, 0, 0, 10, false, true, false)],
        xAxis: [Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
        yAxis: [Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
        legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20, 5),
        dataZoom: [DataZoom.horizontalDataZoom(), DataZoom.horizontalInsideDataZoom()],
        tooltip: Tooltip.itemTooltip(),
        toolbox: Toolbox.hiddenToolbox(),
        brush: Brush.selectBrush(),
        series: [],
      };
    }

    export function histogramBarChartOption(): BaseOption {
      return {
        type: ChartType.BAR,
        grid: [Grid.verticalMode(10, null, 10, 10, false, true, false)],
        xAxis: [Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
        yAxis: [Axis.categoryAxis(Position.INSIDE, null, false, false, true, true)],
        legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
        tooltip: Tooltip.axisTooltip(),
        series: [],
      };
    }

    export function gaugeBarChartOption(): BaseOption {
      return {
        type: ChartType.GAUGE,
        grid: [Grid.verticalMode(10, 0, 0, 10, false, true, false)],
        xAxis: [Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
        yAxis: [Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
        legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20, 5),
        tooltip: Tooltip.itemTooltip(),
        toolbox: Toolbox.hiddenToolbox(),
        brush: Brush.selectBrush(),
        series: [],
      };
    }

    export function defaultBarChartUIOption(): UIBarChart {
      return {
        type: ChartType.BAR,
        color: UI.Color.measureUIChartColor('SC1'),
        xAxis: UI.Axis.xAxis(
          AxisLabelType.ROW,
          true,
          true,
          UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY),
        ),
        yAxis: UI.Axis.yAxis(
          AxisLabelType.COLUMN,
          true,
          true,
          UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE),
        ),
        dataLabel: UI.DataLabel.label(false),
        legend: UI.Legend.pagingLegend(false, 5),
        chartZooms: [UI.DataZoom.sliderDataZoom(true, UIOrient.HORIZONTAL)],
        mark: BarMarkType.MULTIPLE,
        align: Orient.VERTICAL,

        fontSize: FontSize.NORMAL,
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }

    export function histogramBarChartUIOption(): UIBarChart {
      return {
        type: ChartType.BAR,
        color: UI.Color.measureUIChartColor('SC1'),
        xAxis: UI.Axis.xAxis(AxisLabelType.ROW, true, true, UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE)),
        yAxis: UI.Axis.yAxis(
          AxisLabelType.COLUMN,
          false,
          false,
          UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY),
        ),
        dataLabel: UI.DataLabel.label(false),
        align: Orient.HORIZONTAL,
      };
    }

    export function gaugeBarChartUIOption(): UIBarChart {
      return {
        type: ChartType.GAUGE,
        color: UI.Color.dimensionUIChartColor('SC1'),
        xAxis: UI.Axis.xAxis(AxisLabelType.ROW, true, true, UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE)),
        yAxis: UI.Axis.yAxis(
          AxisLabelType.COLUMN,
          true,
          true,
          UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY),
        ),
        dataLabel: UI.DataLabel.label(false),
        legend: UI.Legend.pagingLegend(false, 5),
        mark: BarMarkType.STACKED,
        align: Orient.HORIZONTAL,
        unitType: DataUnit.PERCENT,
        fontSize: FontSize.NORMAL,
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace LineChart {
    export function defaultLineChartOption(): ComposeOption<ToolboxComponentOption> {
      return {
        type: ChartType.LINE,
        grid: [Grid.verticalMode(10, 0, 0, 10, false, true, false)],
        xAxis: [Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
        yAxis: [Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
        legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
        dataZoom: [DataZoom.horizontalDataZoom(), DataZoom.horizontalInsideDataZoom()],
        tooltip: Tooltip.itemTooltip(),
        toolbox: Toolbox.hiddenToolbox(),
        brush: Brush.selectBrush(),
        series: [],
      };
    }

    export function defaultLineChartUIOption(): UILineChart {
      LineStyle;
      return {
        type: ChartType.LINE,
        color: UI.Color.measureUIChartColor('SC1'),
        xAxis: UI.Axis.xAxis(
          AxisLabelType.ROW,
          true,
          true,
          UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY),
        ),
        yAxis: UI.Axis.yAxis(
          AxisLabelType.COLUMN,
          true,
          true,
          UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE),
        ),
        dataLabel: UI.DataLabel.label(false),
        legend: UI.Legend.pagingLegend(true, 5),
        chartZooms: [UI.DataZoom.sliderDataZoom(true, UIOrient.HORIZONTAL)],
        mark: LineMarkType.LINE,
        curveStyle: LineCornerType.STRAIGHT,
        lineStyle: LineStyleType.POINT_LINE,
        lineMode: LineMode.NORMAL,
        fontSize: FontSize.NORMAL,
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }

    export function defaultControlLineChartUIOption(): UIOption {
      return _.extend(defaultLineChartUIOption(), { type: ChartType.CONTROL });
    }
  }

  export namespace ScatterChart {
    export function defaultScatterChartOption(): ComposeOption<ToolboxComponentOption> {
      return {
        type: ChartType.SCATTER,
        grid: [Grid.bothMode(10, 0, 0, 0, false, true)],
        legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
        xAxis: [Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
        yAxis: [Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
        dataZoom: [
          DataZoom.horizontalDataZoom(),
          DataZoom.verticalDataZoom(),
          DataZoom.verticalInsideDataZoom(),
          DataZoom.horizontalInsideDataZoom(),
        ],
        tooltip: Tooltip.itemTooltip(),
        toolbox: Toolbox.hiddenToolbox(),
        brush: Brush.selectBrush(),
        series: [],
      };
    }

    export function defaultScatterChartUIOption(): UIScatterChart {
      return {
        type: ChartType.SCATTER,
        color: UI.Color.dimensionUIChartColor('SC1'),

        xAxis: UI.Axis.xAxis(AxisLabelType.ROW, true, true, UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE)),
        yAxis: UI.Axis.yAxis(
          AxisLabelType.COLUMN,
          true,
          true,
          UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE),
        ),
        dataLabel: UI.DataLabel.label(false),
        legend: UI.Legend.pagingLegend(true, 5),
        chartZooms: [
          UI.DataZoom.sliderDataZoom(true, UIOrient.HORIZONTAL),
          UI.DataZoom.sliderDataZoom(true, UIOrient.VERTICAL),
        ],
        pointShape: PointShape.CIRCLE,
        pointTransparency: 1,
        fontSize: FontSize.NORMAL,
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace HeatMapChart {
    export function defaultHeatMapChartOption(): ComposeOption<ToolboxComponentOption> {
      return {
        type: ChartType.HEATMAP,
        grid: [Grid.bothMode(10, 0, 0, 0, false, true)],
        legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
        xAxis: [Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
        yAxis: [Axis.categoryAxis(Position.INSIDE, null, true, true, true, true)],
        dataZoom: [
          DataZoom.horizontalDataZoom(),
          DataZoom.verticalDataZoom(),
          DataZoom.verticalInsideDataZoom(),
          DataZoom.horizontalInsideDataZoom(),
        ],
        tooltip: Tooltip.itemTooltip(),
        toolbox: Toolbox.hiddenToolbox(),
        brush: Brush.selectBrush(),
        series: [],
      };
    }

    export function defaultHeatMapChartUIOption(): UIOption {
      return {
        type: ChartType.HEATMAP,
        color: UI.Color.valueUIChartColor(ChartGradientType.LINEAR, 'VC1'),
        xAxis: UI.Axis.xAxis(
          AxisLabelType.ROW,
          true,
          true,
          UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY),
        ),
        yAxis: UI.Axis.yAxis(
          AxisLabelType.COLUMN,
          true,
          true,
          UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY),
        ),
        dataLabel: UI.DataLabel.label(false),
        legend: UI.Legend.pagingLegend(true, 5),
        chartZooms: [
          UI.DataZoom.sliderDataZoom(true, UIOrient.HORIZONTAL),
          UI.DataZoom.sliderDataZoom(true, UIOrient.VERTICAL),
        ],
        fontSize: FontSize.NORMAL,
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace GridChart {
    export function defaultGridChartUIOption(): UIGridChart {
      return {
        type: ChartType.GRID,
        color: UI.Color.singleUIChartColor(''),
        dataType: GridViewType.PIVOT,
        measureLayout: UIOrient.VERTICAL,
        headerStyle: {
          fontSize: FontSize.NORMAL,
          showHeader: true,
          fontStyles: [],
          hAlign: UIPosition.LEFT,
          vAlign: UIPosition.MIDDLE,
          backgroundColor: '#ffffff',
          fontColor: '#000',
        },
        contentStyle: {
          hAlign: UIPosition.LEFT,
          vAlign: UIPosition.MIDDLE,
          fontSize: FontSize.NORMAL,
          fontStyles: [],
          fontColor: '',
          showHeader: false,
        },
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace BoxPlotChart {
    export function defaultBoxPlotChartOption(): ComposeOption<ToolboxComponentOption> {
      return {
        type: ChartType.BOXPLOT,
        grid: [Grid.verticalMode(10, 0, 0, 10, false, true, false)],
        xAxis: [Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
        yAxis: [Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
        dataZoom: [DataZoom.horizontalDataZoom(), DataZoom.horizontalInsideDataZoom()],
        tooltip: Tooltip.itemTooltip(),
        toolbox: Toolbox.hiddenToolbox(),
        series: [],
      };
    }

    export function defaultBoxPlotChartUIOption(): UIOption {
      return {
        type: ChartType.BOXPLOT,
        color: UI.Color.valueUIChartColor(ChartGradientType.LINEAR, 'VC1'),
        xAxis: UI.Axis.xAxis(
          AxisLabelType.ROW,
          true,
          true,
          UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY),
        ),
        yAxis: UI.Axis.yAxis(
          AxisLabelType.COLUMN,
          true,
          true,
          UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE),
        ),
        dataLabel: UI.DataLabel.label(false),
        chartZooms: [UI.DataZoom.sliderDataZoom(true, UIOrient.HORIZONTAL)],
        fontSize: FontSize.NORMAL,
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace DPieChart {
    export function defaultDPieChartOption() {
      return {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0,
          },
        },
        title: {
          text: '3D Pie',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 35,
            dataLabels: {
              enabled: true,
              format: '{point.name}',
            },
          },
        },
        series: [
          {
            type: 'pie',
            name: 'Browser share',
            data: [
              ['Firefox', 45.0],
              ['IE', 26.8],
              {
                name: 'Chrome',
                y: 12.8,
                sliced: true,
                selected: true,
              },
              ['Safari', 8.5],
              ['Opera', 6.2],
              ['Others', 0.7],
            ],
          },
        ],
      };
    }
  }

  export namespace DDonatChart {
    export function defaultDDonatChartOptions() {
      return {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45,
          },
        },
        title: {
          text: '3D Donat',
        },
        subtitle: {
          text: '3D donut in Highcharts',
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45,
          },
        },
        series: [
          {
            name: 'Amount',
            data: [
              ['Bananas', 8],
              ['Kiwi', 3],
              ['Mixed nuts', 1],
              ['Oranges', 6],
              ['Apples', 8],
              ['Pears', 4],
              ['Clementines', 4],
              ['Reddish (bag)', 1],
              ['Grapes (bunch)', 1],
            ],
          },
        ],
      };
    }
  }

  export namespace PieChart {
    export function defaultPieChartOption(): BaseOption {
      return {
        type: ChartType.PIE,
        legend: Legend.custom(true, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
        tooltip: Tooltip.itemTooltip(),
        series: [],
      };
    }

    export function defaultPieChartUIOption(): UIPieChart {
      return {
        type: ChartType.PIE,
        color: UI.Color.dimensionUIChartColor('SC2'),
        dataLabel: UI.DataLabel.label(false),
        legend: UI.Legend.pagingLegend(true, 5),
        markType: PieSeriesViewType.SECTOR,
        fontSize: FontSize.NORMAL,
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace LabelChart {
    export function defaultLabelChartOption(): BaseOption {
      return {
        type: ChartType.LABEL,
        series: [],
      };
    }

    export function defaultLabelChartUIOption(): UILabelChart {
      return {
        type: ChartType.LABEL,
        layout: LabelLayoutType.HORIZONTAL,
        showLabel: true,
        icons: [{}],
        annotations: [{}],
        secondaryIndicators: [{}],
        series: [{}],
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace WordCloudChart {
    export function defaultWordCloudChartOption(): BaseOption {
      return {
        type: ChartType.WORDCLOUD,
        series: [],
      };
    }

    export function defaultWordCloudChartUIOption(): UIOption {
      return {
        type: ChartType.WORDCLOUD,
        color: UI.Color.dimensionUIChartColor('SC1'),
      };
    }
  }

  export namespace WateFallChart {
    export function defaultWateFallChartOption(): ComposeOption<ToolboxComponentOption> {
      return {
        type: ChartType.WATERFALL,
        grid: [Grid.verticalMode(10, 0, 0, 10, false, true, false)],
        xAxis: [Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
        yAxis: [Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
        dataZoom: [DataZoom.horizontalDataZoom(), DataZoom.horizontalInsideDataZoom()],
        tooltip: Tooltip.axisTooltip(),
        toolbox: Toolbox.hiddenToolbox(),
        series: [],
      };
    }

    export function defaultWateFallChartUIOption(): UIBarChart {
      return {
        type: ChartType.WATERFALL,
        xAxis: UI.Axis.xAxis(
          AxisLabelType.ROW,
          true,
          true,
          UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY),
        ),
        yAxis: UI.Axis.yAxis(
          AxisLabelType.COLUMN,
          true,
          true,
          UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE),
        ),
        dataLabel: UI.DataLabel.label(false),
        chartZooms: [UI.DataZoom.sliderDataZoom(true, UIOrient.HORIZONTAL)],
        align: Orient.VERTICAL,
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace RadarChart {
    export function defaultRadarChartOption(): ComposeOption<RadarSeriesOption> {
      return {
        type: ChartType.RADAR,
        radar: Axis.radarAxis(),
        legend: Legend.custom(true, true, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
        tooltip: Tooltip.itemTooltip(),
        series: [],
      };
    }

    export function defaultRadarChartUIOption(): UIRadarChart {
      return {
        type: ChartType.RADAR,
        color: UI.Color.measureUIChartColor('SC1'),
        legend: UI.Legend.pagingLegend(true, 5),
        dataLabel: UI.DataLabel.label(false),
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
        mark: LineMarkType.LINE,
      };
    }
  }

  export namespace CombineChart {
    export function defaultCombineChartOption(): ComposeOption<ToolboxComponentOption> {
      return {
        type: ChartType.COMBINE,
        grid: [Grid.verticalMode(10, 0, 0, 10, false, true, false)],
        xAxis: [Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
        yAxis: [
          Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true),
          Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true),
        ],
        legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
        dataZoom: [DataZoom.horizontalDataZoom(), DataZoom.horizontalInsideDataZoom()],
        tooltip: Tooltip.itemTooltip(),
        toolbox: Toolbox.hiddenToolbox(),
        brush: Brush.selectBrush(),
        series: [],
      };
    }

    export function defaultCombineChartUIOption(): UICombineChart {
      return {
        type: ChartType.COMBINE,
        color: UI.Color.measureUIChartColor('SC1'),
        xAxis: UI.Axis.xAxis(
          AxisLabelType.ROW,
          true,
          true,
          UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY),
        ),
        yAxis: UI.Axis.yAxis(
          AxisLabelType.COLUMN,
          true,
          true,
          UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE),
        ),
        secondaryAxis: UI.Axis.yAxis(
          AxisLabelType.SUBCOLUMN,
          true,
          true,
          UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE),
        ),

        dataLabel: UI.DataLabel.label(false),
        legend: UI.Legend.pagingLegend(true, 5),
        chartZooms: [UI.DataZoom.sliderDataZoom(true, UIOrient.HORIZONTAL)],
        barMarkType: BarMarkType.MULTIPLE,
        lineMarkType: LineMarkType.LINE,
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace TreeMapChart {
    export function defaultTreeMapChartOption(): BaseOption {
      return {
        type: ChartType.TREEMAP,
        tooltip: Tooltip.itemTooltip(),
        series: [],
      };
    }

    export function defaultTreeMapChartUIOption(): UIOption {
      return {
        type: ChartType.TREEMAP,
        color: UI.Color.dimensionUIChartColor('SC1'),
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
        fontSize: FontSize.NORMAL,
        dataLabel: {
          showValue: true,
          hAlign: UIPosition.CENTER,
          vAlign: UIPosition.CENTER,
          displayTypes: [undefined, undefined, undefined, UiChartDataLabelDisplayType.SERIES_NAME],
        },
      };
    }
  }

  export namespace NetworkChart {
    export function defaultNetworkChartOption(): BaseOption {
      return {
        type: ChartType.GRAPH,
        legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
        tooltip: Tooltip.itemTooltip(),
        series: [],
      };
    }

    export function defaultNetworkChartUIOption(): UIOption {
      return {
        type: ChartType.GRAPH,
        color: UI.Color.dimensionUIChartColor('SC1'),
        legend: UI.Legend.pagingLegend(true, 5),
        dataLabel: UI.DataLabel.label(
          true,
          [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            UiChartDataLabelDisplayType.NODE_NAME,
          ],
          DataLabelPosition.TOP,
        ),
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace SankeyChart {
    export function defaultSankeyChartOption(): BaseOption {
      return {
        type: ChartType.SANKEY,
        legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
        tooltip: Tooltip.itemTooltip(),
        series: [],
      };
    }

    export function defaultSankeyChartUIOption(): UIOption {
      return {
        type: ChartType.SANKEY,
        color: UI.Color.dimensionUIChartColor('SC1'),
        legend: UI.Legend.pagingLegend(true, 5),
        dataLabel: UI.DataLabel.label(true, [
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          UiChartDataLabelDisplayType.NODE_NAME,
        ]),
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
      };
    }
  }

  export namespace MapViewChart {
    export function defaultMapViewChartOption(): BaseOption {
      return {
        type: ChartType.MAP,
        tooltip: Tooltip.itemTooltip(),
        series: [],
      };
    }

    export function defaultMapViewChartUIOption(): UIMapOption {
      let defaultStyle = 'Light';
      const propMapConf = sessionStorage.getItem(PROP_MAP_CONFIG);
      if (propMapConf) {
        const objConf = JSON.parse(propMapConf);
        objConf && objConf.defaultBaseMap && (defaultStyle = objConf.defaultBaseMap);
      }

      return <any>{
        type: ChartType.MAP,
        layerNum: 0,
        showMapLayer: true,
        map: MapType.OSM,
        style: defaultStyle,
        licenseNotation: '© OpenStreetMap contributors',
        showDistrictLayer: true,
        districtUnit: 'state',
        layers: [
          {
            type: 'symbol',
            name: 'Layer1',
            symbol: 'CIRCLE',
            color: {
              by: 'NONE',
              column: 'NONE',
              schema: '#6344ad',
              transparency: 50,
            },
            size: {
              by: 'NONE',
              column: 'NONE',
              max: 10,
            },
            outline: null,
            clustering: false,
            coverage: 50,
            thickness: {
              by: 'NONE',
              column: 'NONE',
              maxValue: 2,
            },
            lineStyle: MapLineStyle.SOLID,
          },
        ],
        valueFormat: UI.Format.custom(
          true,
          null,
          String(UIFormatType.NUMBER),
          String(UIFormatCurrencyType.KRW),
          2,
          true,
        ),
        legend: {
          pos: UIPosition.RIGHT_BOTTOM,
          showName: false,
          auto: true,
        },
        toolTip: {
          displayColumns: [],
          displayTypes: [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'LAYER_NAME',
            'LOCATION_INFO',
            'DATA_VALUE',
          ],
        },
      };
    }
  }
}
