import { Datasource, DatasourceField, FieldNameAlias, FieldValueAlias } from '../../../datasource';
import { BoardConfiguration, BoardDataSource, Dashboard } from '../../models';
import { setSingleDataSource } from './set-single-datasource';

export function setDatasourceForDashboard(boardInfo: Dashboard): Dashboard {
  const boardInfoCloned: Dashboard = { ...boardInfo };
  let dashboardConf: BoardConfiguration = { ...boardInfoCloned.configuration };
  const boardDs: BoardDataSource | undefined =
    dashboardConf && dashboardConf.dataSource ? dashboardConf.dataSource : undefined;

  if (boardInfo?.dataSources?.length > 0 && boardDs) {
    const nameAliasList: FieldNameAlias[] = boardInfo.aliases
      .filter((alias) => Boolean((alias as FieldNameAlias).nameAlias))
      .map((item) => <FieldNameAlias>item);
    const valueAliasList: FieldValueAlias[] = boardInfo.aliases
      .filter((alias) => Boolean((alias as FieldValueAlias).valueAlias))
      .map((item) => <FieldValueAlias>item);

    let summary: { reorderDsList: Datasource[]; totalFields: DatasourceField[] } = {
      reorderDsList: [],
      totalFields: [],
    };
    if ('multi' === boardDs.type) {
      summary = boardDs.dataSources.reduce(
        (acc: { reorderDsList: Datasource[]; totalFields: DatasourceField[] }, currVal) => {
          const singleSummary: { reorderDsList: Datasource[]; totalFields: DatasourceField[] } = setSingleDataSource(
            currVal,
            boardInfo,
            nameAliasList,
            valueAliasList,
          );
          acc.reorderDsList = acc.reorderDsList.concat(singleSummary.reorderDsList);
          acc.totalFields = acc.totalFields.concat(singleSummary.totalFields);
          return acc;
        },
        { reorderDsList: [], totalFields: [] },
      );
    } else {
      summary = setSingleDataSource(boardDs, boardInfo, nameAliasList, valueAliasList);
    }

    boardInfoCloned.dataSources = summary.reorderDsList;
    dashboardConf = { ...dashboardConf, fields: summary.totalFields };
  }

  boardInfoCloned.configuration = dashboardConf;

  return boardInfoCloned;
}
