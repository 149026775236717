import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import $ from 'jquery';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, catchError, of, switchMap, takeUntil } from 'rxjs';

import {
  BI_ROOT_ROUTE,
  BiPermissionService,
  Dashboard,
  DashboardDomainService,
  PermissionForEntityFactory,
  RecycleBinApiToDomainService,
  WorkbookActionsService,
  Workspace,
} from '@selfai-platform/bi-domain';
import { BiEntityPermission, DestroyService } from '@selfai-platform/shared';
import { provideDialogService } from '@selfai-platform/shell';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { CookieConstant } from '../../../common/constant/cookie.constant';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { CommonUtil } from '../../../common/util/common.util';
import { StringUtil } from '../../../common/util/string.util';
import { DashboardService } from '../../../dashboard/service';
import { DashboardApiLegacyService } from '../../../dashboard/service/dashboard-api.service';
import { Page, PageResult } from '../../../domain/common/page';
import { WorkbookService } from '../../service/workbook.service';

@Component({
  selector: 'app-workbook-navigation',
  templateUrl: './workbook-navigation.component.html',
  styleUrls: ['./workbook-navigation.component.scss'],
  providers: [provideDialogService(), DestroyService],
  animations: [
    trigger('slideInOut', [
      state('in', style({ height: '*', opacity: 1 })),
      transition('* <=> *', [
        query(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            group([
              animate('100ms 100ms ease-in-out', style({ height: 0 })),
              animate('100ms ease-in-out', style({ opacity: '0' })),
            ]),
          ],
          { optional: true },
        ),
        query(
          ':enter',
          [
            style({ height: '0', opacity: 0 }),

            group([
              animate('100ms ease-in-out', style({ height: '*' })),
              animate('100ms 100ms ease-in-out', style({ opacity: '1' })),
            ]),
          ],
          { optional: true },
        ),
      ]),
    ]),

    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('30ms', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('30ms', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
    trigger('enterAnimationUp', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('30ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('30ms', style({ transform: 'translateY(100%)', opacity: 0 })),
      ]),
    ]),
    trigger('enterAnimationDown', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate('30ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('30ms', style({ transform: 'translateY(-100%)', opacity: 0 })),
      ]),
    ]),
    trigger('enterAnimationToggle', [
      transition(':enter', [style({ opacity: 0 }), animate('0ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class WorkbookNavigationComponent
  extends AbstractComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() workbook;
  @Input() dashboards;
  @Input() _boardComp;
  @Input() selectedDashboard;
  @Input() onlyShowingFlag;
  @Input() workbookId;
  @Input() workspace;
  @Input() publicType;
  @Input() itemId;
  @Input() selectedDatasources;
  @Input() datasources;
  @Input() dashboardSrchText;
  @Input() dashboardPage;
  @Input() isShowMoreDashboardList;
  @Input() dashboardId;
  @Input() confirmDeleteInput;
  @ViewChild('navigation') navigation;
  @ViewChildren('navigationItem') navigationItem;
  @ViewChild('leftMenu', { static: true }) leftMenu;

  @Output() dashBoard = new EventEmitter<Dashboard>();
  @Output() dashboardName = new EventEmitter<string>();
  @Output() gridMenu = new EventEmitter<boolean>();
  @Output() modalSetProfile = new EventEmitter<boolean>();
  @Output() clientIDDataEmitter = new EventEmitter<boolean>();

  @HostListener('window:onload', ['$event'])
  public gridMenuState = false;

  public confirmModal = false;

  @Output() confirmDelete = new EventEmitter<boolean>();
  public confirmDel = false;
  public isShowCreateDashboard = false;
  public isDashboardNameEditMode = false;
  public isDashboardDescEditMode = false;
  public tempLoadBoard;
  public listType = 'LIST';
  public editName = true;
  public intervalResizer: any;
  public trumbView = false;
  public arrows = true;
  public isCloseDashboardList = false;

  public asyncAnimation = false;
  public interval: any;
  public dashboardsPins: any[] = [{ name: 'ok' }];
  public showActionsBtnPanel = false;
  public showCharts = false;

  public gettingStopedBtn = false;
  public $window = window;
  public username: string;
  biEntityPermission = BiEntityPermission;
  private workspacePermission: PermissionForEntityFactory;

  protected subscriptions: Subscription[] = [];

  constructor(
    private readonly dashboardApiService: DashboardApiLegacyService,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly dashboardService: DashboardService,
    private readonly workbookService: WorkbookService,
    private readonly activateRoute: ActivatedRoute,
    private readonly destroy$: DestroyService,
    protected readonly elementRef: ElementRef,
    protected readonly injector: Injector,
    public readonly permissionService: BiPermissionService,
    private readonly recycleBinService: RecycleBinApiToDomainService,
    private broadCaster: EventBroadcaster,
    public readonly workbookActionsService: WorkbookActionsService,
  ) {
    super(elementRef, injector);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.allWidth();
    this.sizeStable();
  }

  ngOnInit() {
    super.ngOnInit();
    this._initViewPage();
    this.getPins();
    this.intervalResize();

    this.subscriptions.push(
      this.broadCaster.on<any>('WORKBOOK_RELOAD_BOARD_LIST').subscribe(() => {
        this.getPins();
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.workspace?.currentValue) {
      this.workspacePermission = this.permissionService.getPermissionsForEntityFactory(
        (changes.workspace.currentValue as Workspace).permissions,
      );
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.intervalCleaner();
  }

  hasPermission(permission: BiEntityPermission): boolean {
    return this.workspacePermission?.hasPermission(permission);
  }

  public confirmDelFunc(dashboard) {
    this.confirmDel = true;
    this.confirmDelete.emit(dashboard);
  }

  public clearWindowUI() {
    window['ui'] ? (window['ui'][0]['widgets'] = []) : '';
  }

  public addPanel(type) {
    switch (type) {
      case 'NEW_ACTION_BUTTONS':
        this.showActionsBtnPanel = !this.showActionsBtnPanel;
        this.showCharts = false;
        break;
    }
  }

  public setBtnPanel() {
    this.showActionsBtnPanel = !this.showActionsBtnPanel;
  }

  public intervalResize() {
    this.intervalResizer = setInterval(() => {
      this.allWidth();
      this.sizeStable();
    }, 2000);
  }

  setGridMenu() {
    this.gridMenuState = !this.gridMenuState;
    this.gridMenu.emit(this.gridMenuState);
  }

  hideGridMenu() {
    this.loadingShow();
    this.gridMenuState = false;
    this.gridMenu.emit(this.gridMenuState);
    this.loadingHide();
  }

  public intervalCleaner() {
    clearInterval(this.intervalResizer);
  }

  public sizeStable() {
    const li = $('.workbook-nav-ul').children().outerWidth(true);
    const liLen = $('.workbook-nav-ul').children().length;
    const ul = $('.workbook-nav-ul').width();

    li * liLen > ul ? (this.arrows = true) : (this.arrows = false);
  }

  public allWidth() {
    const all = $('.workbook-nav-ul').width();

    const result = all % 120;
    const width = $('.workbook-nav-ul').children().outerWidth(true);
    const len = $('.workbook-nav-ul').children().length;
    let res;
    if (result != 0 || width * len > len) {
      res = width * len;
      this.checkAddingPins();
    }
  }

  private _initViewPage() {
    this.username = CommonUtil.getLoginUserId();

    const cookieIsCloseDashboardList = this.cookieService.get(CookieConstant.KEY.WORKBOOK_CLOSE_DASHBOARD_LIST);
    this.toggleFoldDashboardList('true' === cookieIsCloseDashboardList);

    this.workbookActionsService.setUpdateDashboardConfig({ view: false });
  }

  public toggleFoldDashboardList(isClose?: boolean) {
    if ('undefined' === typeof isClose) {
      this.isCloseDashboardList = !this.isCloseDashboardList;
    } else {
      this.isCloseDashboardList = isClose;
    }
    this._boardComp && this._boardComp.toggleFoldWorkbookDashboardList();

    this.cookieService.set(CookieConstant.KEY.WORKBOOK_CLOSE_DASHBOARD_LIST, String(this.isCloseDashboardList), 0, '/');
  }

  routeDashboard(dashboard: Dashboard): void {
    const router = this.injector.get(Router);

    router.navigate([], {
      relativeTo: this.activateRoute,
      queryParams: { dashboardId: dashboard.id },
      queryParamsHandling: 'merge',
    });
  }

  public setDashboard(dashboard: Dashboard) {
    this.dashBoard.emit(dashboard);
  }

  public setDashboardName(dashboardName: string) {
    this.dashboardName.emit(dashboardName);
  }

  public scrollRight(): void {
    this.navigation.nativeElement.scrollTo({
      left: this.navigation.nativeElement.scrollLeft + 360,
      behavior: 'smooth',
    });
  }

  public scrollLeft(): void {
    this.navigation.nativeElement.scrollTo({
      left: this.navigation.nativeElement.scrollLeft - 360,
      behavior: 'smooth',
    });
  }

  public createDashboard() {
    this.isShowCreateDashboard = true;
    this.useUnloadConfirm = true;
    this.safelyDetectChanges();
  }

  public closeCreateDashboard() {
    this.isShowCreateDashboard = false;
    this.useUnloadConfirm = false;
    this.safelyDetectChanges();
  }

  public updateCompleteDashboard(dashboard) {
    this._setSelectedDashboard(dashboard);

    this.dashboards.forEach((board) => {
      if (board.id === dashboard.id) {
        board.imageUrl = dashboard.imageUrl;
      }
    });

    this.workbookActionsService.setUpdateDashboardConfig({ view: false });
  }

  private _setSelectedDashboard(dashboard) {
    this.selectedDashboard = dashboard;
    this.dashboardService.setCurrentDashboard(dashboard);
  }

  public onChangeModeName(event: MouseEvent) {
    if (this.workspacePermission.hasPermission(BiEntityPermission.DASHBOARD_EDIT)) {
      event.stopPropagation();
      this.isDashboardNameEditMode = true;
      this.isDashboardDescEditMode = false;
      this.changeDetect.detectChanges();
    }
  }

  public renameDashboard(dashboard: Dashboard) {
    this.isDashboardNameEditMode = false;

    new Promise((resolve) => {
      resolve(this.updateDashboard(dashboard));
    }).then((res) => {
      setTimeout(() => {
        this.getPins();
      }, 200);
    });
  }

  public updateDashboard(dashboard: Dashboard) {
    this.dashboards.forEach((db) => {
      if (db.id === dashboard.id) {
        if (
          this._dashboardValidation(dashboard) &&
          (db.name !== dashboard.name || db.description !== dashboard.description)
        ) {
          const param = {
            name: dashboard.name,
            description: dashboard.description,
          };

          this.loadingShow();
          this.dashboardApiService
            .updateDashboard(dashboard.id, param)
            .then(() => {
              db.name = dashboard.name;
              db.description = dashboard.description;
              this.alertPrimeService.success(this.translateService.instant('msg.comm.alert.save.success'));
              this.changeDetect.detectChanges();
              this.loadingHide();
            })
            .catch(() => {
              this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.save.fail'));
              this.loadingHide();
            });
        }
        return true;
      }
      return false;
    });
    return true;
  }

  private _dashboardValidation(dashboard: Dashboard) {
    if (StringUtil.isEmpty(dashboard.name)) {
      this.alertPrimeService.warn(this.translateService.instant('msg.alert.edit.name.empty'));
      return false;
    }

    if (CommonUtil.getByte(dashboard.name) > 150) {
      this.alertPrimeService.warn(this.translateService.instant('msg.alert.edit.name.len'));
      return false;
    }

    if (dashboard.description != null && CommonUtil.getByte(dashboard.description) > 450) {
      this.alertPrimeService.warn(this.translateService.instant('msg.alert.edit.description.len'));
      return false;
    }

    return true;
  }

  public changeDesc(inputDesc: string) {
    this.isDashboardDescEditMode = false;
    this.selectedDashboard.description = inputDesc;
    this.updateDashboard(this.selectedDashboard);
  }

  public loadAndSelectDashboard(dashboard: Dashboard) {
    this.tempLoadBoard = dashboard;
    if (this.isInvalidDatasource(dashboard)) {
      if (this._boardComp) {
        this._setSelectedDashboard(undefined);
        this._boardComp.showError(this.translateService.instant('msg.space.ui.dashboard.unauthorized'));
        this._boardComp.hideBoardLoading();
      }
    } else {
      if (!this.selectedDashboard || this.selectedDashboard.id !== dashboard.id) {
        if (this._boardComp) {
          this._boardComp.showBoardLoading();
          this._boardComp.hideError();
        }
        this.dashboardDomainService
          .loadDashboard(dashboard.id)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (board: Dashboard) => {
              board.workBook = this.workbook;
              this._setSelectedDashboard(board);
              this.tempLoadBoard = undefined;

              this._boardComp && this._boardComp.hideBoardLoading();
              this.safelyDetectChanges();
            },
            error: () => {
              if (this._boardComp) {
                this._boardComp.showError();
                this._boardComp.hideBoardLoading();
              }
              this.safelyDetectChanges();
            },
          });
      } else {
        this._boardComp.hideBoardLoading();
        this.safelyDetectChanges();
      }
    }
  }
  public isInvalidDatasource(dashboard: Dashboard): boolean {
    return false;
  }

  public scrollToDashboard(dashboardId: string) {
    const selectedIdx: number = this.dashboardsPins.findIndex((item) => item.id === dashboardId);

    if (selectedIdx !== -1) {
      const navItems = this.navigationItem.toArray();
      const selectedItem = navItems[selectedIdx];
      const navigationContainer = this.navigation.nativeElement;

      if (selectedItem) {
        setTimeout(() => {
          const selectedItemOffsetLeft = selectedItem.nativeElement.offsetLeft;
          navigationContainer.scroll({
            left: selectedItemOffsetLeft,
            behavior: 'smooth',
          });
        });
      }
    }
  }

  public get filteredDashboard(): Dashboard[] {
    if (this.onlyShowingFlag) {
      return this.dashboards.filter((ds) => {
        return ds.hiding === true || ds.hiding === undefined;
      });
    }

    return this.dashboards;
  }

  public detailDashboard(dashboard: Dashboard) {
    this.loadAndSelectDashboard(dashboard);
  }

  public changeFavorite(dashboard: Dashboard, event) {
    event.stopImmediatePropagation();

    const params = {
      hiding: !dashboard.hiding,
    };

    this.dashboardApiService.updateDashboard(dashboard.id, params).then((ds) => {
      dashboard.hiding = ds.hiding;
    });
  }
  public changeOrder() {
    this.loadingShow();

    const params = [];
    this.dashboards.forEach((item, idx) => {
      params.push({
        op: 'REPLACE',
        hiding: item.hiding,
        id: item.id,
        seq: idx,
      });
    });

    this.workbookService
      .setDashboardSort(this.workbookId, params)
      .then(() => {
        this.alertPrimeService.success(this.translateService.instant('msg.board.alert.dashboard.sort.success'));

        this.loadingHide();
      })
      .catch(() => {
        this.alertPrimeService.error(this.translateService.instant('msg.board.alert.dashboard.sort.fail'));

        this.loadingHide();
      });
  }

  public addWidget(type: string) {
    switch (type) {
      case 'NEW_CHART':
        this.workbookActionsService.setUpdateDashboardConfig({ view: true, startupCmd: { cmd: 'NEW', type: 'CHART' } });
        break;
      case 'NEW_TEXT':
        this.workbookActionsService.setUpdateDashboardConfig({ view: true, startupCmd: { cmd: 'NEW', type: 'TEXT' } });
        break;
      case 'NEW_FILTER':
        this.workbookActionsService.setUpdateDashboardConfig({
          view: true,
          startupCmd: { cmd: 'NEW', type: 'FILTER' },
        });
        break;
      case 'NEW_ACTION_BUTTONS':
        this.workbookActionsService.setUpdateDashboardConfig({
          view: true,
          startupCmd: { cmd: 'NEW', type: 'NEW_ACTION_BUTTONS' },
        });
        break;
    }
  }

  async animationTimer() {
    if (this.asyncAnimation) {
      this.asyncAnimation = false;
    } else {
      setTimeout(() => {
        this.asyncAnimation = true;
      }, 1000);
    }
  }

  deleteDashboard(getDashboard: Dashboard) {
    const dashboard: Dashboard = getDashboard;

    this.deleteDashboardId(dashboard.id);
  }

  deleteDashboardId(id) {
    this.loadingShow();
    this.recycleBinService
      .recycleBinDashboard(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.alertPrimeService.success(this.translateService.instant('msg.board.alert.dashboard.del.success'));

        if (this.selectedDashboard.id === id) {
          this._setSelectedDashboard(null);
        }

        this._getWorkbook().then(() => {
          this.selectedDatasources = this.datasources.map((ds) => ds.id);
          this.getPins();
          this.loadingHide();
          this.loadDashboardList(0);
        });
      });
  }

  private _getWorkbook(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.workbookService
        .getWorkbook(this.workbookId)
        .then((result) => {
          this.datasources = result.dataSource.map((ds) => {
            return { id: ds.id, name: ds.name, valid: ds.valid };
          });

          if (this.selectedDatasources === undefined) {
            this.selectedDatasources = this.datasources.map((ds) => ds.id);
          }

          this.workbook = result;

          resolve(result);
        })
        .catch((err) => reject(err));
    });
  }

  public gotoWorkspace() {
    const cookieWs = this.cookieService.get(CookieConstant.KEY.CURRENT_WORKSPACE);
    const cookieWorkspace = cookieWs ? JSON.parse(cookieWs) : null;
    if (null !== cookieWorkspace && null !== cookieWorkspace['workspaceId']) {
      this.router.navigate([BI_ROOT_ROUTE, 'workspace', cookieWorkspace['workspaceId']]).then();
    } else {
      this.router.navigate([BI_ROOT_ROUTE, 'workspace']).then();
    }
  }

  public loadDashboardList(page: number = 0, targetBoardId?: string) {
    this.loadingShow();

    if (!this.workspace) return;

    const params = {
      includeHidden: this.workspacePermission.hasPermission(BiEntityPermission.WORKBOOK_EDIT),
      datasources: this.selectedDatasources.join(','),
    };
    if ('' !== this.dashboardSrchText.trim()) {
      params['nameContains'] = this.dashboardSrchText.trim();
    }

    const pageInfo: Page = new Page();
    pageInfo.page = page;

    if (0 === page) {
      this.dashboardPage = new PageResult();
      this.dashboards = [];
      this.isShowMoreDashboardList = false;
    }

    this.workbookService
      .getDashboards(this.workbook.id, { key: 'seq', type: 'asc' }, pageInfo, 'forListView', params)
      .then((result) => {
        let tempList = [];
        if (result['_embedded'] && result['_embedded']['dashboards']) {
          tempList = result['_embedded']['dashboards'];
          tempList.forEach((ds) => {
            if (ds.hiding === undefined) ds.hiding = false;
          });

          if (!this.workspacePermission?.hasPermission(BiEntityPermission.DASHBOARD_VIEW)) {
            tempList = tempList.filter((item) => !item.hiding);
          }
        }

        if (result.page) {
          const objPage: PageResult = result.page;
          this.dashboardPage = objPage;
          if (0 === objPage.number) {
            this.dashboards = tempList;
          } else {
            this.dashboards = this.dashboards.concat(tempList);
          }
          this.isShowMoreDashboardList = 0 < objPage.totalPages && objPage.number !== objPage.totalPages - 1;
        }

        this.loadingHide();

        if (0 < this.dashboards.length) {
          if (targetBoardId) {
            const selectedBoard: Dashboard = this.dashboards.find((item) => item.id === targetBoardId);
            if (selectedBoard) {
              this.loadAndSelectDashboard(selectedBoard);
            } else {
              if (this.dashboardPage.totalPages > this.dashboardPage.number + 1) {
                this.loadDashboardList(this.dashboardPage.number + 1, targetBoardId);
              }
            }
          } else {
            if (this.dashboardId !== undefined) {
              const index = this.dashboards.findIndex((dashboard) => {
                return dashboard.id === this.dashboardId;
              });
              this.loadAndSelectDashboard(this.dashboards[index < 0 ? 0 : index]);
              this.getPins();

              this.router.navigate([], {
                relativeTo: this.activateRoute,
                queryParams: { dashboardId: this.dashboards[0]?.id },
                queryParamsHandling: 'merge',
              });
            } else {
              this.loadAndSelectDashboard(this.dashboards[0]);
            }
          }
        } else {
          this._setSelectedDashboard(null);
        }

        this.safelyDetectChanges();
      })
      .catch((err) => {
        this.alertPrimeService.error(this.translateService.instant('msg.comm.alert.del.fail'));
        console.log(err);
        this.loadingHide();
      });
  }

  public copyDashboard(dashboard: Dashboard) {
    this.loadingShow();
    this.dashboardApiService
      .copyDashboard(dashboard.id)
      .then((copyBoard: Dashboard) => {
        this.alertPrimeService.success(
          "'" + dashboard.name + "' " + this.translateService.instant('msg.board.alert.dashboard.copy.success'),
        );
        this.loadingHide();
        this.loadDashboardList(0, copyBoard.id);
        this.addInPins(copyBoard);
      })
      .catch(() => this.loadingHide());
  }

  public removeItem(item: any, list: any[]): void {
    list.splice(list.indexOf(item), 1);
  }

  drop(event: CdkDragDrop<string[]>) {
    const drag = event.previousContainer.element.nativeElement.children[event.previousIndex].id;
    const drop = event.previousContainer.element.nativeElement.children[event.currentIndex].id;
    let params;
    this.workbookService.changePlacePinsWorkbookID(this.workbookId, drag, drop, params).then(() => {
      this.getPins();
    });
    moveItemInArray(this.dashboardsPins, event.previousIndex, event.currentIndex);
  }

  addInPins(dashboard: any) {
    const params = {};
    this.workbookService.addPinsWorkbook(this.workbookId, dashboard.id, params).then((res) => {
      this.getPins();
    });
  }

  allPins(dashboards: any) {
    this.loadingShow();
    const data = [];
    this.dashboards.forEach((item) => {
      data.push(item.id);
    });
    this.workbookService.addAllPins(this.workbookId, data).then(() => {
      this.getPins().then(() => {
        this.checkAddingPins();
        this.allWidth();
      });
    });
    this.loadingHide();
  }

  deleteAllPins(dashboards) {
    this.loadingShow();
    this.workbookService
      .deleteAllPins(this.workbookId)
      .then(() => {
        return this.getPins();
      })
      .then(() => {
        this.dashboards.forEach((item, index) => {
          this.dashboards[index].pins = false;
        });
      })
      .then(() => {
        this.allWidth();
        this.checkAddingPins();
      });
    this.loadingHide();
  }

  public getPins() {
    this.workbookId = this.activateRoute.snapshot.params['workbookId'];
    return this.workbookService.getPinsWorkbook(this.workbookId).then((res) => {
      this.dashboardsPins = res.dashBoard;
      this.checkAddingPins();
      this.allWidth();

      return this.setDashboardWithPins();
    });
  }

  setDashboardWithPins(): void {
    const dashboardId = this.getDashboardIdFromRouter();

    if (!dashboardId) return;

    this.findDashboardInPinnedOrFetch(dashboardId)
      .pipe(
        switchMap((dashboard) => {
          const selectedDashboard = this.dashboardAddDatasources(
            cloneDeep(dashboard || this.getFirstPinnedDashboard()),
          );
          this._setSelectedDashboard(selectedDashboard);
          this.setDashboard(selectedDashboard);
          return of(this.dashboardsPins);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private findDashboardInPinnedOrFetch(dashboardId: string): Observable<Dashboard> {
    const dashboard = this.dashboardsPins.find(({ id }) => dashboardId === id);
    if (dashboard) return of(dashboard);

    return this.dashboardDomainService
      .loadDashboard(dashboardId)
      .pipe(takeUntil(this.destroy$))
      .pipe(
        catchError((e) => {
          console.warn('Cannot find dashboard with id:', dashboardId);
          return of(null);
        }),
        switchMap((fetchedDashboard) => {
          if (fetchedDashboard) {
            this.dashboardsPins.push(fetchedDashboard);
          }
          return of(fetchedDashboard || this.dashboardsPins[0]);
        }),
        takeUntil(this.destroy$),
      );
  }

  deletePins(dashboardId: any) {
    this.workbookService
      .deletePinsWorkbookID(this.workbookId, dashboardId)
      .then(async () => {
        return this.getPins();
      })
      .then(() => {
        this.setFalsePins(dashboardId);
      });
  }

  setFalsePins(dashboardId) {
    this.dashboards.forEach((item, index) => {
      if (dashboardId == item.id) {
        this.dashboards[index].pins = false;
      }
    });
  }

  checkAddingPins() {
    for (let i = 0; i < this.dashboards.length; i++) {
      for (let j = 0; j < this.dashboardsPins.length; j++) {
        if (this.dashboards[i].id == this.dashboardsPins[j].id) {
          this.dashboards[i].pins = true;
        } else {
          if (this.dashboards[i].pins == undefined) {
            this.dashboards[i].pins = false;
          }
        }
      }
      if (!this.dashboards[i].pins) {
        this.dashboards[i].pins = false;
      }
    }
  }

  checkId(id: string) {
    let result: any;
    this.dashboards.forEach((item) => {
      if (item.id === id) {
        result = item;
      }
    });
    return result?.id === id && result.pins;
  }

  public setViews() {
    this.trumbView = !this.trumbView;
  }

  public runPipeline() {
    window['interfaceStart'].runner();
  }

  public stopedPipeline() {
    window['interfaceStart'].setStop();
  }

  public getStatus() {
    window['interfaceStart'].getter();
  }

  public syncPipeline() {
    window['interfaceStart'].synchronize();
  }

  public onSubmit() {
    if (this.gettingStopedBtn == false) {
      this.runPipeline();
    } else {
      this.stopedPipeline();
    }
  }

  public confirmModalFunc(event) {
    this.confirmModal = !this.confirmModal;
    this.modalSetProfile.emit(event);
  }

  private dashboardAddDatasources(selectedDashboard) {
    const oldParams = cloneDeep(this.selectedDashboard);
    const dashBoard = {
      ...oldParams,
      ...selectedDashboard,
      dataSources: !selectedDashboard?.dataSources[0]?.name ? oldParams?.dataSources : selectedDashboard?.dataSources,
      configuration: {
        ...oldParams?.configuration,
        ...selectedDashboard?.configuration,
      },
    };
    return dashBoard;
  }

  private getFirstPinnedDashboard() {
    const [firstPinDashboard] = this.dashboardsPins;
    return firstPinDashboard;
  }

  private findDashboardInPinned(dashboardId) {
    return this.dashboardsPins.find(({ id }) => dashboardId === id);
  }

  private getDashboardIdFromRouter() {
    return this.activateRoute.snapshot.queryParams['dashboardId'] || this.dashboardsPins[0]?.id;
  }
}
