import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true
})

export class FileSizePipe implements PipeTransform {
  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(bytes: number = 0, precision: number = 2): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

    let unit = 0;
    let bytesArg = bytes;
    let precisionArg = precision;
    while (bytesArg >= 1024) {
      bytesArg /= 1024;
      unit += 1;
    }

    if (unit === 0) precisionArg = 0;
    return bytesArg.toFixed(+precisionArg) + ' ' + this.units[unit];
  }
}
