import { Injectable } from '@angular/core';

import { BehaviorSubject } from "rxjs";
import { DashboardConfig } from "../models/dashboard-config.model";

@Injectable({
  providedIn: 'root'
})
export class WorkbookActionsService {

  private activeMode$ = new BehaviorSubject<boolean>(false);
  private updateDashboardConfig$ = new BehaviorSubject<DashboardConfig>({
    view: false,
    startupCmd: { cmd: 'NONE' }
  });

  isActiveMode$ = this.activeMode$.asObservable();
  getUpdateDashboardConfig$ = this.updateDashboardConfig$.asObservable();


  public setActiveMode(value: boolean) {
    this.activeMode$.next(value);
  }

  public setUpdateDashboardConfig(dashboardConfig: DashboardConfig) {
    if (!dashboardConfig.startupCmd) dashboardConfig.startupCmd = { cmd: 'NONE' };

    this.updateDashboardConfig$.next(dashboardConfig);
  }
}
