import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { PageIsReadyService } from './page-is-ready.service';

@Injectable()
export class PageIsReadyInterceptor implements HttpInterceptor {
  constructor(private readonly pageIsReadyService: PageIsReadyService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isCountedRequest(req.url)) {
      this.pageIsReadyService.increaseCounterRequests();

      return next.handle(req).pipe(
        finalize(() => {
          if (this.isCountedRequest(req.url)) {
            this.pageIsReadyService.dicreaseCounterRequests();
          }
        }),
      );
    }

    return next.handle(req);
  }

  private isCountedRequest(url: string): boolean {
    return url.includes(window.location.origin) || url.startsWith('/');
  }
}
