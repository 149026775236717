import { NgModule } from '@angular/core';
import { provideEntityData, withEffects } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { biChartEngineEntityConfig } from '@selfai-platform/bi-chart-engine';
import {
  biDomainDashboardEntityConfig,
  biDomainFiltersEntityConfig,
  biDomainWidgetEntityConfig,
  biDomainWorkbookEntityConfig,
} from '@selfai-platform/bi-domain';

@NgModule({
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    // StoreDevtoolsModule must be imported after StoreModule.forRoot else it will not work
    StoreDevtoolsModule.instrument({
      logOnly: false,
    }),
  ],
  providers: [
    // all entity metadata must be registered in one provideEntityData
    provideEntityData(
      {
        entityMetadata: {
          ...biChartEngineEntityConfig.entityMetadata,
          ...biDomainWidgetEntityConfig.entityMetadata,
          ...biDomainDashboardEntityConfig.entityMetadata,
          ...biDomainFiltersEntityConfig.entityMetadata,
          ...biDomainWorkbookEntityConfig.entityMetadata,
        },
        pluralNames: { ...biChartEngineEntityConfig.pluralNames, ...biDomainFiltersEntityConfig.pluralNames },
      },
      withEffects(),
    ),
  ],
})
export class SelfaiStoreModule {}
