import { Injector } from '@angular/core';
import { ConfigService, PermissionService, SelfaiAuthService, resolveIdentityProvider } from '@selfai-platform/shared';

export function onAppInitModule(
  configService: ConfigService,
  permissionService: PermissionService,
  injector: Injector,
  identityProviderImplentationService: SelfaiAuthService,
) {
  return async () => {
    await configService.init();
    await resolveIdentityProvider(configService, permissionService, injector, identityProviderImplentationService);
  };
}
