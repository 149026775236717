<app-workbook-action-btn *ngIf="showActionsBtnPanel"></app-workbook-action-btn>

<app-create-board
  *ngIf="isShowCreateDashboard"
  [workbookId]="workbook.id"
  [workbookName]="workbook.name"
  [workspaceId]="workbook.workspaceId"
  (close)="closeCreateDashboard()"
></app-create-board>

<ng-container *ngIf="workbookActionsService.getUpdateDashboardConfig$ | async as updateDashboardConfig">
  <selfai-bi-update-dashboard
    *ngIf="updateDashboardConfig.view"
    [startupCmd]="updateDashboardConfig.startupCmd"
    [workbook]="workbook"
    [dashboards]="dashboards"
    (updateComplete)="updateCompleteDashboard($event)"
    (createDashboard)="createDashboard()"
    (selectedDashboard)="loadAndSelectDashboard($event)"
  ></selfai-bi-update-dashboard>
</ng-container>

