import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';

import $ from 'jquery';
import * as _ from 'lodash';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { fromEvent } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import {
  AggregationType,
  BarMarkType,
  ByTimeUnit,
  ChartType,
  DIRECTION,
  Dashboard,
  DashboardDomainService,
  DatasourceField,
  EventType,
  DatasourceField as Field,
  FieldPivot,
  FieldRole,
  Filter,
  GranularityType,
  LogicalType,
  MeasureField,
  PageWidget,
  PageWidgetConfiguration,
  Pivot,
  PivotField,
  SeriesType,
  Shelf,
  ShelveFieldType,
  ShelveType,
  TimeUnit,
  UIChartAxis,
  UIChartColorByValue,
  UIFormatCurrencyType,
  UIFormatNumericAliasType,
  UIFormatType,
  UIOption,
  WIDGET_CONTEXT_ID,
  createDimensionField,
  createFormat,
  createMeasureField,
  createPivot,
  createTimestampField,
  getFieldName,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { DataSettingsService } from '@selfai-platform/bi-chart-engine';
import cloneDeep from 'lodash/fp/cloneDeep';
import { AbstractComponent } from '../../common/component/abstract.component';
import { Modal } from '../../common/domain/modal';
import { StringUtil } from '../../common/util/string.util';

@Component({
  selector: 'page-pivot',
  templateUrl: './page-pivot.component.html',
  providers: [DestroyService],
})
export class PagePivotComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild('editFieldLayer', {})
  protected editFieldLayerDirective: NgClickOutsideDirective;

  @Input()
  public uiOption: UIOption;

  @Input()
  public widget: PageWidget;

  @Input('widgetConfig')
  set setWidgetConfig(widgetConfig: PageWidgetConfiguration) {
    this.widgetConfig = widgetConfig;

    this.filterFiledList = [];
    if (widgetConfig && widgetConfig.filters) {
      for (const filter of widgetConfig.filters) {
        this.filterFiledList.push(filter['field']);
      }
    }
    if (this.globalFilters) {
      for (const filter of this.globalFilters) {
        this.filterFiledList.push(filter.field);
      }
    }
  }

  @Input()
  public shelf: Shelf;

  @Input('pivot')
  set setPivot(pivot: Pivot) {
    if (!pivot) {
      this.pivot = createPivot();
    } else {
      this.pivot = pivot;
    }

    this.changePivot();
  }

  @Input('chartType')
  set setChartType(chartType: string) {
    const initChartType = this.chartType;

    this.chartType = chartType;

    if (this.chartType) {
      this.initUnavailableShelf();

      if (initChartType && this.$element) this.onShelveAnimation(this.$element.find('.ddp-wrap-default'));
    }
  }

  @Input('globalFilters')
  set setGlobalFilters(filters: Filter[]) {
    if (filters) {
      this.globalFilters = filters;
      for (const filter of filters) {
        this.filterFiledList.push(filter.field);
      }
    }
  }

  @Output()
  public showPopup: EventEmitter<Modal> = new EventEmitter();

  @Output()
  public deletePivotItem: EventEmitter<Object> = new EventEmitter();

  @Output()
  public changePivotItem: EventEmitter<Object> = new EventEmitter();

  @Output('changePivot')
  public changePivotEvent: EventEmitter<any> = new EventEmitter();

  @Output('changePivotFilter')
  public changePivotFilterEvent: EventEmitter<any> = new EventEmitter();

  @Output('toggleFilter')
  public toggleFilterEvent: EventEmitter<any> = new EventEmitter();

  @Output('customField')
  public customFieldEvent: EventEmitter<any> = new EventEmitter();

  @Output('changeFormat')
  public changeFormatEvent: EventEmitter<any> = new EventEmitter();

  public widgetConfig: PageWidgetConfiguration;

  public filterFiledList: string[] = [];

  public editingField: PivotField;

  public dragField: PivotField;

  public pivot: Pivot;

  public chartType: string;

  public unavailableShelfs: string[];

  public editingFieldAlias: string;

  public fix2DepthContext = false;

  protected timestampTypeList: any[];

  public combineAggIndex: number;

  public globalFilters: Filter[] = [];

  public animationPause: boolean;

  public finishAnimation: boolean;

  private $editFieldLayer: JQuery;

  protected aggregationType = AggregationType;

  public aggTypeList: any[];

  dashboard: Dashboard;

  private destroy$: DestroyService;
  private readonly dashboardDomainService: DashboardDomainService;
  private readonly contextId: string = inject(WIDGET_CONTEXT_ID);

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);

    this.destroy$ = injector.get(DestroyService);
    this.dashboardDomainService = injector.get(DashboardDomainService);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.init();

    const resizeEvent$ = fromEvent(window, 'resize').pipe(
      map(() => document.documentElement.clientWidth + 'x' + document.documentElement.clientHeight),
      debounceTime(500),
    );

    const windowResizeSubscribe = resizeEvent$.subscribe((data) => {
      this.onShelveAnimation(this.$element.find('.ddp-wrap-default'));
      //TODO resize window scroll check
    });

    this.subscriptions.push(windowResizeSubscribe);

    setTimeout(() => {
      this.finishAnimation = true;

      if (this.$element) this.onShelveAnimation(this.$element.find('.ddp-wrap-default'));
    }, 700);

    this.dashboardDomainService.loadDashboard(this.widget.dashBoardId).pipe(takeUntil(this.destroy$)).subscribe();

    this.dashboardDomainService
      .getDashboard(this.widget.dashBoardId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((dashboard) => {
        this.dashboard = dashboard;
      });
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public changePivot(eventType?: EventType) {
    this.pivot.columns = this.pivot.columns.map(this.mapJoinToRef);
    this.pivot.rows = this.pivot.rows.map(this.mapJoinToRef);
    this.pivot.aggregations = this.pivot.aggregations.map(this.mapJoinToRef);

    if (this.widget?.id) {
      // TODO: need to refactor. changePivot uses in setter setPivot and we can't be sure that this.widget is already defined
      this.injector
        .get(DataSettingsService)
        .setSettings(this.widget.id, { pivot: cloneDeep(this.pivot) }, this.contextId);
    }

    this.changePivotEvent.emit({ pivot: this.pivot, eventType: eventType });
  }

  public onChangePivotPosition(chartType: string) {
    const type: ChartType = ChartType[String(chartType.toUpperCase())] as ChartType;
    switch (type) {
      case ChartType.BAR:
      case ChartType.GRID:
      case ChartType.LINE:
      case ChartType.WATERFALL:
      case ChartType.COMBINE:
      case ChartType.SANKEY:
      case ChartType.CONTROL:
        if (ChartType.BAR == type) {
          this.changeBarStackPivot();
        }

        this.autoPivoting(FieldPivot.COLUMNS, FieldPivot.AGGREGATIONS);
        break;
      case ChartType.HEATMAP:
        this.autoPivoting(FieldPivot.COLUMNS, FieldPivot.AGGREGATIONS);

        this.moveShelfItemByIndex([String(ShelveFieldType.MEASURE), String(ShelveFieldType.CALCULATED)], 0);
        break;
      case ChartType.PIE:
      case ChartType.RADAR:
      case ChartType.WORDCLOUD:
      case ChartType.DPIE:
      case ChartType.DDONAT:
        this.autoPivoting(FieldPivot.AGGREGATIONS, FieldPivot.AGGREGATIONS);
        break;

      case ChartType.LABEL:
        this.updatePivotList(this.pivot.columns, FieldPivot.COLUMNS, [
          String(ShelveFieldType.DIMENSION),
          String(ShelveFieldType.TIMESTAMP),
        ]);

        this.updatePivotList(this.pivot.rows, FieldPivot.ROWS, [
          String(ShelveFieldType.DIMENSION),
          String(ShelveFieldType.TIMESTAMP),
        ]);

        this.updatePivotList(this.pivot.aggregations, FieldPivot.AGGREGATIONS, [
          String(ShelveFieldType.DIMENSION),
          String(ShelveFieldType.TIMESTAMP),
        ]);

        this.autoPivoting(null, FieldPivot.AGGREGATIONS);
        break;

      case ChartType.GAUGE:
        this.autoPivoting(FieldPivot.ROWS, FieldPivot.AGGREGATIONS);
        break;

      case ChartType.SCATTER:
        this.autoPivoting(FieldPivot.AGGREGATIONS);

        this.moveShelfItemByIndex([String(ShelveFieldType.MEASURE), String(ShelveFieldType.CALCULATED)], 1, [0], [1]);
        break;

      case ChartType.BOXPLOT:
        this.moveShelfItemByIndex(
          [String(ShelveFieldType.DIMENSION), String(ShelveFieldType.TIMESTAMP)],
          null,
          [0],
          [1],
          2,
        );

        this.autoPivoting(null, FieldPivot.AGGREGATIONS);
        break;
      case ChartType.TREEMAP:
        this.moveShelfItemByIndex(
          [String(ShelveFieldType.DIMENSION), String(ShelveFieldType.TIMESTAMP)],
          null,
          [0],
          [],
          null,
          1,
        );

        this.autoPivoting(null, FieldPivot.AGGREGATIONS);
        break;
      case ChartType.GRAPH:
      case ChartType.GRAPHV2:
        this.moveShelfItemByIndex(
          [String(ShelveFieldType.DIMENSION), String(ShelveFieldType.TIMESTAMP)],
          null,
          [0],
          [1],
        );

        this.autoPivoting(null, FieldPivot.AGGREGATIONS);
        break;
    }

    this.getUniqTimestampShelve();
  }

  public changePivotFilter(): void {
    this.pivot.columns = this.pivot.columns.map(this.mapJoinToRef);
    this.pivot.rows = this.pivot.rows.map(this.mapJoinToRef);
    this.pivot.aggregations = this.pivot.aggregations.map(this.mapJoinToRef);

    this.changePivotFilterEvent.emit(this.pivot);
  }

  public addField(targetField: Field, targetContainer: string, pivotField: PivotField) {
    let shelf;

    if (targetContainer === 'column') {
      shelf = this.pivot.columns;
    } else if (targetContainer === 'row') {
      shelf = this.pivot.rows;
    } else if (targetContainer === 'aggregation') {
      shelf = this.pivot.aggregations;
    } else {
      return;
    }

    if (targetField) {
      shelf.push(targetField);
      this.convertField(targetField, targetContainer);
    }
  }

  public removeField(event: any, fieldPivot: FieldPivot, shelf, idx: number) {
    const field: PivotField = shelf.splice(idx, 1)[0];

    field.field?.pivot.splice(field.field.pivot.indexOf(fieldPivot), 1);

    if (event) {
      const target = $(event.currentTarget.parentElement.parentElement.parentElement.parentElement);

      this.onShelveAnimation(target);
    }

    this.changePivot(EventType.CHANGE_PIVOT);
  }

  public convertField(targetField: Field, targetContainer: string, addPivotFl: boolean = true) {
    let shelf;
    let shelfElement: JQuery;

    if (targetContainer === 'column') {
      shelf = this.pivot.columns;
      shelfElement = this.$element.find('#shelfColumn');
    } else if (targetContainer === 'row') {
      shelf = this.pivot.rows;
      shelfElement = this.$element.find('#shelfRow');
    } else if (targetContainer === 'aggregation') {
      shelf = this.pivot.aggregations;
      shelfElement = this.$element.find('#shelfAggregation');
    } else {
      return;
    }

    const idx = shelf.findIndex((field) => {
      return field.name === targetField.name && targetField.role === field.role;
    });

    if (idx > -1) {
      let field;

      if (
        targetField.role === FieldRole.TIMESTAMP ||
        (targetField.role === FieldRole.DIMENSION && targetField.logicalType === LogicalType.TIMESTAMP)
      ) {
        const timeField = createTimestampField();

        field = timeField;
      } else if (targetField.role === FieldRole.DIMENSION) {
        field = createDimensionField();
      } else if (targetField.role === FieldRole.MEASURE) {
        field = createMeasureField();
        field.aggregated = targetField.aggregated;
      }
      field.name = targetField.name;
      field.subType = targetField.type;
      field.subRole = targetField.role;

      field.expr = targetField.expr;
      field.field = targetField;

      if (
        targetField.name !== targetField.alias &&
        (!targetField.nameAlias || targetField.nameAlias.nameAlias !== targetField.alias)
      ) {
        field.alias = targetField.alias;
      }
      targetField.nameAlias && (field.fieldAlias = targetField.nameAlias.nameAlias);
      field.granularity = targetField.granularity;
      field.segGranularity = targetField.segGranularity;
      if (!_.isUndefined(targetField.ref)) {
        field.ref = targetField.ref;
      } else if (targetField.type == 'user_expr') {
        field.ref = 'user_defined';
      }

      this.dragField = field;

      shelf[idx] = field;

      const shelves = this.pivot.rows.concat(this.pivot.columns.concat(this.pivot.aggregations));

      if (!this.distinctPivotItems(shelves, field, idx, shelf, targetContainer)) {
        targetField.format = shelf[idx].format;

        targetField.pivot = targetField.pivot ? targetField.pivot : [];
        if (targetContainer === 'column') {
          if (addPivotFl) targetField.pivot.push(FieldPivot.COLUMNS);
          field.currentPivot = FieldPivot.COLUMNS;
        } else if (targetContainer === 'row') {
          if (addPivotFl) targetField.pivot.push(FieldPivot.ROWS);
          field.currentPivot = FieldPivot.ROWS;
        } else if (targetContainer === 'aggregation') {
          if (addPivotFl) targetField.pivot.push(FieldPivot.AGGREGATIONS);
          field.currentPivot = FieldPivot.AGGREGATIONS;
        }

        if ((field as MeasureField)?.type === ShelveFieldType.MEASURE) {
          field.format = {
            type:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.type)
                ? String(UIFormatType.NUMBER)
                : this.uiOption.valueFormat.type,
            sign:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.sign)
                ? String(UIFormatCurrencyType.KRW)
                : this.uiOption.valueFormat.sign,
            decimal:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.decimal)
                ? 2
                : this.uiOption.valueFormat.decimal,
            useThousandsSep:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.useThousandsSep)
                ? true
                : this.uiOption.valueFormat.useThousandsSep,
            abbr:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.abbr)
                ? String(UIFormatNumericAliasType.NONE)
                : this.uiOption.valueFormat.abbr,
            customSymbol:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.customSymbol)
                ? null
                : this.uiOption.valueFormat.customSymbol,
          };
        }
      }

      this.chartShelveLimit(this.chartType, targetContainer, shelf);

      this.changePivot(EventType.CHANGE_PIVOT);

      if (shelfElement) this.onShelveAnimation(shelfElement.find('.ddp-wrap-default'));
    }

    this.editFieldLayerDirective.exclude = '.ddp-icon-layer';
  }

  public changeFieldPivot(targetField: Field, targetContainer: string, pivotField: PivotField): void {
    targetField.pivot = targetField.pivot ? targetField.pivot : [];

    let shelf;
    let pivot: FieldPivot;

    let shelfElement: JQuery;

    if (targetContainer === 'column') {
      pivot = FieldPivot.COLUMNS;
      shelf = this.pivot.columns;
      shelfElement = this.$element.find('#shelfColumn');
    } else if (targetContainer === 'row') {
      pivot = FieldPivot.ROWS;
      shelf = this.pivot.rows;
      shelfElement = this.$element.find('#shelfRow');
    } else if (targetContainer === 'aggregation') {
      pivot = FieldPivot.AGGREGATIONS;
      shelf = this.pivot.aggregations;
      shelfElement = this.$element.find('#shelfAggregation');
    }

    if (pivotField.currentPivot !== pivot) {
      if (
        targetField.role === FieldRole.TIMESTAMP ||
        (targetField.role === FieldRole.DIMENSION && targetField.logicalType === LogicalType.TIMESTAMP)
      ) {
        const timestampIndex = shelf.findIndex((field) => {
          return (
            field.name === targetField.name &&
            targetField.role === field.field.role &&
            targetField.granularity === field.granularity &&
            -1 !== targetField.format['type'].indexOf('time')
          );
        });

        if (-1 !== timestampIndex) {
          let duplicateFieldFl = false;
          if ('grid' !== this.chartType) {
            duplicateFieldFl = this.deleteDuplicatedField(targetField, timestampIndex, targetContainer);
          }

          if (!duplicateFieldFl) {
            targetField.pivot.splice(targetField.pivot.indexOf(pivotField.currentPivot), 1);

            targetField.pivot.push(pivot);

            pivotField.currentPivot = pivot;
          }
        }
      } else {
        targetField.pivot.splice(targetField.pivot.indexOf(pivotField.currentPivot), 1);

        targetField.pivot.push(pivot);

        pivotField.currentPivot = pivot;
      }
    }

    this.chartShelveLimit(this.chartType, targetContainer, shelf);

    this.changePivot(EventType.CHANGE_PIVOT);

    if (shelfElement) this.onShelveAnimation(shelfElement.find('.ddp-wrap-default'));
  }

  public onChangeShowValue(showValue: boolean): void {
    this.editingField.showValue = showValue;

    this.changePivotFilter();
  }

  public onChangeFilter($event): void {
    if (this.editingField.field.filtering) {
      $event.target ? ($event.target.checked = true) : ($event.currentTarget.checked = true);
      this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.recomm-filter.del.error'));
      return;
    }

    this.toggleFilterEvent.emit(this.editingField);
  }

  public unescapeCustomColumnExpr(expr: string) {
    return StringUtil.unescapeCustomColumnExpr(expr);
  }

  public onChangeFormat(formatType: string): void {
    if (formatType != '') {
      const field: PivotField = _.cloneDeep(this.editingField);
      if (!field.format) {
        field.format = {};
      }
      field.format.type = formatType;
      this.changeFormatEvent.emit(field);
    } else {
      this.fix2DepthContext = false;
      this.editingField = null;
      this.changeFormatEvent.emit(null);
    }
  }

  public combineChangeSeriesConvertType(seriesType: SeriesType) {
    this.combineAggIndex = this.pivot.aggregations.indexOf(this.editingField);

    this.setEditingFieldOptions('series.type=', seriesType);

    this.changePivot();
  }

  public isGuide(type: string): boolean {
    if (this.chartType == '') {
      return false;
    }

    if (_.eq(type, ShelveType.COLUMNS)) {
      if (
        _.eq(this.chartType, ChartType.BAR) ||
        _.eq(this.chartType, ChartType.LINE) ||
        _.eq(this.chartType, ChartType.HEATMAP) ||
        _.eq(this.chartType, ChartType.BOXPLOT) ||
        _.eq(this.chartType, ChartType.COMBINE) ||
        _.eq(this.chartType, ChartType.SANKEY) ||
        _.eq(this.chartType, ChartType.GRID)
      ) {
        return true;
      } else if (_.eq(this.chartType, ChartType.SCATTER)) {
        let count = 0;
        for (const field of this.pivot.columns) {
          if (_.eq(field.type, ShelveFieldType.MEASURE) || _.eq(field.type, ShelveFieldType.CALCULATED)) {
            count++;
          }
        }
        return count != 1;
      } else if (_.eq(this.chartType, ChartType.CONTROL) || _.eq(this.chartType, ChartType.WATERFALL)) {
        let count = 0;
        for (const field of this.pivot.columns) {
          if (_.eq(field.type, ShelveFieldType.TIMESTAMP)) {
            count++;
          }
        }
        return count != 1;
      } else if (
        _.eq(this.chartType, ChartType.GRAPH) ||
        _.eq(this.chartType, ChartType.GRAPHV2) ||
        _.eq(this.chartType, ChartType.TREEMAP)
      ) {
        let count = 0;
        for (const field of this.pivot.columns) {
          if (_.eq(field.type, ShelveFieldType.DIMENSION) || _.eq(field.type, ShelveFieldType.TIMESTAMP)) {
            count++;
          }
        }
        return count != 1;
      }
    }

    if (_.eq(type, ShelveType.ROWS)) {
      if (
        _.eq(this.chartType, ChartType.HEATMAP) ||
        _.eq(this.chartType, ChartType.GRID) ||
        _.eq(this.chartType, ChartType.TREEMAP) ||
        _.eq(this.chartType, ChartType.GAUGE)
      ) {
        return true;
      } else if (_.eq(this.chartType, ChartType.SCATTER)) {
        let count = 0;
        for (const field of this.pivot.rows) {
          if (_.eq(field.type, ShelveFieldType.MEASURE) || _.eq(field.type, ShelveFieldType.CALCULATED)) {
            count++;
          }
        }
        return count != 1;
      } else if (
        _.eq(this.chartType, ChartType.GRAPH) ||
        _.eq(this.chartType, ChartType.GRAPHV2) ||
        _.eq(this.chartType, ChartType.BOXPLOT)
      ) {
        let count = 0;
        for (const field of this.pivot.rows) {
          if (_.eq(field.type, ShelveFieldType.DIMENSION) || _.eq(field.type, ShelveFieldType.TIMESTAMP)) {
            count++;
          }
        }
        return count != 1;
      }
    }

    if (_.eq(type, ShelveType.AGGREGATIONS)) {
      if (
        _.eq(this.chartType, ChartType.BAR) ||
        _.eq(this.chartType, ChartType.LINE) ||
        _.eq(this.chartType, ChartType.SCATTER) ||
        _.eq(this.chartType, ChartType.LABEL) ||
        _.eq(this.chartType, ChartType.WORDCLOUD) ||
        _.eq(this.chartType, ChartType.RADAR) ||
        _.eq(this.chartType, ChartType.GRID)
      ) {
        return true;
      } else if (_.eq(this.chartType, ChartType.PIE)) {
        let dCount = 0;
        let mCount = 0;
        for (const field of this.pivot.aggregations) {
          if (_.eq(field.type, ShelveFieldType.DIMENSION)) {
            dCount++;
          }
        }

        for (const field of this.pivot.aggregations) {
          if (_.eq(field.type, ShelveFieldType.MEASURE) || _.eq(field.type, ShelveFieldType.CALCULATED)) {
            mCount++;
          }
        }
        return dCount == 0 || dCount > 1 || mCount == 0 || mCount > 1;
      } else if (
        _.eq(this.chartType, ChartType.HEATMAP) ||
        _.eq(this.chartType, ChartType.WATERFALL) ||
        _.eq(this.chartType, ChartType.GRAPH) ||
        _.eq(this.chartType, ChartType.GRAPHV2) ||
        _.eq(this.chartType, ChartType.BOXPLOT) ||
        _.eq(this.chartType, ChartType.SANKEY) ||
        _.eq(this.chartType, ChartType.GAUGE) ||
        _.eq(this.chartType, ChartType.TREEMAP)
      ) {
        let count = 0;
        for (const field of this.pivot.aggregations) {
          if (_.eq(field.type, ShelveFieldType.MEASURE) || _.eq(field.type, ShelveFieldType.CALCULATED)) {
            count++;
          }
        }
        return count != 1;
      } else if (_.eq(this.chartType, ChartType.CONTROL)) {
        let count = 0;
        for (const field of this.pivot.aggregations) {
          if (_.eq(field.type, ShelveFieldType.TIMESTAMP)) {
            count++;
          }
        }
        return count != 1;
      } else if (_.eq(this.chartType, ChartType.COMBINE)) {
        let count = 0;
        for (const field of this.pivot.aggregations) {
          if (_.eq(field.type, ShelveFieldType.MEASURE) || _.eq(field.type, ShelveFieldType.CALCULATED)) {
            count++;
          }
        }
        return count < 2 || count > 4;
      }
    }

    return false;
  }

  public getGuideText(type: string, isText: boolean = true): string {
    if (this.chartType == '') {
      return '';
    }

    if (_.eq(type, ShelveType.COLUMNS)) {
      if (
        _.eq(this.chartType, ChartType.BAR) ||
        _.eq(this.chartType, ChartType.LINE) ||
        _.eq(this.chartType, ChartType.HEATMAP) ||
        _.eq(this.chartType, ChartType.BOXPLOT) ||
        _.eq(this.chartType, ChartType.COMBINE) ||
        _.eq(this.chartType, ChartType.GRID)
      ) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.dimension.over.desc', { value: '1' })
          : 'ddp-box-dimension';
      } else if (_.eq(this.chartType, ChartType.SCATTER)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.measure.desc', { value: '1' })
          : 'ddp-box-measure';
      } else if (_.eq(this.chartType, ChartType.CONTROL) || _.eq(this.chartType, ChartType.WATERFALL)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.dimension.desc', { value: '1' })
          : 'ddp-box-dimension';
      } else if (
        _.eq(this.chartType, ChartType.GRAPH) ||
        _.eq(this.chartType, ChartType.GRAPHV2) ||
        _.eq(this.chartType, ChartType.TREEMAP)
      ) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.dimension.desc', { value: '1' })
          : 'ddp-box-dimension';
      } else if (_.eq(this.chartType, ChartType.SANKEY)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.dimension.over.desc', { value: '2' })
          : 'ddp-box-dimension';
      }
    }

    if (_.eq(type, ShelveType.ROWS)) {
      if (
        _.eq(this.chartType, ChartType.HEATMAP) ||
        _.eq(this.chartType, ChartType.GRID) ||
        _.eq(this.chartType, ChartType.TREEMAP) ||
        _.eq(this.chartType, ChartType.GAUGE)
      ) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.dimension.over.desc', { value: '1' })
          : 'ddp-box-dimension';
      } else if (_.eq(this.chartType, ChartType.SCATTER)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.measure.desc', { value: '1' })
          : 'ddp-box-measure';
      } else if (_.eq(this.chartType, ChartType.GRAPH) || _.eq(this.chartType, ChartType.BOXPLOT)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.dimension.desc', { value: '1' })
          : 'ddp-box-dimension';
      }
    }

    if (_.eq(type, ShelveType.AGGREGATIONS)) {
      if (
        _.eq(this.chartType, ChartType.BAR) ||
        _.eq(this.chartType, ChartType.LINE) ||
        _.eq(this.chartType, ChartType.LABEL) ||
        _.eq(this.chartType, ChartType.CONTROL) ||
        _.eq(this.chartType, ChartType.GRID)
      ) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.measure.over.desc', { value: '1' })
          : 'ddp-box-measure';
      } else if (_.eq(this.chartType, ChartType.WORDCLOUD)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.measure.desc', { value: '1' }) +
              this.translateService.instant('msg.page.pivot.layout.condition.dimension.and.over.desc', { value: '1' })
          : 'ddp-box-measure';
      } else if (_.eq(this.chartType, ChartType.PIE)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.measure.desc', { value: '1' }) +
              this.translateService.instant('msg.page.pivot.layout.condition.dimension.and.desc', { value: '1' })
          : 'ddp-box-measure';
      } else if (_.eq(this.chartType, ChartType.RADAR)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.measure.over.desc', { value: '1' }) +
              this.translateService.instant('msg.page.pivot.layout.condition.dimension.and.desc', { value: '1' })
          : 'ddp-box-measure';
      } else if (_.eq(this.chartType, ChartType.COMBINE)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.measure.range', {
              valueFirst: '2',
              valueSecond: '4',
            })
          : 'ddp-box-measure';
      } else if (
        _.eq(this.chartType, ChartType.HEATMAP) ||
        _.eq(this.chartType, ChartType.WATERFALL) ||
        _.eq(this.chartType, ChartType.GRAPH) ||
        _.eq(this.chartType, ChartType.GRAPHV2) ||
        _.eq(this.chartType, ChartType.BOXPLOT) ||
        _.eq(this.chartType, ChartType.SANKEY) ||
        _.eq(this.chartType, ChartType.GAUGE) ||
        _.eq(this.chartType, ChartType.TREEMAP)
      ) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.measure.desc', { value: '1' })
          : 'ddp-box-measure';
      } else if (_.eq(this.chartType, ChartType.SCATTER)) {
        return isText
          ? this.translateService.instant('msg.page.pivot.layout.condition.dimension.over.desc', { value: '1' })
          : 'ddp-box-dimension';
      }
    }

    return '';
  }

  public getDisplayPivotName(field: PivotField): string {
    return getFieldName(field.field, field.alias);
  }

  public subscribeFromPivotContext(data: any) {
    const type = data['type'];

    switch (type) {
      case 'toggleFilter':
        this.toggleFilterEvent.emit(data.value);
        break;
      case 'format':
        this.changeFormatEvent.emit(data.value);
        break;
      case 'pivotFilter':
        this.changePivotFilter();
        break;
      case 'customField':
        this.customFieldEvent.emit(data.value);
        break;
      case 'changePivot':
        this.changePivot(data['value']);
        break;
      case 'showPopup':
        this.showPopup.emit(data.value);
        break;
      case 'onSetGranularity':
        {
          const value = data.value;
          this.onSetGranularity(value.discontinuous, value.unit, value.byUnit);
        }
        break;
      case 'outside':
        this.editingField = data.value;
        break;
    }
  }

  public getAliasPlaceholder(field: PivotField): string {
    const displayName: string = field.fieldAlias ? field.fieldAlias : field.name;
    return field['aggregationType'] ? field['aggregationType'] + '(' + displayName + ')' : displayName;
  }

  public onAliasApply(event: Event): void {
    event.stopPropagation();

    if (this.editingField.pivotAlias && this.editingField.pivotAlias.trim().length > 50) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.chart.title.maxlength.warn'));
      return;
    }

    let duppIndex: number = _.findIndex(
      _.concat(this.pivot.columns, this.pivot.rows, this.pivot.aggregations),
      (item) => {
        return item.pivotAlias == this.editingFieldAlias || item.fieldAlias == this.editingFieldAlias;
      },
    );
    if (duppIndex == -1) {
      this.dashboard.configuration.fields.forEach((field, index) => {
        if (field.nameAlias && field.nameAlias.nameAlias === this.editingFieldAlias) {
          duppIndex = index;
          return false;
        }
      });
    }

    if (duppIndex > -1) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.chart.alias.dupp.warn'));
      return;
    }

    if (this.editingFieldAlias.trim() == '') {
      this.onAliasReset(null);
      return;
    }

    this.editingFieldAlias =
      this.editingFieldAlias == this.editingField.name ? this.editingFieldAlias + ' ' : this.editingFieldAlias;
    this.editingField.alias = this.editingFieldAlias;
    this.editingField.pivotAlias = this.editingFieldAlias;
    this.fix2DepthContext = false;

    this.changePivot();
  }

  public onAliasReset(event: Event): void {
    if (event) {
      event.stopPropagation();
    }

    delete this.editingField.alias;
    delete this.editingField.pivotAlias;
    this.editingFieldAlias = '';
    this.fix2DepthContext = false;

    this.changePivot();
  }

  public setEditingFieldAlias(editingField: PivotField) {
    if (this.isSetPivotAlias(editingField)) {
      if (editingField.pivotAlias) {
        this.editingFieldAlias = editingField.pivotAlias.trim();
      } else {
        this.editingFieldAlias = editingField.alias.trim();
      }
    } else {
      this.editingFieldAlias = '';
    }
  }

  public getDisplayEditingPivotAlias(editingField: PivotField): string {
    if (editingField.pivotAlias) {
      return editingField.pivotAlias;
    } else {
      return editingField.alias ? editingField.alias : 'NONE';
    }
  }

  public isSetPivotAlias(editingField: PivotField): boolean {
    if (editingField.pivotAlias) {
      return true;
    } else {
      return (
        editingField.alias && editingField.alias !== editingField.name && editingField.fieldAlias !== editingField.alias
      );
    }
  }

  public isUseGranularity(discontinuous: boolean, unit: string, byUnit?: string): boolean {
    return this.useGranularity(discontinuous, unit, this.editingField.granularity, byUnit);
  }

  public mouseOverPrev(event: any) {
    this.animationPause = false;

    const scope = this;

    $(event.currentTarget.parentElement)
      .find('.ddp-wrap-default')
      .animate(
        { marginLeft: 0 },
        {
          duration: 1500,
          step: function () {
            if (scope.animationPause) {
              $(this).stop();
            }
          },
        },
      );
  }

  public mouseOverNext(event: any) {
    this.animationPause = false;

    const scope = this;

    const $currentShelve = $(event.currentTarget.parentElement);

    const totalWidth = this.getShelveTotalWidth($currentShelve);

    const moveWidth = totalWidth - $currentShelve.find('.ddp-ui-drag-slide-in').width();

    $(event.currentTarget.parentElement)
      .find('.ddp-wrap-default')
      .animate(
        { marginLeft: -moveWidth - 80 },
        {
          duration: 1500,
          step: function () {
            if (scope.animationPause) {
              $(this).stop();
            }
          },
        },
      );
  }

  public onChangeSecondaryAxis($event: Event): void {
    const secondaryAxis: UIChartAxis = _.cloneDeep(this.uiOption.yAxis);
    secondaryAxis.name = this.editingField.alias;
    this.uiOption.secondaryAxis = secondaryAxis;

    this.changePivot();
  }

  public onSetGranularity(discontinuous: boolean, unit: string, byUnit?: string) {
    if (this.editingField.granularityList && -1 !== this.editingField.granularityList.indexOf(unit)) {
      return;
    }

    this.editingField.format = createFormat();
    this.editingField.format.type = String(UIFormatType.TIME_CONTINUOUS);
    this.editingField.format.discontinuous = discontinuous;
    this.editingField.format.unit = TimeUnit[unit];
    if (discontinuous) {
      this.editingField.format.byUnit = ByTimeUnit[byUnit];
    }

    this.changePivot(EventType.GRANULARITY);
  }

  public onShelveAnimation(element: JQuery) {
    if (!this.changeDetect['destroyed']) {
      this.changeDetect.detectChanges();
    }

    const scope = this;

    element.each(function () {
      const totalWidth = scope.getShelveTotalWidth($(this));

      $(this).css('width', totalWidth + 150);

      if (totalWidth > $(this).parent('.ddp-ui-drag-slide-in').width()) {
        $(this).parent().parent().find('.ddp-btn-prev').show();
        $(this).parent().parent().find('.ddp-btn-next').show();
        $(this).css('padding', '0 40px');
      }
      if (totalWidth <= $(this).parent('.ddp-ui-drag-slide-in').width()) {
        $(this).parent().parent().find('.ddp-btn-prev').hide();
        $(this).parent().parent().find('.ddp-btn-next').hide();
        $(this).css('padding', '0px');

        $(this).css('marginLeft', 0);
      }
    });
  }

  protected isPossibleSeries() {
    if (
      !_.eq(this.chartType, ChartType.BAR) &&
      !_.eq(this.chartType, ChartType.LINE) &&
      !_.eq(this.chartType, ChartType.CONTROL)
    ) {
      return false;
    }

    if (this.pivot.aggregations.length < 2) {
      return false;
    }

    if (this.pivot.aggregations[0] == this.editingField) {
      return false;
    }

    return true;
  }

  protected isSecondaryAxis(): boolean {
    if (this.uiOption.secondaryAxis) {
      return false;
    }

    if (this.uiOption.secondaryAxis && this.uiOption.secondaryAxis.name == this.editingField.alias) {
      return true;
    } else {
      return false;
    }
  }

  protected onClickedOutside(event) {
    console.info(event);
    this.editingField = null;
  }

  protected fieldSetting(shelves: PivotField[]) {
    if ('measure' == this.editingField.type) {
      const aggregationTypeList = [];

      for (const item of shelves) {
        if (
          this.editingField.type == item.type &&
          this.editingField.alias == item.alias &&
          this.editingField.name == item.name
        ) {
          aggregationTypeList.push(item.aggregationType);
        }
      }

      const index = aggregationTypeList.indexOf(this.editingField.aggregationType);
      if (-1 !== index) aggregationTypeList.splice(index, 1);

      this.editingField.aggregationTypeList = aggregationTypeList;
    } else if ('timestamp' == this.editingField.type) {
      const granularityList = [];

      for (const item of shelves) {
        if (
          this.editingField.type == item.type &&
          this.editingField.alias == item.alias &&
          this.editingField.name == this.editingField.name
        ) {
          granularityList.push(item.format.unit);
        }
      }

      const index = granularityList.indexOf(this.editingField.format.unit);
      if (-1 !== index) granularityList.splice(index, 1);

      this.editingField.granularityList = granularityList;
    }

    if (this.pivot && this.pivot.aggregations)
      this.combineAggIndex = this.pivot.aggregations.indexOf(this.editingField);

    const $this = $(event.currentTarget);

    console.info('openFieldSetting', this.$editFieldLayer.width(), $this.offset());

    if ($this.offset().left > $(window).width() / 2) {
      this.$editFieldLayer.css({
        top: $this.offset().top + 17,

        left: $this.offset().left - this.$editFieldLayer.width() + 30 - this.$window.scrollLeft(),
      });
    } else {
      this.$editFieldLayer.css({
        top: $this.offset().top + 17,
        left: $this.offset().left - 30 - this.$window.scrollLeft(),
      });
    }
  }

  protected openFieldSetting(event, field) {
    if (this.editingField === field) {
      this.editingField = null;

      return;
    }

    this.editingField = field;

    const shelves = this.pivot.aggregations.concat(this.pivot.rows.concat(this.pivot.columns));

    if ('measure' == this.editingField.type) {
      const aggregationTypeList = [];

      for (const item of shelves) {
        if (
          this.editingField.type == item.type &&
          this.editingField.alias == item.alias &&
          this.editingField.name == item.name
        ) {
          aggregationTypeList.push(item.aggregationType);
        }
      }

      const index = aggregationTypeList.indexOf(this.editingField.aggregationType);
      if (-1 !== index) aggregationTypeList.splice(index, 1);

      this.editingField.aggregationTypeList = aggregationTypeList;
    } else if ('timestamp' == this.editingField.type) {
      const granularityList = [];

      for (const item of shelves as PivotField[]) {
        if (
          this.editingField.type == item.type &&
          this.editingField.alias == item.alias &&
          this.editingField.name == this.editingField.name
        ) {
          granularityList.push(item.format.unit);
        }
      }

      const index = granularityList.indexOf(this.editingField.format.unit);
      if (-1 !== index) granularityList.splice(index, 1);

      this.editingField.granularityList = granularityList;
    }

    if (this.pivot && this.pivot.aggregations)
      this.combineAggIndex = this.pivot.aggregations.indexOf(this.editingField);

    const $this = $(event.currentTarget);

    console.info('openFieldSetting', this.$editFieldLayer.width(), $this.offset());

    if ($this.offset().left > $(window).width() / 2) {
      this.$editFieldLayer.css({
        top: $this.offset().top + 17,

        left: $this.offset().left - this.$editFieldLayer.width() + 30 - this.$window.scrollLeft(),
      });
    } else {
      this.$editFieldLayer.css({
        top: $this.offset().top + 17,
        left: $this.offset().left - 30 - this.$window.scrollLeft(),
      });
    }
  }

  protected onDropSuccess(field: Field, shelf) {
    let targetShelf;
    if ('column' === shelf) {
      targetShelf = this.pivot.columns;
    } else if ('row' === shelf) {
      targetShelf = this.pivot.rows;
    } else if ('aggregation' === shelf) {
      targetShelf = this.pivot.aggregations;
    }

    let targetField;

    // if (!field instanceof PivotField) {
    // } else {
    if (field.role === FieldRole.DIMENSION) {
      targetField = createDimensionField();
    } else if (field.role === FieldRole.MEASURE) {
      targetField = createMeasureField();
      targetField.aggregationType = !field.aggregated ? AggregationType.SUM : AggregationType.NONE;
    } else if (field.role === FieldRole.TIMESTAMP) {
      targetField = createTimestampField();
    }
    targetField.name = field.name;
    targetField.expr = field.expr ? field.expr.replace(new RegExp('"', 'g'), '').trim() : '';
    targetField.alias = field.alias;

    targetShelf.push(targetField);
    //}

    this.changePivot(EventType.CHANGE_PIVOT);
  }

  protected onChangeAggregationType(aggregationTypeId: string, aggTypeOption: number) {
    event.stopPropagation();

    if (
      -1 !== this.editingField.aggregationTypeList.indexOf(aggregationTypeId) &&
      String(AggregationType.PERCENTILE) !== aggregationTypeId
    ) {
      return;
    }

    if (String(AggregationType.PERCENTILE) === aggregationTypeId && !aggTypeOption) {
      return;
    }

    this.editingField.aggregationType = aggregationTypeId;

    if (aggTypeOption) {
      this.setEditingFieldOptions('value=', aggTypeOption);
    }

    this.changePivot(EventType.AGGREGATION);
  }

  protected onChangeOrder(direction: string) {
    this.editingField.direction = DIRECTION[direction];

    _.concat(this.pivot.columns, this.pivot.rows, this.pivot.aggregations).forEach((item) => {
      delete item.lastDirection;
    });
    this.editingField.lastDirection = true;

    this.changePivot();
  }

  protected hasAlign(direction: string) {
    if (direction === 'NONE' && !this.editingField.hasOwnProperty('direction')) {
      return true;
    }
    return this.editingField.direction === DIRECTION[direction];
  }

  protected onChangeGranularity(discontinuous: boolean, unit: string, byUnit?: string) {
    if (
      this.editingField.format.discontinuous == discontinuous &&
      this.editingField.format.unit == TimeUnit[unit] &&
      this.editingField.format.byUnit == ByTimeUnit[byUnit]
    ) {
      return;
    }

    if (
      this.uiOption.color &&
      (<UIChartColorByValue>this.uiOption.color).ranges &&
      (<UIChartColorByValue>this.uiOption.color).ranges.length > 0 &&
      (this.editingField.format.discontinuous !== discontinuous || this.editingField.format.unit !== TimeUnit[unit])
    ) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.page.chart.color.measure.range.grid.original.description');
      modal.data = {
        data: { discontinuous: discontinuous, unit: unit, byUnit: byUnit },
        eventType: EventType.GRANULARITY,
      };

      this.showPopup.emit(modal);
      return;
    }

    this.onSetGranularity(discontinuous, unit, byUnit);
  }

  protected getAlignName() {
    if (this.editingField.direction === DIRECTION.ASC) {
      return 'Ascending';
    } else if (this.editingField.direction === DIRECTION.DESC) {
      return 'Descending';
    }
    return 'In order of data';
  }

  protected getGranularityName(field: PivotField, byUnitShowFl?: boolean) {
    return field.format && field.format.unit
      ? field.format.byUnit && byUnitShowFl
        ? field.format.unit.toString() + ' BY ' + field.format.byUnit.toString()
        : field.format.unit.toString()
      : '';
  }

  protected isGranularitySelected(field: PivotField, discontinuous: boolean, unit: string, byUnit?: string) {
    if (_.isUndefined(field.format)) {
      return false;
    }

    if (!discontinuous) {
      return !field.format.discontinuous && field.format.unit == TimeUnit[unit];
    } else {
      return (
        field.format.discontinuous &&
        field.format.unit == TimeUnit[unit] &&
        (!byUnit || (byUnit && field.format.byUnit == ByTimeUnit[byUnit]))
      );
    }
  }

  protected onSortSuccess(event) {}

  protected mapJoinToRef(pivotField: PivotField) {
    if (pivotField && pivotField.field && pivotField.field.join) {
      const { joinAlias } = pivotField.field.join;
      return { ...pivotField, ref: joinAlias };
    }

    return pivotField;
  }

  protected checkDuplicatedField(pivotList: PivotField[], field: DatasourceField): any {
    const duplicateList = [];

    pivotList.forEach((item) => {
      if (item.name === getFieldName(field)) {
        duplicateList.push(item);
      }
    });

    return duplicateList;
  }

  private initUnavailableShelf() {
    this.unavailableShelfs = [];

    switch (this.chartType) {
      case 'waterfall':
      case 'combine':
      case 'sankey':
      case 'line':
      case 'control':
        this.unavailableShelfs.push('row');
        break;
      case 'dpie':
      case 'ddonat':
      case 'pie':
      case 'label':
      case 'wordcloud':
      case 'radar':
        this.unavailableShelfs.push('column', 'row');
        break;
      case 'gauge':
        this.unavailableShelfs.push('column');
        break;
      default:
        break;
    }
  }

  private init() {
    this.$editFieldLayer = $('#editFieldLayer');

    this.aggTypeList = [
      { label: 'Sum', id: AggregationType.SUM },
      { label: 'Average', id: AggregationType.AVG },
      { label: 'Count', id: AggregationType.COUNT },
      //{ label: 'Median', id: AggregationType.MEDIAN },
      { label: 'Min', id: AggregationType.MIN },
      { label: 'Max', id: AggregationType.MAX },
      // {
      //   label: 'Percentile',
      //   id: AggregationType.PERCENTILE,
      //   options: [
      //     { decimal: 0.25, fraction: '1/4' },
      //     { decimal: 0.75, fraction: '3/4' },
      //   ],
      // },
    ];

    this.timestampTypeList = [
      { label: 'Second', id: GranularityType.SECOND, discontinuous: false },
      { label: 'Minute', id: GranularityType.MINUTE, discontinuous: false },
      { label: 'Hour', id: GranularityType.HOUR, discontinuous: false },
      { label: 'Day', id: GranularityType.DAY, discontinuous: false },
      { label: 'Week', id: GranularityType.WEEK, discontinuous: false },
      { label: 'Month', id: GranularityType.MONTH, discontinuous: false },
      { label: 'Quarter', id: GranularityType.QUARTER, discontinuous: false },
      { label: 'Year', id: GranularityType.YEAR, discontinuous: false },
      { label: 'None', id: GranularityType.NONE, discontinuous: false },
    ];
  }

  public distinctPivotItems(
    shelves: PivotField[],
    field: any,
    idx: number,
    shelf: PivotField[],
    targetContainer: string,
  ): boolean {
    let removedPivotItemFl = false;

    const duplicateList = [];

    shelves.forEach((item) => {
      if (item.name === field.name) {
        duplicateList.push(item);
      }
    });

    switch (field.type) {
      case String(ShelveFieldType.DIMENSION):
        if (duplicateList.length > 1) {
          shelf.splice(idx, 1);

          removedPivotItemFl = true;
        }
        break;
      case String(ShelveFieldType.MEASURE):
        if (duplicateList.length > 1) {
          if ('user_expr' == field.subType && field['aggregated']) {
            shelf.splice(idx, 1);
            removedPivotItemFl = true;
            return;
          }

          const copiedAggregation = JSON.parse(JSON.stringify(this.aggTypeList));

          loop1: for (let num = copiedAggregation.length; num--; ) {
            const aggTypeId = copiedAggregation[num].id;

            for (const item2 of duplicateList) {
              if (aggTypeId && item2.aggregationType && aggTypeId === item2.aggregationType) {
                copiedAggregation.splice(_.findIndex(copiedAggregation, { id: aggTypeId }), 1);
                continue loop1;
              }
            }
          }

          if (this.aggTypeList.length < duplicateList.length) {
            shelf.splice(idx, 1);

            removedPivotItemFl = true;
          } else {
            if (copiedAggregation && copiedAggregation.length > 0) shelf[idx].aggregationType = copiedAggregation[0].id;
          }
        } else {
          shelf[idx].aggregationType = !shelf[idx]['aggregated'] ? AggregationType.SUM : null;
        }
        break;
      case String(ShelveFieldType.TIMESTAMP):
        {
          let copiedTimestampList = _.cloneDeep(this.timestampTypeList);

          if (this.dragField) {
            copiedTimestampList = copiedTimestampList.filter((item) => {
              return item['id'] == GranularityType.NONE
                ? true
                : this.useGranularity(false, item['id'], this.dragField.granularity);
            });
          }

          if (duplicateList.length > 1) {
            loop1: for (let num = copiedTimestampList.length; num--; ) {
              const timestampTypeId = copiedTimestampList[num].id;

              const timeDiscontinuous = copiedTimestampList[num].discontinuous;

              for (const item2 of duplicateList) {
                if (
                  timestampTypeId &&
                  item2.format &&
                  timestampTypeId === item2.format.unit &&
                  timeDiscontinuous === item2.format.discontinuous
                ) {
                  copiedTimestampList.splice(
                    _.findLastIndex(copiedTimestampList, {
                      id: timestampTypeId,
                    }),
                    1,
                  );
                  continue loop1;
                }
              }
            }

            if (copiedTimestampList && copiedTimestampList.length == 0) {
              shelf.splice(idx, 1);

              removedPivotItemFl = true;
            } else {
              if (shelf[idx].field.role === FieldRole.DIMENSION) {
                const noneIndex = _.findIndex(copiedTimestampList, (obj) => {
                  return obj.id === 'NONE';
                });

                if (-1 !== noneIndex) {
                  const item = copiedTimestampList[noneIndex];
                  copiedTimestampList.splice(noneIndex, 1);
                  copiedTimestampList.unshift(item);

                  removedPivotItemFl = true;
                }
              }

              shelf[idx].format = createFormat();
              shelf[idx].format.type = String(UIFormatType.TIME_CONTINUOUS);
              shelf[idx].format.discontinuous = false;
              shelf[idx].format.unit = copiedTimestampList[0].id;
              if (field.field && field.field.format) {
                shelf[idx].format.timeZone = field.field.format.timeZone;
              }
            }
          } else {
            if (shelf[idx].field.role === FieldRole.TIMESTAMP) {
              shelf[idx].format = createFormat();
              shelf[idx].format.type = String(UIFormatType.TIME_CONTINUOUS);
              shelf[idx].format.discontinuous = false;
              shelf[idx].format.unit = copiedTimestampList[0].id;
              if (field.field && field.field.format) {
                shelf[idx].format.timeZone = field.field.format.timeZone;
              }
            } else if (shelf[idx].field.role === FieldRole.DIMENSION) {
              shelf[idx].format = createFormat();
              shelf[idx].format.type = String(UIFormatType.TIME_CONTINUOUS);
              shelf[idx].format.discontinuous = false;
              shelf[idx].format.unit = TimeUnit.NONE;
              if (field.field && field.field.format) {
                shelf[idx].format.timeZone = field.field.format.timeZone;
              }
            }
          }

          if (!removedPivotItemFl && 'grid' !== this.chartType) {
            if (this.deleteDuplicatedField(field, idx, targetContainer)) removedPivotItemFl = true;
          }
        }
        break;
    }

    return removedPivotItemFl;
  }

  protected deleteDuplicatedField(field: any, idx: number, targetContainer: string): boolean {
    switch (targetContainer) {
      case 'column':
        if (this.checkDuplicatedField(this.pivot.columns, field).length > 1) {
          this.pivot.columns.splice(idx, 1);
          return true;
        }
        break;
      case 'row':
        if (this.checkDuplicatedField(this.pivot.rows, field).length > 1) {
          this.pivot.rows.splice(idx, 1);
          return true;
        }
        break;
      case 'aggregation':
        if (this.checkDuplicatedField(this.pivot.aggregations, field).length > 1) {
          this.pivot.aggregations.splice(idx, 1);
          return true;
        }
        break;
    }

    return false;
  }

  private changeBarStackPivot() {
    if (this.widgetConfig?.chart['mark'] === String(BarMarkType.MULTIPLE)) {
      let aggDimensionExist = false;

      this.pivot.aggregations.forEach((item) => {
        aggDimensionExist = String(ShelveFieldType.DIMENSION) === item.type ? true : false;
      });

      if (aggDimensionExist) {
        for (let num = this.pivot.rows.length; num--; ) {
          const item = this.pivot.rows[num];

          if (String(ShelveFieldType.DIMENSION) === item.type) {
            this.pivot.rows.splice(num, 1);

            this.pivot.aggregations.push(item);
          }
        }
      }
    } else {
      let rowDimensionExist = false;

      this.pivot.rows.forEach((item) => {
        rowDimensionExist = String(ShelveFieldType.DIMENSION) === item.type ? true : false;
      });

      if (rowDimensionExist) {
        for (let num = this.pivot.aggregations.length; num--; ) {
          const item = this.pivot.aggregations[num];

          if (String(ShelveFieldType.DIMENSION) === item.type) {
            this.pivot.aggregations.splice(num, 1);

            this.pivot.rows.push(item);
          }
        }
      }
    }
  }

  private updatePivotList(
    deleteList: PivotField[],
    deleteType: FieldPivot,
    typeList: string[] = [''],
    addList?: PivotField[],
    fieldPivot: FieldPivot = FieldPivot.AGGREGATIONS,
  ) {
    for (let num = deleteList.length; num--; ) {
      const item = deleteList[num];

      for (const type of typeList) {
        const typeCheck: boolean = !type || '' === type ? true : type === item.type ? true : false;

        if (typeCheck) {
          deleteList.splice(num, 1);

          if (addList) {
            item.currentPivot = fieldPivot;
            addList.push(item);

            this.changePivotItem.emit({
              data: item,
              list: this.pivot.aggregations,
              addType: fieldPivot,
              deleteType: deleteType,
            });
          } else {
            this.deletePivotItem.emit({
              data: item,
              addType: fieldPivot,
              deleteType: deleteType,
            });
          }
        }
      }
    }
  }

  private setEditingFieldOptions(key: string, optionData: any) {
    if (!this.editingField.options) {
      this.editingField.options = key + optionData;
    } else {
      if (this.editingField.options.indexOf(key) != -1) {
        const optionsList = this.editingField.options.split(',');

        for (let num = 0; num < optionsList.length; num++) {
          const option = optionsList[num];

          if (option.indexOf(key) != -1) {
            optionsList[num] = num !== 0 ? key + optionData : key + optionData;
          }
        }

        this.editingField.options = optionsList.join();
      } else {
        this.editingField.options = this.editingField.options + ',' + key + optionData;
      }
    }
  }

  private chartShelveLimit(chartType: string, targetContainer: string, shelf: any) {
    const type: ChartType = ChartType[String(chartType.toUpperCase())] as ChartType;
    switch (type) {
      case ChartType.BAR:
        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        if (type === ChartType.BAR) this.changeBarStackPivot();
        break;

      case ChartType.GRID:
        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.targetFieldLimit(targetContainer, 'row', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        break;

      case ChartType.LINE:
        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.targetFieldLimit(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );
        break;

      case ChartType.PIE:
        this.targetFieldLimit(
          targetContainer,
          'column',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );
        break;

      case ChartType.SCATTER:
        this.deleteOverFieldCount(
          targetContainer,
          'column',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );

        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP], shelf);

        this.targetFieldLimit(targetContainer, 'row', [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP], shelf);

        this.deleteOverFieldCount(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
        );

        break;

      case ChartType.HEATMAP:
        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.targetFieldLimit(targetContainer, 'row', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );
        break;

      case ChartType.CONTROL:
        this.targetFieldLimit(
          targetContainer,
          'column',
          [ShelveFieldType.DIMENSION, ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
        );

        this.deleteOverFieldCount(targetContainer, 'column', [ShelveFieldType.TIMESTAMP], shelf, 1);

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );
        break;

      case ChartType.LABEL:
        this.targetFieldLimit(
          targetContainer,
          'column',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );
        break;

      case ChartType.BOXPLOT:
        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.deleteOverFieldCount(
          targetContainer,
          'row',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
          1,
        );

        this.targetFieldLimit(targetContainer, 'row', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        break;

      case ChartType.WATERFALL:
        this.targetFieldLimit(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(
          targetContainer,
          'column',
          [ShelveFieldType.DIMENSION, ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
        );

        this.deleteOverFieldCount(targetContainer, 'column', [ShelveFieldType.TIMESTAMP], shelf, 1);

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        break;

      case ChartType.WORDCLOUD:
        this.targetFieldLimit(
          targetContainer,
          'column',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );
        break;

      case ChartType.COMBINE:
        this.targetFieldLimit(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          5,
        );
        break;

      case ChartType.TREEMAP:
        this.deleteOverFieldCount(
          targetContainer,
          'column',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
          1,
        );

        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );

        this.targetFieldLimit(targetContainer, 'row', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );
        break;

      case ChartType.RADAR:
        this.targetFieldLimit(
          targetContainer,
          'column',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
          1,
        );
        break;

      case ChartType.GAUGE:
        this.targetFieldLimit(
          targetContainer,
          'column',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(targetContainer, 'row', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );
        break;

      case ChartType.GRAPH:
      case ChartType.GRAPHV2:
        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.deleteOverFieldCount(
          targetContainer,
          'column',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
          1,
        );

        this.targetFieldLimit(targetContainer, 'row', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.deleteOverFieldCount(
          targetContainer,
          'row',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
          1,
        );

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );
        break;

      case ChartType.SANKEY:
        this.targetFieldLimit(
          targetContainer,
          'row',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE, ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.targetFieldLimit(targetContainer, 'column', [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE], shelf);

        this.targetFieldLimit(
          targetContainer,
          'aggregation',
          [ShelveFieldType.DIMENSION, ShelveFieldType.TIMESTAMP],
          shelf,
        );

        this.deleteOverFieldCount(
          targetContainer,
          'aggregation',
          [ShelveFieldType.CALCULATED, ShelveFieldType.MEASURE],
          shelf,
          1,
        );
        break;
    }
  }

  private deleteOverFieldCount(
    targetContainer: string,
    containerType: string,
    shelveFieldTypeList: ShelveFieldType[],
    shelf: any,
    overCount: number = 0,
  ) {
    let index = 0;

    if (overCount > 0) {
      for (const type of shelveFieldTypeList) {
        for (const item of shelf) {
          if (String(type) === item.type) {
            if (targetContainer === containerType) {
              index++;
            }
          }
        }
      }

      if (index > overCount) {
        _.remove(shelf, this.dragField);

        if (this.dragField.field.pivot && this.dragField.field.pivot.length > 0)
          this.dragField.field.pivot.splice(this.dragField.field.pivot.length - 1, 1);
      }
    }
  }

  private targetFieldLimit(
    targetContainer: string,
    containerType: string,
    shelveFieldTypeList: ShelveFieldType[],
    shelf: any,
  ) {
    const typeString = String(this.dragField.type);
    const index = shelveFieldTypeList.findIndex((type) => String(type) === typeString);

    if (index !== -1 && targetContainer === containerType) {
      shelf.splice(index, 1);

      const pivot = this.dragField.field.pivot;
      if (pivot && pivot.length > 0) {
        pivot.splice(pivot.length - 1, 1);
      }
    }
  }

  public removeAnimation() {
    const element = this.$element.find('.ddp-wrap-default');

    const scope = this;

    element.each(function () {
      const totalWidth = scope.getShelveTotalWidth($(this));

      $(this).css('width', totalWidth + 150);

      $(this).parent().parent().find('.ddp-btn-prev').hide();
      $(this).parent().parent().find('.ddp-btn-next').hide();
      $(this).css('padding', '0px');

      $(this).css('marginLeft', 0);
    });
  }

  protected getShelveTotalWidth($currentShelve: JQuery): number {
    let totalExWidth = 0;
    let totalDefaultWidth = 0;
    let totanetworkWidth = 0;

    $currentShelve.find('.ddp-wrap-example').each(function () {
      totalExWidth += Math.ceil($(this).outerWidth() + 2);
    });
    $currentShelve.find('.ddp-ui-default').each(function (i) {
      totalDefaultWidth += Math.ceil($(this).outerWidth() + 2);
    });
    $currentShelve.find('.ddp-box-network').each(function (i) {
      totanetworkWidth += Math.ceil($(this).outerWidth() + 4 - $(this).find('.ddp-ui-default').outerWidth() + 10);
    });
    const totalWidth = totalExWidth + totalDefaultWidth + totanetworkWidth;

    return totalWidth;
  }

  private autoPivoting(dimensionPivotType?: FieldPivot, measurePivotType?: FieldPivot) {
    switch (dimensionPivotType) {
      case FieldPivot.COLUMNS:
        this.updatePivotList(
          this.pivot.rows,
          FieldPivot.ROWS,
          [String(ShelveFieldType.DIMENSION), String(ShelveFieldType.TIMESTAMP)],
          this.pivot.columns,
          FieldPivot.COLUMNS,
        );
        this.updatePivotList(
          this.pivot.aggregations,
          FieldPivot.AGGREGATIONS,
          [String(ShelveFieldType.DIMENSION), String(ShelveFieldType.TIMESTAMP)],
          this.pivot.columns,
          FieldPivot.COLUMNS,
        );
        break;
      case FieldPivot.ROWS:
        this.updatePivotList(
          this.pivot.columns,
          FieldPivot.COLUMNS,
          [String(ShelveFieldType.DIMENSION), String(ShelveFieldType.TIMESTAMP)],
          this.pivot.rows,
          FieldPivot.ROWS,
        );
        this.updatePivotList(
          this.pivot.aggregations,
          FieldPivot.AGGREGATIONS,
          [String(ShelveFieldType.DIMENSION), String(ShelveFieldType.TIMESTAMP)],
          this.pivot.rows,
          FieldPivot.ROWS,
        );
        break;
      case FieldPivot.AGGREGATIONS:
        this.updatePivotList(
          this.pivot.columns,
          FieldPivot.COLUMNS,
          [String(ShelveFieldType.DIMENSION), String(ShelveFieldType.TIMESTAMP)],
          this.pivot.aggregations,
        );
        this.updatePivotList(
          this.pivot.rows,
          FieldPivot.ROWS,
          [String(ShelveFieldType.DIMENSION), String(ShelveFieldType.TIMESTAMP)],
          this.pivot.aggregations,
        );
        break;
    }

    switch (measurePivotType) {
      case FieldPivot.AGGREGATIONS:
        this.updatePivotList(
          this.pivot.columns,
          FieldPivot.COLUMNS,
          [String(ShelveFieldType.MEASURE), String(ShelveFieldType.CALCULATED)],
          this.pivot.aggregations,
        );
        this.updatePivotList(
          this.pivot.rows,
          FieldPivot.ROWS,
          [String(ShelveFieldType.MEASURE), String(ShelveFieldType.CALCULATED)],
          this.pivot.aggregations,
        );
        break;
    }
  }

  private moveShelfItemByIndex(
    fieldTypeList: string[],
    deleteIndex: number,
    columnIndexList?: number[],
    rowIndexList?: number[],
    columnUnlimitedIndex?: number,
    rowUnlimitedIndex?: number,
  ) {
    const getFieldList = (item): void => {
      if (FieldPivot.COLUMNS == item.currentPivot) {
        _.remove(this.pivot.columns, item);
      } else if (FieldPivot.ROWS == item.currentPivot) {
        _.remove(this.pivot.rows, item);
      } else if (FieldPivot.AGGREGATIONS == item.currentPivot) {
        _.remove(this.pivot.aggregations, item);
      }
    };

    if (typeof deleteIndex !== null) {
      const list = this.pivot.aggregations
        .concat(this.pivot.rows.concat(this.pivot.columns))
        .filter((item) => -1 !== fieldTypeList.indexOf(item.type));
      list.forEach((item, index) => {
        if (index > deleteIndex) {
          getFieldList(item);

          this.deletePivotItem.emit({
            data: item,
            list: list,
            deleteType: item.currentPivot,
          });
        }
      });
    }

    if (columnIndexList && rowIndexList) {
      const editedList = this.pivot.aggregations
        .concat(this.pivot.rows.concat(this.pivot.columns))
        .filter((item) => -1 !== fieldTypeList.indexOf(item.type));

      editedList.forEach((item, index) => {
        getFieldList(item);

        if (-1 !== columnIndexList.indexOf(index) || (columnUnlimitedIndex && index >= columnUnlimitedIndex)) {
          item.field.pivot.splice(item.field.pivot.indexOf(item.currentPivot), 1);
          item.field.pivot.push(FieldPivot.COLUMNS);
          item.currentPivot = FieldPivot.COLUMNS;

          this.pivot.columns.push(item);
        } else if (-1 !== rowIndexList.indexOf(index) || (rowUnlimitedIndex && index >= rowUnlimitedIndex)) {
          item.field.pivot.splice(item.field.pivot.indexOf(item.currentPivot), 1);
          item.field.pivot.push(FieldPivot.ROWS);
          item.currentPivot = FieldPivot.ROWS;

          this.pivot.rows.push(item);
        }
      });
    }
  }

  private useGranularity(discontinuous: boolean, unit: string, granularity: GranularityType, byUnit?: string): boolean {
    const getGranularityScore = (granularity: string): number => {
      let score = 0;
      switch (granularity) {
        case String(GranularityType.SECOND):
          score = 1;
          break;
        case String(GranularityType.MINUTE):
          score = 2;
          break;
        case String(GranularityType.HOUR):
          score = 3;
          break;
        case String(GranularityType.DAY):
          score = 4;
          break;
        case String(GranularityType.WEEK):
          score = 4;
          break;
        case String(GranularityType.MONTH):
          score = 6;
          break;
        case String(GranularityType.QUARTER):
          score = 6;
          break;
        case String(GranularityType.YEAR):
          score = 8;
          break;
      }
      return score;
    };

    const minGranularityScore: number = getGranularityScore(String(granularity));

    const granularityScore: number = getGranularityScore(unit);

    return granularityScore >= minGranularityScore;
  }

  private getUniqTimestampShelve() {
    const getTimeStampList = (shelve: PivotField[], fieldPivot: FieldPivot) => {
      for (let index = shelve.length; index--; ) {
        const item = shelve[index];

        if (item.type == 'timestamp') {
          const duplicateList = shelve.filter((data) => {
            return data.name == item.name;
          });

          if (duplicateList.length > 1) {
            shelve.splice(index, 1);

            item.field.pivot.splice(item.field.pivot.indexOf(fieldPivot), 1);
          }
        }
      }
    };

    if (this.pivot) {
      getTimeStampList(this.pivot.columns, FieldPivot.COLUMNS);
      getTimeStampList(this.pivot.rows, FieldPivot.ROWS);
      getTimeStampList(this.pivot.aggregations, FieldPivot.AGGREGATIONS);
    }
  }
}
