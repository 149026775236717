<div class="composition-confirm--modal">
  <div class="composition-confirm-wrapper">
    <div class="composition-confirm-title">{{ "msg.modal.delete?" | translate }}</div>
    <button class="composition-confirm-btn-delete" (click)="confirmDeleteDel()">
      {{ "msg.modal.delete" | translate }}
    </button>
    <button class="composition-confirm-btn-cancel" (click)="confirmDeleteCancel()">
      {{ "msg.modal.cancel" | translate }}
    </button>
  </div>
</div>
