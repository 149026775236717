import { EntityMetadataMap } from '@ngrx/data';
import { WORKBOOK_PINS_ENTITY_NAME } from '../const';
import { WorkbookPinsState } from '../models';

const entityMetadata: EntityMetadataMap = {
  [WORKBOOK_PINS_ENTITY_NAME]: {
    selectId: (entity: WorkbookPinsState) => entity.workbookId,
  },
};

export const biDomainWorkbookEntityConfig = {
  entityMetadata,
};
