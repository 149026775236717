import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationFilterModel, ConfigurationModel, SearchQueryRequestApiModel } from '../../api';
import { KdBackendApiService } from '../../common';
import { DashboardDetailViewApiModel } from '../../dashboard';
import {
  CreateWidgetApiModel,
  WidgetApiModel,
  WidgetDetailResponseApiModel,
  WidgetPropertiesMapView,
} from '../models/widget-api.model';
import { WidgetCreateApiModel } from '../models/widget-create-api.model';
import { WidgetUpdateApiModel } from '../models/widget-update-api.model';

@Injectable()
export class WidgetApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  getPropMapView(): Observable<WidgetPropertiesMapView> {
    const mapViewApi = `widgets/properties/mapview`;
    return this.kdBackendApiService.get<WidgetPropertiesMapView>(mapViewApi);
  }

  getWidget(widgetId: string): Observable<WidgetApiModel> {
    const widgetApi = `widgets/${widgetId}?projection=forDetailView`;
    return this.kdBackendApiService.get<WidgetDetailResponseApiModel>(widgetApi);
  }

  getDashboardByWidgetId(widgetId: string): Observable<DashboardDetailViewApiModel> {
    const boardApi = `widgets/${widgetId}/dashBoard?projection=forDetailView`;
    return this.kdBackendApiService.get<DashboardDetailViewApiModel>(boardApi);
  }

  createWidget(widget: CreateWidgetApiModel, dashboardId: string): Observable<WidgetApiModel> {
    const url = 'widgets';
    const param: WidgetCreateApiModel = {
      name: widget.name,
      type: widget.type,
      configuration: widget.configuration,
      dashBoard: '/api/dashboards/' + dashboardId,
    };
    return this.kdBackendApiService.post<WidgetApiModel>(url, param);
  }

  updateWidget(widgetId: string, options: WidgetUpdateApiModel): Observable<WidgetApiModel> {
    return this.kdBackendApiService.patch<WidgetApiModel>('widgets/' + widgetId, options);
  }

  deleteWidget(widgetId: string): Observable<void> {
    return this.kdBackendApiService.delete<void>('widgets/' + widgetId);
  }

  previewConfig(query: ConfigurationModel, original: boolean, preview: boolean): Observable<unknown> {
    const config: ConfigurationModel = {
      type: 'page',
      dataSource: query.dataSource,
      fields: query.fields,
      filters: query.filters,
      pivot: query.pivot,
      limit: query.limit,
    };

    const url = `widgets/config/data?original=${original}&preview=${preview}`;
    return this.kdBackendApiService.post<unknown>(url, config);
  }

  downloadConfig(
    query: SearchQueryRequestApiModel,
    original: boolean,
    maxRowsPerSheet: number,
    fileType?: string,
  ): Observable<Blob> {
    const config = {
      type: 'page',
      dataSource: query.dataSource,
      fields: query.userFields,
      filters: query.filters,
      pivot: query.pivot,
      limit: query.limits,
    };

    const strType: string = 'CSV' === fileType ? 'application/csv' : 'application/vnd.ms-excel';

    const url: string = 'widgets/config/download?original=' + original + '&maxRowsPerSheet=' + maxRowsPerSheet;

    return this.kdBackendApiService.post<BlobPart>(url, config).pipe(
      map((res) => {
        return new Blob([res], { type: strType });
      }),
    );
  }

  previewWidget(widgetId: string, original: boolean, preview: boolean, param: unknown = null): Observable<unknown> {
    const url = `widgets/${widgetId}/data?original=${original}&preview=${preview}`;
    return this.kdBackendApiService.post(url, param);
  }

  downloadWidget(
    widgetId: string,
    original: boolean,
    maxRowsPerSheet?: number,
    fileType?: string,
    param: ConfigurationFilterModel[] | null = null,
  ): Observable<Blob> {
    let url: string = 'widgets/' + widgetId + '/download?original=' + original;

    if (maxRowsPerSheet) {
      url = url + '&maxRowsPerSheet=' + maxRowsPerSheet;
      url = url + '&limit=' + maxRowsPerSheet;
    }

    const strType: string = 'CSV' === fileType ? 'application/csv' : 'application/vnd.ms-excel';

    return this.kdBackendApiService.post<BlobPart>(url, param).pipe(
      map((res) => {
        return new Blob([res], { type: strType });
      }),
    );
  }
}
