import { Injectable } from '@angular/core';
import { MergeStrategy } from '@ngrx/data';
import { PageParams, getIdContextKey } from '@selfai-platform/shared';
import { Observable, filter, map, of, switchMap, take, tap } from 'rxjs';
import { GlobalActiveFiltersService } from '../../filters';
import { Filter } from '../../workbook';
import { getAllFiltersDsRelations } from '../functions';
import { BoardWidgetOptions, Dashboard, DashboardList, LayoutWidgetInfo } from '../models';
import { DashboardStore, WidgetDashboardStore } from '../stores';
import { DashboardApiToDomainService } from './dashboard-api-to-domain.service';

@Injectable({ providedIn: 'root' })
export class DashboardDomainService {
  constructor(
    private readonly dashboardApiToDomainService: DashboardApiToDomainService,
    private readonly dashboardStore: DashboardStore,
    private readonly widgetDashboardStore: WidgetDashboardStore,
    private readonly globalActiveFiltersService: GlobalActiveFiltersService,
  ) {}

  loadDashboard(dashboardId: string, widgetContextId?: string): Observable<Dashboard> {
    return this.dashboardStore.getByKey(dashboardId, { mergeStrategy: MergeStrategy.OverwriteChanges }).pipe(
      tap((dashboard) => {
        this.dashboardStore.upsertOneInCache({ id: dashboardId, ...dashboard, contextId: widgetContextId });
        this.globalActiveFiltersService.setFilters(dashboardId, dashboard.configuration.filters, widgetContextId);
      }),
    );
  }

  loadDashboardByWidgetId(widgetId: string, widgetContextId?: string): Observable<Dashboard> {
    return this.widgetDashboardStore.getByKey(widgetId, { mergeStrategy: MergeStrategy.OverwriteChanges }).pipe(
      tap((dashboard) => {
        this.dashboardStore.upsertOneInCache({ id: dashboard.id, ...dashboard, contextId: widgetContextId });
        this.widgetDashboardStore.upsertOneInCache({ ...dashboard, widgetId, contextId: widgetContextId });
        this.globalActiveFiltersService.setFilters(dashboard.id, dashboard.configuration.filters, widgetContextId);
      }),
    );
  }

  loadDashboardByWidgetIdIfNotLoaded(widgetId: string, widgetContextId?: string): Observable<Dashboard | null> {
    return this.getDashboardByWidgetId(widgetId, widgetContextId).pipe(
      switchMap((dashboard) => {
        if (dashboard) {
          return this.globalActiveFiltersService.getFields(dashboard.id, widgetContextId).pipe(
            map((filters) => {
              if (filters.length === 0 && dashboard.configuration.filters.length > 0) {
                this.globalActiveFiltersService.setFilters(
                  dashboard.id,
                  dashboard.configuration.filters,
                  widgetContextId,
                );
              }

              return dashboard;
            }),
          );
        }

        return of(null);
      }),
      take(1),
      tap((dashboard) => {
        if (!dashboard) {
          this.loadDashboardByWidgetId(widgetId, widgetContextId).subscribe();
        }
      }),
    );
  }

  linkDashboardToWidget({
    dashboardId,
    widgetId,
    widgetContextId,
  }: {
    dashboardId: string;
    widgetId: string;
    widgetContextId?: string;
  }): void {
    this.getDashboard(dashboardId)
      .pipe(filter(Boolean))
      .subscribe((dashboard) => {
        this.widgetDashboardStore.upsertOneInCache({ ...dashboard, widgetId, contextId: widgetContextId });
      });
  }

  getDashboard(dashboardId: string): Observable<Dashboard> {
    return this.dashboardStore.entityMap$.pipe(
      map((entityMap) => entityMap?.[dashboardId]),
      filter(Boolean),
    );
  }

  getDashboardLoading(): Observable<boolean> {
    return this.dashboardStore.loading$;
  }

  getDashboardError(): Observable<unknown> {
    return this.dashboardStore.errors$;
  }

  getBoardWidgetOptions(dashboardId: string): Observable<BoardWidgetOptions> {
    return this.getDashboard(dashboardId).pipe(
      map((dashboard) => {
        return dashboard.configuration.options.widget;
      }),
    );
  }

  getBoardWidgetOptionsByWidgetId(widgetId: string): Observable<BoardWidgetOptions> {
    return this.getDashboardByWidgetId(widgetId).pipe(
      filter(Boolean),
      map((dashboard) => {
        return dashboard.configuration.options.widget;
      }),
    );
  }

  getDashboardByWidgetId(widgetId: string, widgetContextId?: string): Observable<Dashboard> {
    return this.widgetDashboardStore.entityMap$.pipe(
      map((entityMap) => entityMap?.[getIdContextKey(widgetId, widgetContextId)]),
    );
  }

  getDashboardByWidgetIdLoading(): Observable<boolean> {
    return this.widgetDashboardStore.loading$;
  }

  getLayoutWidgetInfoByWidgetId(widgetId: string): Observable<LayoutWidgetInfo> {
    return this.getDashboardByWidgetId(widgetId).pipe(
      map((dashboard) => dashboard.configuration.widgets.find((item) => item.ref === widgetId)),
    ) as Observable<LayoutWidgetInfo>;
  }

  getAllFiltersDsRelations(dashboardId: string, engineName: string, paramFilters?: Filter[]): Observable<Filter[]> {
    return this.getDashboard(dashboardId).pipe(
      map((dashboard) => {
        return getAllFiltersDsRelations(dashboard, engineName, paramFilters);
      }),
    );
  }

  addDashboard(workbookId: string, dashboard: Dashboard): Observable<Dashboard> {
    return this.dashboardStore.add({ ...dashboard, workbookId });
  }

  updateDashboard(dashboard: Partial<Dashboard> & Pick<Dashboard, 'configuration' | 'id'>): Observable<Dashboard> {
    return this.dashboardStore.update(dashboard);
  }

  loadDashboardList(workbookId: string, pageParams?: PageParams): Observable<DashboardList> {
    return this.dashboardApiToDomainService.loadDashboardList(workbookId, pageParams);
  }
}
