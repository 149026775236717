<ng-container *ngIf="workspacePermission">
  <ng-container *ngIf="workspacePermission.hasPermission(biPermissionEntity.WORKBOOK_VIEW); else accessDenied">
    <selfai-platform-workbook-toolbar
      *ngIf="selectedDashboard"
      [workspace]="workspace"
      [workbook]="workbook"
      [(thumbnailsVisibility)]="thumbnailsVisibility"
      [selectedDashboard]="selectedDashboard"
      [dashboards]="dashboards"
      (dashboardDelete)="getConfirmDelete($event)"
      (dashboardClone)="dashboardClone($event)"
      (creatingDashboardShow)="isShowCreateDashboard = $event"
    ></selfai-platform-workbook-toolbar>

    <selfai-platform-workbook-thumbnails
      *ngIf="thumbnailsVisibility; else pins"
      [workspace]="workspace"
      [dashboards]="dashboards"
      [workbookId]="workbook.id"
      [selectedDashboard]="selectedDashboard"
      (dashboardDelete)="getConfirmDelete($event)"
      (dashboardSelect)="getDashboard($event)"
      (dashboardClone)="dashboardClone($event)"
      (creatingDashboardShow)="isShowCreateDashboard = $event"
    ></selfai-platform-workbook-thumbnails>

    <ng-template #pins>
      <selfai-platform-workbook-pins [workspace]="workspace" [workbookId]="workbook.id"></selfai-platform-workbook-pins>
    </ng-template>

    <app-workbook-navigation
      *ngIf="workbook"
      (gridMenu)="addGridMenuState($event)"
      [workbook]="workbook"
      [dashboards]="dashboards"
      [_boardComp]="_boardComp"
      [selectedDashboard]="selectedDashboard"
      [onlyShowingFlag]="onlyShowingFlag"
      [workbookId]="workbookId"
      [publicType]="publicType"
      [workspace]="workspace"
      [selectedDatasources]="selectedDatasources"
      [datasources]="datasources"
      [dashboardSrchText]="dashboardSrchText"
      [dashboardPage]="dashboardPage"
      [isShowMoreDashboardList]="isShowMoreDashboardList"
      [dashboardId]="dashboardId"
      (dashBoard)="getDashboard($event)"
      (dashboardName)="getSearch($event)"
      (confirmDelete)="getConfirmDelete($event)"
      (modalSetProfile)="getModalProfile($event)"
      (clientIDDataEmitter)="getClientIDData($event)"
      #confirmDelete
    ></app-workbook-navigation>

    <div class="ddp-ui-dash-contents page-dashboard" id="scrollbar_style" [class.ddp-close]="isCloseDashboardList">
      <div class="ddp-box-data-none" *ngIf="mode === 'NO_DATA' && loading === false">
        <div class="ddp-wrap-data-none">
          <div class="ddp-ui-data-none">
            <span class="ddp-txt-nodata">
              {{ 'msg.board.ui.dashboard.none' | translate }}
              <br />
              <span>
                {{ 'msg.board.ui.dashboard.none.description' | translate }}
              </span>
            </span>
            <a
              *ngIf="hasPermission(biPermissionEntity.DASHBOARD_CREATE)"
              (click)="createDashboard()"
              href="javascript:"
              class="ddp-link-board-add"
            >
              <em class="ddp-link-add"></em>
              {{ 'msg.comm.ui.create.project' | translate }}
            </a>
          </div>
        </div>
      </div>

      <input-grid-chart *ngIf="gridMenuState"></input-grid-chart>
      <app-dashboard
        *ngIf="!gridMenuState && workspacePermission"
        [isManagementUser]="hasPermission(biPermissionEntity.DASHBOARD_EDIT)"
        [inputDashboard]="selectedDashboard"
        (dashboardEvent)="onDashboardEvent($event)"
      ></app-dashboard>
    </div>

    <div *ngIf="isShowDetailMenu" style="display: block" class="ddp-wrap-popup2 ddp-types ddp-popup-lnbmore">
      <div class="ddp-ui-date">
        <span class="ddp-data-date">
          {{
            'msg.common.ui.updated'
              | translate
                : {
                    modifiedTime: workbook.modifiedTime | mdate: 'YYYY-MM-DD HH:mm',
                    fullName: workbook.modifiedBy.fullName
                  }
          }}
        </span>
        <span class="ddp-data-date">
          {{
            'msg.common.ui.created'
              | translate
                : {
                    createdTime: workbook.createdTime | mdate: 'YYYY-MM-DD HH:mm',
                    fullName: workbook.createdBy.fullName
                  }
          }}
        </span>
      </div>
    </div>

    <popup-input-namedesc (triggerDone)="afterInputInfo($event)"></popup-input-namedesc>
    <app-delete-modal (deleteConfirm)="deleteContent($event)"></app-delete-modal>
    <selfai-bi-update-dashboard
      *ngIf="mode === 'UPDATE'"
      [startupCmd]="updateDashboardStartupCmd"
      [workbook]="workbook"
      [dashboards]="dashboards"
      (changeMode)="changeMode($event)"
      (updateComplete)="updateCompleteDashboard($event)"
      (createDashboard)="createDashboard()"
      (selectedDashboard)="loadAndSelectDashboard($event)"
    ></selfai-bi-update-dashboard>
    <app-create-board
      *ngIf="isShowCreateDashboard"
      [workbookId]="workbook.id"
      [workbookName]="workbook.name"
      [workspaceId]="workbook.workspaceId"
      (close)="closeCreateDashboard()"
    ></app-create-board>

    <data-preview
      *ngIf="isShowDataPreview"
      [source]="selectedDashboard"
      (close)="isShowDataPreview = false"
    ></data-preview>

    <app-essential-filter
      *ngIf="isShowDataIngestion"
      [datasource]="ingestionTargetDatasource"
      (close)="isShowDataIngestion = false"
      (done)="finishDataIngestion($event)"
    ></app-essential-filter>

    <workbook-confirm-delete
      (confirmDelete)="setConfirmDelete($event)"
      *ngIf="this.gettingConfirmDel"
    ></workbook-confirm-delete>
    <app-modal-workbook-nav-locked
      *ngIf="this._ptService.ptModalClose === 'locked'"
      [clientIDData]="clientIDData"
    ></app-modal-workbook-nav-locked>
    <app-modal-workbook-nav-unlocked
      *ngIf="this._ptService.ptModalClose === 'unlocked'"
      [clientIDData]="clientIDData"
    ></app-modal-workbook-nav-unlocked>
    <app-modal-workbook-nav-limited
      *ngIf="this._ptService.ptModalClose === 'limited'"
      [clientIDData]="clientIDData"
    ></app-modal-workbook-nav-limited>
  </ng-container>
  <ng-template #accessDenied>
    <div class="flex align-items-center flex-column h-full justify-content-center">
      <h1 class="exception-summary">{{ 'msg.comm.alert.access-denied.title' | translate }}</h1>
      <p>{{ 'msg.comm.alert.access-denied.desc' | translate }}</p>
      <button
        pButton
        pRipple
        label="{{ 'msg.comm.ui.previous' | translate }}"
        icon="pi pi-home"
        routerLink="/kd"
        tabindex="0"
      ></button>
    </div>
  </ng-template>
</ng-container>
