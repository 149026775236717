import { Config } from "../../config";
import jwt_decode from 'jwt-decode';
import { KeycloakParsedToken } from "../../permission";
import { getKeClientId } from "./get-ke-client-id";
import { getKdClientId } from "./get-kd-client-id";
import { IdentityProviderRoles } from "../models";

export function getRolesFromKeycloakToken(token: string, config: Config): IdentityProviderRoles {
    const resultRoles: IdentityProviderRoles = {
        kdRoles: [],
        keRoles: []
    };
    try {
        const decodedKeycloakToken = jwt_decode(token) as KeycloakParsedToken;
        const keKeycloakClientId = getKeClientId(config);
        if (decodedKeycloakToken.resource_access[keKeycloakClientId]) {
          const clientRoles = decodedKeycloakToken.resource_access[keKeycloakClientId].roles;
          resultRoles.keRoles = clientRoles;
        }
        const kdClientId = getKdClientId(config);
        if (decodedKeycloakToken.resource_access[kdClientId]) {
          const clientRoles = decodedKeycloakToken.resource_access[kdClientId].roles;
          resultRoles.kdRoles = clientRoles;
        }
      } catch (error) {
        console.error('Permission calculation has failed with error', error);
      }
    return resultRoles;
}