import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BiPermissionService } from '@selfai-platform/bi-domain';
import { BiPermission, KdConfigService } from '@selfai-platform/shared';
import { MenuService } from '../../menu';
import { PATH_RECYCLEBIN_ADMIN, PATH_RECYCLEBIN_USER } from '@selfai-platform/bi-shared';

@Component({
  selector: 'selfai-platform-recycle-menu',
  templateUrl: './recycle-menu.component.html',
  styleUrls: ['./recycle-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecycleMenuComponent {
  menuMode$ = this.menuService.menuMode$;

  PATH_RECYCLEBIN_ADMIN = PATH_RECYCLEBIN_ADMIN;
  PATH_RECYCLEBIN_USER = PATH_RECYCLEBIN_USER;

  constructor(private permissionService: BiPermissionService, private menuService: MenuService, private kdConfigService: KdConfigService) {}

  get hasAccessToAdminRecycleBin() {
    return this.permissionService.hasPermission(BiPermission.KD_RECYCLEBIN_ADMIN);
  }

  get hasAccessToUserRecycleBin() {
    return this.kdConfigService.getConfig().enabled;
  }
}
