import { isNullOrUndefined } from '@selfai-platform/shared';

import { BoardDataSource, BoardDataSourceRelation, Dashboard, Datasource } from '@selfai-platform/bi-domain';

import { isSameDataSource } from '../../../util';

export function getInformationAboutDeletedDatasources(boardInfo: Dashboard, _removeDsEngineNames: string[]): string[] {
  const boardDataSource: BoardDataSource = boardInfo.configuration.dataSource;
  const dataSource: Datasource[] = boardInfo.dataSources;
  if ('multi' === boardDataSource.type) {
    boardDataSource.dataSources = boardDataSource.dataSources.filter((boardDs: BoardDataSource) => {
      if (dataSource.some((item) => isSameDataSource(boardDs, item))) {
        return true;
      } else {
        const engineName: string = boardDs.engineName ? boardDs.engineName : boardDs.name;
        if (boardInfo.configuration.filters) {
          boardInfo.configuration.filters = boardInfo.configuration.filters.filter(
            (item) => item.dataSource !== engineName,
          );
        }
        _removeDsEngineNames.push(engineName);
        return false;
      }
    });

    boardDataSource.associations = boardDataSource.associations?.filter((ass: BoardDataSourceRelation) => {
      return !(
        _removeDsEngineNames.some((item) => item === ass.source) ||
        _removeDsEngineNames.some((item) => item === ass.target)
      );
    });
  } else {
    if (isNullOrUndefined(dataSource) || 0 === dataSource.length) {
      _removeDsEngineNames.push(boardDataSource.engineName);
      boardInfo.configuration.dataSource = null;
    }
  }
  return [..._removeDsEngineNames];
}
