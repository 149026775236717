import { AfterViewInit, Component, OnInit } from '@angular/core';

import {
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartColorList,
  ChartPivotType,
  ChartType,
  Orient,
  PieSeriesViewType,
  Pivot,
  PivotTableInfo,
  Position,
  PositionLabel,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  SymbolType,
  UIChartFormat,
  UIOption,
  UIPieChart,
  UiChartDataLabelDisplayType,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';
import { PieSeriesOption } from 'echarts';
import { LegendComponentOption } from 'echarts/components';
import * as _ from 'lodash';
import { merge } from 'lodash';
import { FormatOptionConverter, LegendOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

import optGen = OptionGenerator;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pie-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class PieChartComponent extends BaseChart implements OnInit, AfterViewInit {
  public override isValid(shelve: Pivot): boolean {
    return (
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 1 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) ==
        1
    );
  }

  public override draw(isKeepRange?: boolean): void {
    this.fieldInfo.aggs = this.pivot.aggregations
      .filter((agg) => {
        return _.eq(agg.type, ShelveFieldType.DIMENSION) || _.eq(agg.type, ShelveFieldType.TIMESTAMP);
      })
      .map((agg) => {
        return !_.isEmpty(agg.alias) ? agg.alias : agg.name;
      });

    this.pivotInfo = this.setPiePivotInfo();

    super.draw(isKeepRange);
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.PIE,
      legend: OptionGenerator.Legend.custom(true, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      series: [],
    };
  }

  protected override convertSeriesData(): BaseOption {
    let pieSizeInfo: any = {};

    if (this.data.columns.length > 1) {
      const orient = !_.isEmpty(this.pivot.columns) ? Orient.HORIZONTAL : Orient.VERTICAL;
      pieSizeInfo = this.setPieSizeCount(this.data.columns.length, orient);
    }

    const seriesList = [];
    let existEtcData = false;

    _.each(this.data.columns, (column, idx) => {
      for (const item of column.value) {
        if (item['name'].trim() == '') {
          item['name'] = 'EMPTY';
        }
      }

      const seriesName = column.name;
      const resultSeries: PieSeriesOption = {
        type: SeriesType.PIE,
        name: seriesName,
        radius: !_.isEmpty(pieSizeInfo) ? pieSizeInfo.radius[idx] : ['0%', '60%'],
        center: !_.isEmpty(pieSizeInfo) ? pieSizeInfo.center[idx] : ['50%', '50%'],
        data: column.value.map((val) => {
          val.selected = false;
          val.itemStyle = optGen.ItemStyle.opacity1();
          return val;
        }),
        selectedMode: true,
        itemStyle: optGen.ItemStyle.auto(),
        label: optGen.LabelStyle.defaultLabelStyle(true, PositionLabel.INSIDE),
        tooltip: {
          formatter: (param) => {
            return this.tooltipFormatter(param, this.pivot);
          },
        },
      };

      const otherList = [];
      const otherValueList = resultSeries.data
        // PieDataItemOption instead of any
        .filter((dataObj: any, index) => {
          const isOtherValue =
            null != (<UIPieChart>this.uiOption).maxCategory && undefined != (<UIPieChart>this.uiOption).maxCategory
              ? (<UIPieChart>this.uiOption).maxCategory <= index
              : false;
          if (isOtherValue) {
            otherList.push(dataObj.name);
          }
          return isOtherValue;
        })
        .map((dataObj: any) => {
          return { value: dataObj.value, percentage: dataObj.percentage };
        });

      resultSeries.data = resultSeries.data.filter((dataObj, index) => {
        return null != (<UIPieChart>this.uiOption).maxCategory && undefined != (<UIPieChart>this.uiOption).maxCategory
          ? (<UIPieChart>this.uiOption).maxCategory > index
          : true;
      });

      if (otherValueList.length > 0) {
        existEtcData = true;
        resultSeries.data.push({
          value: _.sumBy(otherValueList, 'value'),
          percentage: _.sumBy(otherValueList, 'percentage'),
          name: 'OTHER',
        } as any);
      }
      // resultSeries.originData = _.cloneDeep(resultSeries.data);

      // resultSeries.uiData = resultSeries.data;

      seriesList.push(resultSeries);
    });

    this.chartOption.series = seriesList;

    this.chartOption['dataInfo']['existEtcData'] = existEtcData;

    return this.chartOption;
  }

  protected override setUIData(): any {
    _.each(this.data.columns, (data) => {
      data.seriesName = _.cloneDeep(_.map(data.value, 'name'));
      data.seriesValue = _.cloneDeep(_.map(data.value, 'value'));
      data.seriesPercent = _.cloneDeep(_.map(data.value, 'percentage'));
    });

    return this.data.columns;
  }

  protected override additionalLegend(): BaseOption {
    if (!_.isUndefined(this.chartOption.legend) && (this.chartOption['dataInfo'] as any).existEtcData) {
      const legend = this.chartOption.legend as LegendComponentOption;
      if (!legend.data) legend.data = [];

      legend.data.push('OTHER');
    }

    return this.chartOption;
  }

  protected override additionalSeries(): BaseOption {
    this.chartOption = this.convertViewType();

    (this.chartOption.series as PieSeriesOption[]).forEach((series) => {
      if (this.uiOption.dataLabel.showOutside) {
        series.label.position = Position.OUTSIDE;

        if (!series.labelLine) series.labelLine = {};

        series.labelLine.length = 10;
        series.labelLine.length2 = 15;

        if (series.label.rich) delete series.label.rich['align'];
      } else {
        series.label.position = Position.INSIDE;
      }

      series.label.formatter = (params): string => {
        const uiData = _.cloneDeep(series.data[params.dataIndex]);

        return this.getFormatPieValueSeries(params, this.uiOption.valueFormat, this.uiOption, series, uiData);
      };
    });

    return this.chartOption;
  }

  private getFormatPieValueSeries(
    params,
    format: UIChartFormat,
    uiOption: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.dataLabel || !uiOption.dataLabel.displayTypes) return '';

      let isUiData = false;
      let result: string[] = [];

      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_NAME)) {
        const categoryNameList = _.split(uiData.name, CHART_STRING_DELIMITER);
        const dimensionPivotList = this.pivot.aggregations.filter((item) => {
          if ('dimension' == item.type) return item;
        });
        result = FormatOptionConverter.getTooltipName(categoryNameList, dimensionPivotList, result);
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE)) {
        const seriesValue = typeof uiData.value === 'undefined' ? uiData.value : uiData.value;
        result.push(FormatOptionConverter.getFormatValue(seriesValue, format));
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT)) {
        let value = params.percent;
        value = (Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal)).toFixed(
          format.decimal,
        );
        result.push('(' + value + '%' + ')');
        isUiData = true;
      }

      let label = '';

      if (isUiData) {
        for (let num = 0; num < result.length; num++) {
          if (num > 0) {
            label += ' ';
          }
          if (series.label && series.label.rich) {
            label += '{align|' + result[num] + '}';
          } else {
            label += result[num];
          }
        }

        return label;
      } else {
        return label;
      }
    }

    return FormatOptionConverter.noUIDataFormat(params, format);
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
    this.addLegendSelectEventListener();
  }

  protected override setSelectData(params: any, colValues: string[], rowValues: string[]): any {
    const returnDataList: any = [];

    let targetValues: string[] = [];
    _.forEach(this.pivot, (value, key) => {
      let deepCopyShelve = _.cloneDeep(this.pivot[key]);

      deepCopyShelve = _.filter(deepCopyShelve, (obj) => {
        if (_.eq(obj.type, ShelveFieldType.DIMENSION) || _.eq(obj.type, ShelveFieldType.TIMESTAMP)) {
          return obj;
        }
      });

      deepCopyShelve.map((obj, idx) => {
        if (!_.isNull(params)) {
          targetValues = _.eq(key, ShelveType.AGGREGATIONS) ? colValues : rowValues;
        }

        if (!_.isEmpty(targetValues) && targetValues[idx]) {
          if (-1 === _.findIndex(returnDataList, { name: obj.name })) {
            returnDataList.push(obj);
          }
          returnDataList[returnDataList.length - 1].data = [targetValues[idx]];
        }
      });
    });

    return returnDataList;
  }

  protected override convertLegend(): BaseOption {
    if (!this.chartOption.legend) {
      return this.chartOption;
    }

    if (this.chartOption.legend) {
      let legendData: string[];

      let fieldIdx: number;

      let pivotType: ChartPivotType;

      const fieldInfo = _.cloneDeep(this.fieldOriginInfo);

      _.forEach(fieldInfo, (value, key) => {
        if (_.indexOf(value, this.uiOption.color['targetField']) > -1) {
          fieldIdx = _.indexOf(value, this.uiOption.color['targetField']);
          pivotType = _.eq(key, ChartPivotType.COLS)
            ? ChartPivotType.COLS
            : _.eq(key, ChartPivotType.ROWS)
            ? ChartPivotType.ROWS
            : ChartPivotType.AGGS;
        }
      });

      if (this.fieldInfo[pivotType] && this.fieldInfo[pivotType].length > 1) {
        legendData = this.pivotInfo[pivotType].map((value) => {
          return !_.split(value, CHART_STRING_DELIMITER)[fieldIdx]
            ? value
            : _.split(value, CHART_STRING_DELIMITER)[fieldIdx];
        });

        legendData = _.uniq(legendData);
      } else {
        legendData = this.pivotInfo[pivotType];
      }

      (this.chartOption.legend as LegendComponentOption) = merge(this.chartOption.legend as LegendComponentOption, {
        data: legendData,
        lineStyle: { color: ChartColorList[this.uiOption.color['schema']] },
      });
    }

    this.chartOption = LegendOptionConverter.convertLegend(this.chartOption, this.uiOption);

    this.chartOption = this.additionalLegend();

    return this.chartOption;
  }

  private setPieSizeCount(count: number, orient: Orient): any {
    const size = 95 / count;
    const increase = size / 1.55;

    const radiusList: any[] = _.fill(Array(count), []);
    const centerList: any[] = _.fill(Array(count), []);
    const titleList: any[] = _.fill(Array(count), []);

    radiusList.map((item, idx) => {
      const location = _.eq(idx, 0) ? increase + '%' : increase + size * idx + '%';
      if (_.eq(orient, Orient.HORIZONTAL)) {
        radiusList[idx] = ['0%', size + '%'];
        centerList[idx] = [location, '50%'];
        titleList[idx] = [location, 50 - size / 2 - 4 + '%'];
      } else {
        radiusList[idx] = ['0%', size - 5 + '%'];
        centerList[idx] = ['50%', location];
        titleList[idx] = ['50%', size * idx + '%'];
      }
    });

    return { radius: radiusList, center: centerList, title: titleList };
  }

  private convertViewType(): BaseOption {
    const type: PieSeriesViewType = (<UIPieChart>this.uiOption).markType;

    const series = this.chartOption.series;

    _.each(series, (obj: PieSeriesOption) => {
      const size = _.toNumber(_.join(_.dropRight(_.values(obj.radius[1])), ''));
      obj.radius = _.eq(type, PieSeriesViewType.DONUT) ? [size / 2 + '%', obj.radius[1]] : ['0%', obj.radius[1]];
    });

    return this.chartOption;
  }

  private setPiePivotInfo(): PivotTableInfo {
    const cols: string[] = [];
    let aggs: string[] = [];
    const allAggs = [];

    let pieSizeInfo: any = {};

    if (this.data.columns.length > 1) {
      const orient = !_.isEmpty(this.pivot.columns) ? Orient.HORIZONTAL : Orient.VERTICAL;
      pieSizeInfo = this.setPieSizeCount(this.data.columns.length, orient);
    }

    let otherFl = false;

    _.each(this.data.columns, (column, idx) => {
      if (!_.isEmpty(pieSizeInfo)) {
        const title = _.join(_.dropRight(_.split(column.name, CHART_STRING_DELIMITER)), CHART_STRING_DELIMITER);
        cols.push(title);
      }

      const otherList = [];
      column.value
        .filter((dataObj, index) => {
          const isOtherValue =
            null != (<UIPieChart>this.uiOption).maxCategory && undefined != (<UIPieChart>this.uiOption).maxCategory
              ? (<UIPieChart>this.uiOption).maxCategory <= index
              : false;
          if (isOtherValue) {
            otherList.push(dataObj.name);
            otherFl = true;
          }
          return isOtherValue;
        })
        .map((dataObj) => {
          return dataObj.value;
        });

      aggs = column.value.map((dataObj) => {
        return dataObj.name;
      });

      for (let num: number = aggs.length - 1; num >= 0; num--) {
        for (const item of otherList) {
          if (item == aggs[num]) {
            aggs.splice(num, 1);
          }
        }
      }

      allAggs.push(aggs);
    });

    let setAggs = [];
    if (allAggs && allAggs.length > 1) {
      let array = [];
      for (const item of allAggs) {
        array = array.concat(item);
      }

      setAggs = _.uniq(array);
    } else setAggs = aggs;

    if (otherFl) {
      setAggs.push('OTHER');
    }

    return createPivotTableInfo(cols, [], _.uniq(setAggs));
  }

  private tooltipFormatter(params, pivot: Pivot): any {
    if (!this.uiOption.toolTip) this.uiOption.toolTip = {};
    if (!this.uiOption.toolTip.displayTypes)
      this.uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(this.uiOption.type);

    const format = this.uiOption.valueFormat;

    let result: string[] = [];

    if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_NAME)) {
      const nameList = _.split(params.data.name, CHART_STRING_DELIMITER);
      const dimensionList = pivot.aggregations.filter((item) => {
        if ('dimension' == item.type) return item;
      });

      result = FormatOptionConverter.getTooltipName(nameList, dimensionList, result, true);
    }
    if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE)) {
      let seriesValue = FormatOptionConverter.getTooltipValue(
        params.seriesName,
        pivot.aggregations,
        this.uiOption.valueFormat,
        params.data.value,
      );

      if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT)) {
        const value = (
          Math.floor(Number(params.percent) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal)
        ).toFixed(format.decimal);

        seriesValue += ' (' + value + '%)';
      }

      result.push(seriesValue);
    }
    if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT)) {
      if (-1 == this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE)) {
        let seriesPercent = FormatOptionConverter.getTooltipValue(
          params.seriesName,
          pivot.aggregations,
          this.uiOption.valueFormat,
          params.percent,
        );

        seriesPercent += '%';
        result.push(seriesPercent);
      }
    }

    return result.join('<br/>');
  }
}
