import { isNullOrUndefined } from '@selfai-platform/shared';

import { Component, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';

import $ from 'jquery';
import * as _ from 'lodash';

import { ChartUtil, OptionGenerator, TooltipOptionConverter } from '@selfai-platform/bi-chart-engine';
import {
  ChartType,
  DatasourceField,
  EventType,
  FieldPivot,
  FieldRole,
  LogicalType,
  MapLayerType,
  MeasureField,
  Pivot,
  PivotField,
  Shelf,
  ShelveFieldType,
  UIFormatCurrencyType,
  UIFormatNumericAliasType,
  UIFormatType,
  UIMapOption,
  UIOption,
  createDatasourceField,
  createDimensionField,
  createMeasureField,
  createTimestampField,
} from '@selfai-platform/bi-domain';

import { CommonConstant } from '../../../common/constant/common.constant';
import { PagePivotComponent } from '../page-pivot.component';

@Component({
  selector: 'map-page-pivot',
  templateUrl: './map-page-pivot.component.html',
})
export class MapPagePivotComponent extends PagePivotComponent {
  public shelf: Shelf;

  public uiOption: UIMapOption;

  @Input()
  public pageDimensions: DatasourceField[];

  @Input()
  public geoType: LogicalType;

  @Input('uiOption')
  public set setUIOption(uiOption: UIMapOption) {
    if (!uiOption.layerNum) uiOption.layerNum = 0;

    this.uiOption = uiOption;
  }

  @Input('pivot')
  set setPivot(pivot: Pivot) {
    this.pivot = pivot;
  }

  @Input('shelf')
  set setShelf(shelf: Shelf) {
    this.shelf = shelf;

    this.changeShelf();
  }

  @Output('changeShelf')
  public changeShelfEvent: EventEmitter<any> = new EventEmitter();

  @Output('changeLayer')
  public changeLayerEvent: EventEmitter<any> = new EventEmitter();

  @Output('removeAnalysisLayer')
  public removeAnalysisLayerEvent: EventEmitter<any> = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public mapPivotValidationPass(targetField, currentMapLayer, pageDimensions): boolean {
    let returnValue = true;

    let diffDataSourceFl = false;

    let cnt = 0;
    currentMapLayer.forEach((item) => {
      if (item.field.dsId == targetField.field.dsId && item.field.name == targetField.field.name) {
        cnt++;
      }
      if (cnt > 1) {
        returnValue = false;
        return;
      }
    });

    currentMapLayer.forEach((item) => {
      if (
        'user_expr' !== targetField.subType &&
        'user_expr' !== item.field.type &&
        item.field.dataSource != targetField.field.dataSource
      ) {
        diffDataSourceFl = true;
        return;
      }
    });

    const geoFields = [];
    for (const item of _.cloneDeep(pageDimensions)) {
      if (item.logicalType && -1 !== item.logicalType.toString().indexOf('GEO')) {
        geoFields.push(ChartUtil.getAlias(item));
      }
    }

    for (const alias of geoFields) {
      if (targetField.expr && -1 !== targetField.expr.indexOf(alias)) {
        this.alertPrimeService.warn(this.translateService.instant('msg.storage.ui.list.geo.block.custom.field.geo'));
        returnValue = false;
        return;
      }
    }

    if (diffDataSourceFl) {
      this.alertPrimeService.warn(this.translateService.instant('msg.page.layer.multi.datasource.same.shelf'));
      returnValue = false;
      return;
    }

    if ('user_expr' === targetField.subType && true === targetField.aggregated) {
      this.alertPrimeService.warn(
        this.translateService.instant('msg.storage.ui.list.geo.block.custom.field.agg.function'),
      );
      returnValue = false;
      return;
    }

    return returnValue;
  }

  public convertField(targetField: DatasourceField, targetContainer: string, addPivotFl: boolean = true) {
    let shelf;

    let shelfElement: JQuery;

    let fieldPivot: FieldPivot;

    if (targetContainer === 'layer0') {
      shelf = this.shelf.layers[0].fields;
      shelfElement = this.$element.find('#layer0');
      fieldPivot = FieldPivot.MAP_LAYER0;
      this.uiOption.layerNum = 0;
    } else if (targetContainer === 'layer1') {
      shelf = this.shelf.layers[1].fields;
      shelfElement = this.$element.find('#layer1');
      fieldPivot = FieldPivot.MAP_LAYER1;
      this.uiOption.layerNum = 1;
    }

    const currentMapLayer = this.shelf.layers[this.uiOption.layerNum].fields;

    if (
      !isNullOrUndefined(currentMapLayer) &&
      !isNullOrUndefined(currentMapLayer[0]) &&
      !isNullOrUndefined(currentMapLayer[0]['field']) &&
      targetField.dataSource != currentMapLayer[0].field.dataSource
    ) {
      this.shelf.layers[this.uiOption.layerNum].fields = this.shelf.layers[this.uiOption.layerNum].fields.filter(
        (field) => {
          return field.name != targetField.name && field.ref != targetField.ref;
        },
      );
      this.alertPrimeService.warn(this.translateService.instant('msg.page.layer.multi.datasource.same.shelf'));
      return;
    }

    if (targetField.logicalType == LogicalType.GEO_LINE) {
      this.uiOption.layers[this.uiOption.layerNum].type = MapLayerType.LINE;
    } else if (targetField.logicalType == LogicalType.GEO_POLYGON) {
      this.uiOption.layers[this.uiOption.layerNum].type = MapLayerType.POLYGON;
    } else if (targetField.logicalType == LogicalType.GEO_POINT) {
      if (
        !_.isUndefined(this.uiOption.layers[this.uiOption.layerNum]['clustering']) &&
        this.uiOption.layers[this.uiOption.layerNum]['clustering']
      ) {
        this.uiOption.layers[this.uiOption.layerNum].type = MapLayerType.CLUSTER;
      } else {
        this.uiOption.layers[this.uiOption.layerNum].type = MapLayerType.SYMBOL;
      }
    }

    const idx = shelf.findIndex((field) => {
      return field.name === targetField.name && targetField.role === field.role;
    });

    if (idx > -1) {
      let field;

      if (
        targetField.role === FieldRole.TIMESTAMP ||
        (targetField.role === FieldRole.DIMENSION && targetField.logicalType === LogicalType.TIMESTAMP)
      ) {
        const timeField = createTimestampField();
        field = timeField;
      } else if (targetField.role === FieldRole.DIMENSION) {
        field = createDimensionField();
      } else if (targetField.role === FieldRole.MEASURE) {
        field = createMeasureField();
        field.aggregated = targetField.aggregated;
      }
      field.name = targetField.name;
      field.subType = targetField.type;
      field.subRole = targetField.role;
      field.expr = targetField.expr;
      field.field = targetField;

      if (
        targetField.name !== targetField.alias &&
        (!targetField.nameAlias || targetField.nameAlias.nameAlias !== targetField.alias)
      ) {
        field.alias = targetField.alias;
      }
      targetField.nameAlias && (field.fieldAlias = targetField.nameAlias.nameAlias);
      field.granularity = targetField.granularity;
      field.segGranularity = targetField.segGranularity;
      if (!_.isUndefined(targetField.ref)) {
        field.ref = targetField.ref;
      } else if (targetField.type == 'user_expr') {
        field.ref = 'user_defined';
      }

      shelf[idx] = field;

      if (!this.mapPivotValidationPass(field, shelf, _.cloneDeep(this.pageDimensions))) {
        shelf.splice(idx, 1);
        return;
      }

      this.dragField = field;

      const shelves = this.shelf.layers[this.uiOption.layerNum].fields;

      let duplicateFl = this.distinctPivotItems(shelves, field, idx, shelf, targetContainer);

      duplicateFl = this.distinctMeasure(shelves, field, idx);

      if (!duplicateFl) {
        targetField.format = shelf[idx].format;

        targetField.pivot = targetField.pivot ? targetField.pivot : [];

        if (addPivotFl) targetField.pivot.push(fieldPivot);
        field.currentPivot = fieldPivot;

        if ((field as MeasureField).type === ShelveFieldType.MEASURE) {
          field.format = {
            type:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.type)
                ? String(UIFormatType.NUMBER)
                : this.uiOption.valueFormat.type,
            sign:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.sign)
                ? String(UIFormatCurrencyType.KRW)
                : this.uiOption.valueFormat.sign,
            decimal:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.decimal)
                ? 2
                : this.uiOption.valueFormat.decimal,
            useThousandsSep:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.useThousandsSep)
                ? true
                : this.uiOption.valueFormat.useThousandsSep,
            abbr:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.abbr)
                ? String(UIFormatNumericAliasType.NONE)
                : this.uiOption.valueFormat.abbr,
            customSymbol:
              _.isUndefined(this.uiOption.valueFormat) || _.isUndefined(this.uiOption.valueFormat.customSymbol)
                ? null
                : this.uiOption.valueFormat.customSymbol,
          };
        }

        if (MapLayerType.TILE !== this.uiOption.layers[this.uiOption.layerNum].type) {
          delete field.aggregationType;
        }
      }

      this.changePivot(EventType.CHANGE_PIVOT);

      if (shelfElement) this.onShelveAnimation();
    }

    if (!_.isUndefined(this.editFieldLayerDirective)) {
      this.editFieldLayerDirective.exclude = '.ddp-icon-layer';
    }
  }

  public changeFieldPivot(targetField: DatasourceField, targetContainer: string, pivotField: PivotField): void {
    targetField.pivot = targetField.pivot ? targetField.pivot : [];

    let shelf;
    let fieldPivot: FieldPivot;

    let shelfElement: JQuery;

    if (targetContainer === 'layer0') {
      shelf = this.shelf.layers[0].fields;
      shelfElement = this.$element.find('#layer0');
      fieldPivot = FieldPivot.MAP_LAYER0;
      this.uiOption.layerNum = 0;
    } else if (targetContainer === 'layer1') {
      shelf = this.shelf.layers[1].fields;
      shelfElement = this.$element.find('#layer1');
      fieldPivot = FieldPivot.MAP_LAYER1;
      this.uiOption.layerNum = 1;
    }

    if (pivotField.currentPivot !== fieldPivot) {
      if (shelf.length > 0) {
        let targetDsId = '';
        shelf.forEach((item) => {
          if (
            item.field.logicalType == LogicalType.GEO_POINT ||
            item.field.logicalType == LogicalType.GEO_LINE ||
            item.field.logicalType == LogicalType.GEO_POLYGON
          ) {
            targetDsId = item.field.dsId;
          }
        });
        shelf = _.remove(shelf, function (item) {
          return item['field']['dsId'] != targetDsId;
        });
      }

      if (
        targetField.role === FieldRole.TIMESTAMP ||
        (targetField.role === FieldRole.DIMENSION && targetField.logicalType === LogicalType.TIMESTAMP)
      ) {
        const timestampIndex = shelf.findIndex((field) => {
          return (
            field.name === targetField.name &&
            targetField.role === field.field.role &&
            targetField.granularity === field.granularity &&
            -1 !== targetField.format['type'].indexOf('time')
          );
        });

        if (-1 !== timestampIndex) {
          const duplicateFieldFl = this.deleteDuplicatedField(targetField, timestampIndex, targetContainer);

          if (!duplicateFieldFl) {
            targetField.pivot.splice(targetField.pivot.indexOf(pivotField.currentPivot), 1);

            targetField.pivot.push(fieldPivot);

            pivotField.currentPivot = fieldPivot;
          }
        }
      } else {
        targetField.pivot.splice(targetField.pivot.indexOf(pivotField.currentPivot), 1);

        targetField.pivot.push(fieldPivot);

        pivotField.currentPivot = fieldPivot;
      }
    }

    this.changePivot(EventType.CHANGE_PIVOT);

    if (shelfElement) this.onShelveAnimation();
  }

  public changePivot(eventType?: EventType) {
    this.shelf.layers[this.uiOption.layerNum].fields = this.shelf.layers[this.uiOption.layerNum].fields.map(
      this.mapJoinToRef,
    );

    this.setPointOption();

    this.changeShelfEvent.emit({ shelf: this.shelf, eventType: eventType });
  }

  public addLayer(index: number): void {
    if (
      this.shelf.layers.length >= 2 ||
      isNullOrUndefined(this.shelf.layers[0]) ||
      isNullOrUndefined(this.shelf.layers[0].fields) ||
      this.shelf.layers[0].fields.length <= 0
    ) {
      this.alertPrimeService.warn('Please select GEO dimension one or more');
      return;
    } else {
      const layers = {
        name: '',
        ref: '',

        fields: [],
      };

      this.shelf.layers.push(layers);

      this.uiOption.layerNum = this.shelf.layers.length - 1;

      this.shelf.layers[this.uiOption.layerNum].fields = this.shelf.layers[this.uiOption.layerNum].fields.map(
        this.mapJoinToRef,
      );

      const addUiOptionLayer = OptionGenerator.initUiOption(this.uiOption)['layers'][0];

      addUiOptionLayer.name =
        this.uiOption.layers[index].name == 'Layer' + (index + 1) ? 'Layer' + (index + 2) : 'Layer' + (index + 1);

      this.uiOption.layers.push(addUiOptionLayer);

      this.changeLayerEvent.emit(this.shelf);
    }
  }

  public removeLayer(index: number): void {
    if (
      this.shelf.layers.length <= 1 ||
      (!_.isUndefined(this.uiOption['analysis']) && this.uiOption.analysis['use'] == true)
    ) {
      return;
    }

    this.shelf.layers.splice(index, 1);

    for (let idx = 0; idx < this.shelf.layers.length; idx++) {
      const item = this.shelf.layers[idx].fields;
      for (let idx2 = 0; idx2 < item.length; idx2++) {
        // item[idx2].field.pivot.splice(item[idx2].field.pivot.indexOf(index), 1);
      }
    }

    this.uiOption.layerNum = this.shelf.layers.length - 1;

    this.shelf.layers[this.uiOption.layerNum].fields = this.shelf.layers[this.uiOption.layerNum].fields.map(
      this.mapJoinToRef,
    );

    this.uiOption.layers.splice(index, 1);

    this.changeShelfEvent.emit({ shelf: this.shelf });

    this.changePivot(EventType.CHANGE_PIVOT);
  }

  public isGuide(): boolean {
    if (this.chartType == '') {
      return false;
    }

    for (const field of this.shelf.layers[this.uiOption.layerNum].fields) {
      if (field && field.field && field.field.logicalType && -1 !== field.field.logicalType.toString().indexOf('GEO')) {
        return false;
      }
    }

    return true;
  }

  public openFieldSetting(event, field) {
    event.stopPropagation();

    if (this.editingField === field) {
      this.editingField = null;
      return;
    }

    this.editingField = field;

    const shelves = this.shelf.layers[this.uiOption.layerNum].fields;

    this.fieldSetting(shelves);
  }

  public changeShelf(eventType?: EventType) {
    this.changeShelfEvent.emit({ shelf: this.shelf, eventType: eventType });
  }

  public getMapGuideText(index: number, type?: string): boolean {
    if (!this.shelf.layers) return;

    const layers = this.shelf.layers[index];

    let returnValue: boolean;

    if ('geo' === type) {
      returnValue = true;

      layers.fields.forEach((item) => {
        if (item.field && item.field.logicalType && -1 !== item.field.logicalType.toString().indexOf('GEO')) {
          return (returnValue = false);
        }
      });
    } else {
      returnValue = false;

      layers.fields.forEach((item) => {
        if (item.field && item.field.logicalType && -1 !== item.field.logicalType.toString().indexOf('GEO')) {
          return (returnValue = true);
        }
      });
    }

    return returnValue;
  }

  public onShelveAnimation() {
    if (!this.changeDetect['destroyed']) {
      this.changeDetect.detectChanges();
    }

    const element = this.$element.find('#layer' + this.uiOption.layerNum).find('.ddp-wrap-default');

    const scope = this;

    element.each(function () {
      const totalWidth = scope.getShelveTotalWidth($(this));

      $(this).css('width', totalWidth + 150);

      if (totalWidth > $(this).parent('.ddp-ui-drag-slide-in').width()) {
        $(this).parent().parent().addClass('ddp-slide');
        $(this).parent().parent().css('padding-right', '10px');
      }
      if (totalWidth <= $(this).parent('.ddp-ui-drag-slide-in').width()) {
        $(this).parent().parent().removeClass('ddp-slide');
        $(this).parent().parent().removeAttr('padding-right');

        $(this).css('margin-left', '0px');
      }
    });
  }

  public mouseOverPrev(event: any) {
    this.animationPause = false;

    const scope = this;

    const $wrapDefault = $(event.currentTarget.parentElement.parentElement).find('.ddp-wrap-default');

    $wrapDefault.animate(
      { marginLeft: 0 },
      {
        duration: 1500,
        step: function () {
          if (scope.animationPause) {
            $(this).stop();
          }
        },
      },
    );
  }

  public mouseOverNext(event: any) {
    this.animationPause = false;

    const scope = this;

    const $currentShelve = $(event.currentTarget.parentElement.parentElement);

    const $wrapDefault = $(event.currentTarget.parentElement.parentElement).find('.ddp-wrap-default');

    const totalWidth = this.getShelveTotalWidth($currentShelve);

    const moveWidth = totalWidth - $currentShelve.find('.ddp-ui-drag-slide-in').width();

    $wrapDefault.animate(
      { marginLeft: -moveWidth - 40 },
      {
        duration: 1500,
        step: function () {
          if (scope.animationPause) {
            $(this).stop();
          }
        },
      },
    );
  }

  public addField(targetField: DatasourceField, targetContainer: string, pivotField: PivotField) {
    let shelf;

    if (targetContainer === 'layer') {
      shelf = this.shelf.layers[this.uiOption.layerNum];
    } else {
      console.info('정의되지 않은 drop', targetContainer);
      return;
    }

    targetContainer = 'layer' + this.uiOption.layerNum;

    if (targetField) {
      shelf.fields.push(targetField);
      this.convertField(targetField, targetContainer);
    }
  }

  public blockCustomField(targetField: DatasourceField, uiOption: UIOption) {
    if (ChartType.MAP === uiOption.type && 'user_expr' === targetField.type) {
      return true;
    }

    return false;
  }

  protected deleteDuplicatedField(field: any, idx: number, targetContainer: string): boolean {
    if (this.checkDuplicatedField(this.shelf.layers[this.uiOption.layerNum].fields, field).length > 1) {
      this.shelf.layers[this.uiOption.layerNum].fields.splice(idx, 1);
      return true;
    }

    return false;
  }

  private distinctMeasure(shelf: PivotField[], field: any, idx: number): boolean {
    const duplicateList = [];

    shelf.forEach((item) => {
      if (item.name === field.name) {
        duplicateList.push(item);
      }
    });

    if (String(ShelveFieldType.MEASURE) === field.type && duplicateList.length > 1) {
      if (
        MapLayerType.SYMBOL === this.uiOption.layers[this.uiOption.layerNum].type ||
        MapLayerType.CLUSTER === this.uiOption.layers[this.uiOption.layerNum].type ||
        MapLayerType.HEATMAP === this.uiOption.layers[this.uiOption.layerNum].type
      ) {
        shelf.splice(idx, 1);
        return true;
      }
    }

    return false;
  }

  public selectedLayer(index: number) {
    if (
      this.uiOption.layerNum != index &&
      (_.isUndefined(this.uiOption.analysis) ||
        (!_.isUndefined(this.uiOption.analysis) && this.uiOption['analysis']['use'] == false))
    ) {
      this.uiOption.layerNum = index;
    }
  }

  public spatialAnalysisBtnClicked(value) {
    this.uiOption = value;

    const layers = {
      name: CommonConstant.MAP_ANALYSIS_LAYER_NAME,
      ref: '',
      fields: _.cloneDeep(this.shelf.layers[this.uiOption.analysis.layerNum].fields),
    };

    this.shelf.layers.push(layers);

    const addUiOptionLayer = OptionGenerator.initUiOption(this.uiOption)['layers'][0];

    addUiOptionLayer.name = CommonConstant.MAP_ANALYSIS_LAYER_NAME;

    this.uiOption.layers.push(addUiOptionLayer);

    this.uiOption.layerNum = this.uiOption.layers.length - 1;

    if (this.uiOption['analysis']['operation']['choropleth'] == true) {
      const field: MeasureField = createMeasureField();

      field.aggregationType = null;
      field.alias = 'count';
      field.subRole = 'measure';
      field.name = 'count';
      field.isCustomField = true;
      field.field = createDatasourceField({
        logicalType: LogicalType.INTEGER,
      });
      this.uiOption.toolTip['isFirstOpenTooltipOption'] = true;

      const uiOption = this.uiOption;
      this.shelf.layers.forEach((layer) => {
        if (layer.name === CommonConstant.MAP_ANALYSIS_LAYER_NAME) {
          layer.fields.push(_.cloneDeep(field));
          layer.fields.forEach((field) => {
            if (
              uiOption['analysis']['operation']['aggregation']['column'] == field.name &&
              !(!_.isUndefined(field.isCustomField) && field.isCustomField == true)
            ) {
              field.aggregationType = uiOption['analysis']['operation']['aggregation']['type'];
            }
          });
        }
      });
    }
    this.setPointOption();
  }

  public removeAnalysis() {
    if (!_.isUndefined(this.uiOption.analysis) && this.uiOption['analysis']['use'] == true) {
      this.shelf.layers.pop();
      this.uiOption.layers.pop();
      this.uiOption.layerNum = this.uiOption.layers.length - 1;
      this.uiOption['analysis']['use'] = false;
      delete this.uiOption.analysis.operation;
      delete this.uiOption.analysis.mainLayer;
      delete this.uiOption.analysis.compareLayer;
      delete this.uiOption.analysis.type;

      const layerItems = [];

      for (let layerIndex = 0; this.uiOption.layers.length > layerIndex; layerIndex++) {
        if (this.shelf && !_.isUndefined(this.shelf.layers[layerIndex])) {
          this.shelf.layers[layerIndex].fields.forEach((field) => {
            layerItems.push(field);
          });
        }
      }

      for (const item of layerItems) {
        item['alias'] = ChartUtil.getAlias(item);
      }

      const uniqList = TooltipOptionConverter.returnTooltipDataValue(layerItems);

      this.uiOption.toolTip['isFirstOpenTooltipOption'] = true;
      this.uiOption.toolTip.displayColumns = ChartUtil.returnNameFromField(uniqList);

      this.removeAnalysisLayerEvent.emit(this.shelf);
    }
  }

  private setPointOption() {
    if (
      !_.isUndefined(this.uiOption) &&
      !_.isUndefined(this.uiOption['layers']) &&
      this.uiOption['layers'].length > 0
    ) {
      for (let layerIndex = 0; this.uiOption['layers'].length > layerIndex; layerIndex++) {
        if (this.uiOption['layers'][layerIndex]['type'] == MapLayerType.SYMBOL) {
          if (isNullOrUndefined(this.uiOption.layers[layerIndex]['pointRadiusFrom'])) {
            this.uiOption.layers[layerIndex]['pointRadiusFrom'] = 5;
            this.uiOption.layers[layerIndex].pointRadius = 5;
          }
          const hasMeasure = this.shelf.layers[layerIndex].fields.find((field) => {
            return field.type == 'measure';
          });

          if (
            this.shelf['layers'][layerIndex].fields.length <= 1 ||
            (this.shelf['layers'][layerIndex].fields.length > 1 &&
              isNullOrUndefined(this.uiOption.layers[layerIndex]['pointRadiusTo']) &&
              isNullOrUndefined(hasMeasure))
          ) {
            delete this.uiOption.layers[layerIndex]['needToCalPointRadius'];
            delete this.uiOption.layers[layerIndex]['pointRadiusTo'];
            delete this.uiOption.layers[layerIndex]['pointRadiusCal'];
            this.uiOption.layers[layerIndex].pointRadius = this.uiOption.layers[layerIndex]['pointRadiusFrom'];
          } else if (this.shelf['layers'][layerIndex].fields.length > 1 && hasMeasure) {
            if (isNullOrUndefined(this.uiOption.layers[layerIndex]['pointRadiusTo'])) {
              if (this.uiOption.layers[layerIndex]['pointRadiusFrom'] < 100) {
                this.uiOption.layers[layerIndex]['pointRadiusTo'] = 20;
              } else {
                this.uiOption.layers[layerIndex]['pointRadiusTo'] = 200;
              }
            }
            this.uiOption.layers[layerIndex]['needToCalPointRadius'] = true;
          }
        }
      }
    }
  }
}
