import { UserProfile, Workspace } from '@selfai-platform/bi-domain';

import { AbstractHistoryEntity } from '../common/abstract-history-entity';

export class Workspaces {
  private workspaces: Workspace[];

  constructor(workspacesResponse: WorkspacesResponse) {
    this.workspaces = workspacesResponse._embedded.workspaces;
  }

  public getList() {
    return this.workspaces;
  }
}

export interface WorkspacesResponse {
  _embedded: {
    workspaces: Workspace[];
  };
  page: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
}

export class WorkspaceAdmin extends AbstractHistoryEntity {
  public id: string;
  public name: string;
  public description: string;
  public owner: UserProfile;
  public publicType: PublicType;
  public published: boolean;
  public active: boolean;

  public statusShowFl = false;

  constructor(workspace?: Workspace) {
    super();
    if (workspace) {
      this.id = workspace.id;
      this.name = workspace.name;
      this.description = workspace.description;
      this.owner = workspace.owner;
      this.publicType = workspace.publicType;
      this.published = workspace.published;
      this.active = workspace.active;
    }
  }
}

export class CountByBookType {
  public workBook: number;
  public folder: number;
  public report: number;
  public workBench: number;
  public notebook: number;
}

export enum PublicType {
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED',
}
