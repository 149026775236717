import { Component, ElementRef, Injector } from '@angular/core';

import * as _ from 'lodash';

import { UIChartAxis, UIOption } from '@selfai-platform/bi-domain';

import { YAxisOptionsService } from '../service/y-axis-options.service';

import { DestroyService } from '@selfai-platform/shared';
import { YAxisOptionComponent } from './yaxis-option.component';

@Component({
  selector: 'secondary-axis-option',
  templateUrl: './secondary-axis-option.component.html',
  providers: [DestroyService],
})
export class SecondaryAxisOptionComponent extends YAxisOptionComponent {
  public isSecondaryAxis = true;

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    yAxisOptionsService: YAxisOptionsService,
    readonly destroy$: DestroyService,
  ) {
    super(elementRef, injector, yAxisOptionsService, destroy$);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public axisName(axisLabelType: any, idx: number, name: string): void {
    const value = name ? name : this.nameUiOption.secondaryAxis.customName;

    if (value && value.length > 20) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.axis.label.warn'));
      return;
    }

    if (_.eq(this.uiOption.secondaryAxis.mode, axisLabelType)) {
      this.uiOption.secondaryAxis.name = value;
    }

    if (_.isEmpty(value) || (value && _.isEmpty(value.trim()))) {
      delete this.uiOption.secondaryAxis.customName;
    } else {
      this.uiOption.secondaryAxis.customName = value.trim();
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { secondaryAxis: this.uiOption.secondaryAxis });
    this.update();
    this.changeAxisNameEvent.emit();
  }

  public showAxisName(axisLabelType: any, show: boolean): void {
    if (_.eq(this.uiOption.secondaryAxis.mode, axisLabelType)) {
      this.uiOption.secondaryAxis.showName = show;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { secondaryAxis: this.uiOption.secondaryAxis });

    this.update();
  }

  public showAxisLabel(axisLabelType: any, show: boolean): void {
    if (_.eq(this.uiOption.secondaryAxis.mode, axisLabelType)) {
      this.uiOption.secondaryAxis.showLabel = show;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { secondaryAxis: this.uiOption.secondaryAxis });

    this.update();
  }

  public changeSecondaryAxisValue(axis: UIChartAxis): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { secondaryAxis: axis });

    this.update();
  }

  public changeSecondaryAxisBaseline(axis: UIChartAxis): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { secondaryAxis: axis });

    this.update({});
  }

  public showSecondaryAxis(): void {
    this.uiOption.secondaryAxis.disabled = _.isUndefined(this.uiOption.secondaryAxis.disabled)
      ? true
      : !this.uiOption.secondaryAxis.disabled;
    if (this.uiOption.secondaryAxis.disabled) {
      this.uiOption.secondaryAxis.showName = false;
      this.uiOption.secondaryAxis.showLabel = false;
    } else {
      this.uiOption.secondaryAxis.showName = true;
      this.uiOption.secondaryAxis.showLabel = true;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { secondaryAxis: this.uiOption.secondaryAxis });
    this.update();
  }
}
