import { UserDetailModel, WidgetApiModel } from '@selfai-platform/bi-api';
import { UIOption } from '../../chart';
import { BoardDataSource } from '../../dashboard';
import { Filter, Limit, Pivot, Shelf } from '../../workbook';
import { FilterWidget, PageWidget, TextWidget, Widget } from '../models';

export function widgetApiResponseToWidgetNormalizer(
  widgetResponse: WidgetApiModel,
): Omit<Widget, 'configuration' | 'type'> {
  return {
    id: widgetResponse.id,
    name: widgetResponse.name,
    description: widgetResponse.description || null,
    modifiedBy: widgetResponse.modifiedBy as UserDetailModel,
    modifiedTime: new Date(widgetResponse.modifiedTime),
    createdBy: widgetResponse.createdBy as UserDetailModel,
    createdTime: new Date(widgetResponse.createdTime),
    imageUrl: widgetResponse.imageUrl,
  };
}

export function widgetApiResponseToPageWidgetNormalizer(widgetResponse: WidgetApiModel): PageWidget {
  const widget: PageWidget = {
    ...widgetApiResponseToWidgetNormalizer(widgetResponse),
    configuration: {
      type: widgetResponse.configuration.type as string,
      filters: (widgetResponse.configuration.filters as Filter[]) || [],
      limit: widgetResponse.configuration.limit as Limit,
      pivot: (<unknown>widgetResponse.configuration.pivot) as Pivot,
      shelf: widgetResponse.configuration.shelf as Shelf,
      chart: widgetResponse.configuration.chart as UIOption,
      dataSource: widgetResponse.configuration.dataSource as BoardDataSource,
      fields: widgetResponse.configuration.fields,
    },
    dataSourcesIds: widgetResponse.dataSourcesIds,
    mode: 'chart',
    type: 'page',
  };
  return widget;
}

export function widgetApiResponseToTextWidgetNormalizer(widgetResponse: WidgetApiModel): TextWidget {
  return {
    ...widgetApiResponseToWidgetNormalizer(widgetResponse),
    configuration: {
      type: widgetResponse.configuration.type || 'text',
      contents: widgetResponse.configuration.contents || '',
    },
    type: 'text',
  };
}

export function widgetApiResponseToFilterWidgetNormalizer(widgetResponse: WidgetApiModel): FilterWidget {
  return {
    ...widgetApiResponseToWidgetNormalizer(widgetResponse),
    configuration: {
      filter: widgetResponse.configuration.filter as Filter,
      type: widgetResponse.configuration.type || 'filter',
    },
    type: 'filter',
  };
}

export function widgetApiResponseToSpecWidgetsNormalizer(
  widget: WidgetApiModel,
): FilterWidget | TextWidget | PageWidget {
  const type = widget.type || widget.configuration.type;
  switch (type) {
    case 'page':
      return widgetApiResponseToPageWidgetNormalizer(widget);
    case 'text':
      return widgetApiResponseToTextWidgetNormalizer(widget);
    case 'filter':
      return widgetApiResponseToFilterWidgetNormalizer(widget);
    default: {
      throw new Error(`Widget type ${type} not supported`);
    }
  }
}

export function widgetsApiResponseArrayToWidgetsNormalizer(
  widgets: WidgetApiModel[],
): (FilterWidget | TextWidget | PageWidget)[] {
  return widgets.map(widgetApiResponseToSpecWidgetsNormalizer) as (FilterWidget | TextWidget | PageWidget)[];
}
