import { cloneDeep } from 'lodash';
import { JoinMapping } from '../../workbook';
import {
  BoardDataSource,
  BoardDataSourceRelation,
  Dashboard,
  createBoardDataSourceRelation,
  createBoardSource,
} from '../models';

function verityJoin(joinMappings: JoinMapping[]): boolean {
  return joinMappings.every((join) => {
    if (Object.keys(join.keyPair).length === 0) {
      return false;
    }

    for (const key in join.keyPair) {
      if (!(key in join.keyPair) || !join.keyPair[key]) {
        return false;
      }
    }
    return true;
  });
}

export function setDataSourceAndRelations(
  sourceDashboard: Dashboard,
  boardDsList: BoardDataSource[],
  relations?: BoardDataSourceRelation[],
): Dashboard {
  const dashboard = cloneDeep(sourceDashboard);
  boardDsList.forEach((item) => {
    if (item.joins && item.joins.length > 0 && verityJoin(item.joins)) {
      item.type = 'mapping';
      item.joins.forEach((join) => {
        if (join.join && !join.join.id) {
          join.join = undefined;
        }
      });
    } else {
      item.type = 'default';
    }
  });

  if (boardDsList.length === 1) {
    dashboard.dataSource = cloneDeep(boardDsList[0]);
  } else {
    const multiDataSource: BoardDataSource = createBoardSource({
      type: 'multi',
      dataSources: cloneDeep(boardDsList),
    });

    if (relations) {
      multiDataSource.associations = relations.map((item) => {
        const relInfo = createBoardDataSourceRelation({
          source: item.ui.source.engineName,
          target: item.ui.target.engineName,
          columnPair: {
            [item.ui.sourceField.name]: item.ui.targetField.name,
          },
        });
        // TODO remove delete after research of reasons of this code line
        delete relInfo.ui;
        return relInfo;
      });
    }

    dashboard.dataSource = multiDataSource;
  }

  return dashboard;
}
