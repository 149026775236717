import { InjectionToken } from '@angular/core';

export type DataSourceTypeList =
  | 'file'
  | 'database'
  | 'staging'
  | 'stream'
  | 'snapshot'
  | 'druid'
  | 's3-storage'
  | 'linked_database';

export const DATA_SOURCE_TYPE_LIST = new InjectionToken<DataSourceTypeList[]>('DataSourceTypeList', {
  providedIn: 'root',
  factory: () => ['file', 'database', 'staging', 'stream', 'snapshot', 'druid', 's3-storage', 'linked_database'],
});
