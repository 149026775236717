import { ETypeIcon } from './field-icon.interfaces';

export const renderFieldIcon = (options: { view?: ETypeIcon; type?: string; logicalType?: string }) => {
  const { view = null, type = '', logicalType = '' } = options;

  if (view === ETypeIcon.DIMENSION && !!type) {
    return `<em class="${getDimensionClass(type, logicalType)}"></em>`;
  }

  if (view === ETypeIcon.MEASURE && !!type) {
    return `<em class="${getMeasureClass(type, logicalType)}"></em>`;
  }

  if (view === ETypeIcon.FIELD && !!type) {
    return `<em class="${getFieldClass(type)}"></em>`;
  }

  return '';
};

export const getDimensionClass = (type: string, logicalType: string): string => {
  if ('STRING' === logicalType || 'user_expr' === type) {
    return 'ddp-icon-dimension-ab';
  } else if ('LNG' === logicalType) {
    return 'ddp-icon-dimension-longitude';
  } else if ('LNT' === logicalType) {
    return 'ddp-icon-dimension-latitude';
  } else if ('TIMESTAMP' === logicalType) {
    return 'ddp-icon-dimension-calen';
  } else if ('DOUBLE' === logicalType) {
    return 'ddp-icon-dimension-float';
  } else if ('INTEGER' === logicalType) {
    return 'ddp-icon-dimension-sharp';
  } else if ('BOOLEAN' === logicalType) {
    return 'ddp-icon-dimension-tf';
  } else if ('ARRAY' === logicalType) {
    return 'ddp-icon-dimension-array';
  } else if ('GEO_POINT' === logicalType) {
    return 'ddp-icon-map-view ddp-icon-dimension-point';
  } else if ('GEO_LINE' === logicalType) {
    return 'ddp-icon-map-view ddp-icon-dimension-line';
  } else if ('GEO_POLYGON' === logicalType) {
    return 'ddp-icon-map-view ddp-icon-dimension-polygon';
  }

  return '';
};

export const getMeasureClass = (type: string, logicalType: string): string => {
  if ('STRING' === logicalType) {
    return 'ddp-icon-measure-ab';
  } else if ('LNG' === logicalType || 'LNT' === logicalType) {
    return 'ddp-icon-measure-local';
  } else if ('TIMESTAMP' === logicalType) {
    return 'ddp-icon-measure-calen';
  } else if ('DOUBLE' === logicalType) {
    return 'ddp-icon-measure-float';
  } else if ('INTEGER' === logicalType || 'user_expr' === type) {
    return 'ddp-icon-measure-sharp';
  } else if ('BOOLEAN' === logicalType) {
    return 'ddp-icon-measure-tf';
  } else if ('ARRAY' === logicalType) {
    return 'ddp-icon-measure-array';
  } else if ('GEO_POINT' === logicalType) {
    return 'ddp-icon-map-view ddp-icon-measure-point';
  } else if ('GEO_LINE' === logicalType) {
    return 'ddp-icon-map-view ddp-icon-measure-line';
  } else if ('GEO_POLYGON' === logicalType) {
    return 'ddp-icon-map-view ddp-icon-measure-polygon';
  }
  return '';
};

export const getFieldClass = (type: string): string => {
  let result = '';
  if (type) {
    switch (type.toUpperCase()) {
      case 'TIMESTAMP':
        result = 'ddp-icon-type-calen';
        break;
      case 'BOOLEAN':
        result = 'ddp-icon-type-tf';
        break;
      case 'TEXT':
      case 'DIMENSION':
      case 'STRING':
      case 'USER_DEFINED':
        result = 'ddp-icon-type-ab';
        break;
      case 'INT':
      case 'INTEGER':
      case 'LONG':
        result = 'ddp-icon-type-int';
        break;
      case 'DOUBLE':
      case 'FLOAT':
        result = 'ddp-icon-type-float';
        break;
      case 'MAP':
        result = 'ddp-icon-type-map';
        break;
      case 'ARRAY':
        result = 'ddp-icon-type-array';
        break;
      case 'CALCULATED':
        result = 'ddp-icon-type-sharp';
        break;
      case 'LNG':
      case 'LONGITUDE':
        result = 'ddp-icon-type-longitude';
        break;
      case 'LNT':
      case 'LATITUDE':
        result = 'ddp-icon-type-latitude';
        break;
      case 'ETC':
        result = 'ddp-icon-type-etc';
        break;
      case 'IMAGE':
        result = 'ddp-icon-type-image';
        break;
      case 'BINARY':
        result = 'ddp-icon-type-binary';
        break;
      case 'SPATIAL':
        result = 'ddp-icon-type-spatial';
        break;
      case 'PRIVATE':
        result = 'ddp-icon-type-private';
        break;
      case 'PHONE':
        result = 'ddp-icon-type-phone';
        break;
      case 'EMAIL':
        result = 'ddp-icon-type-email';
        break;
      case 'GENDER':
        result = 'ddp-icon-type-gender';
        break;
      case 'URL':
        result = 'ddp-icon-type-url';
        break;
      case 'POST':
        result = 'ddp-icon-type-zipcode';
        break;
      case 'COUNTRY':
      case 'STATE':
      case 'CITY':
      case 'GU':
      case 'DONG':
        result = 'ddp-icon-type-local';
        break;
      case 'GEO_POINT':
        result = 'ddp-icon-type-point';
        break;
      case 'GEO_LINE':
        result = 'ddp-icon-type-line';
        break;
      case 'GEO_POLYGON':
        result = 'ddp-icon-type-polygon';
        break;
    }
  }
  return result;
};
